import React, { useEffect, useState } from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import LayoutAfterLogin from "../../component/LayoutAL";
import Tooltip from "../../Snippets/Tooltip";
import DollerIcon from '../../asserts/IMAGES/dai.svg'
import DimeIcon from '../../asserts/IMAGES/dimeCyanLogo.svg'
import CreditsIcon from '../../asserts/IMAGES/creditsBlackLogo.svg'
import ArrowRight from '../../asserts/IMAGES/redeem-arrow.svg'
import TransactionPending from '../../asserts/IMAGES/transsationPending.svg';
import { CheckAllowance, PendingModal,   convertEpochToDateTime, getGasBalance, getTokenBalance ,getDAIPrice,getDimePrice,getCreditsPrice, ConnectWallet,config} from "../../abi/CommonSolFunctions";
import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers5/react";
import { CHAIN_URL,DAI_TOKEN_ABI,DIME_Token_ABI,CREDITS_Token_ABI,credits_pool_ABI,DAI_TOKEN_Address,DIME_Token_Address,CREDITS_Token_Address, Credits_Pool_Address,TXN_ID, Network_Name, API_KEY} from '../../abi/ABI&ContractAddress';
// import { formatJsonRpcRequest } from "@json-rpc-tools/utils";
import {ethers} from 'ethers';
import { createTxn, recordUserVisits } from "../../abi/firebasecode";
/* global BigInt */
const Redeem = ({balances, balanceOfTokens}) => {
    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
    const provider = ethers.getDefaultProvider(Network_Name, {
        etherscan: API_KEY});
    const[daiallowance,setDaiAllowance] = useState("")
    const[creditsAllowance,setCreditsAllowance] = useState("")
    const[dimeAllowance,setDimeAllowance] = useState("")
    const[mintenabled,setMintEnabled] = useState(true)
    const[RedeemEnabled,setRedeemEnabled] = useState(true)
    const [redeemfee,setRedeemfee] = useState("");
    const [redeempercent,setRedeempercent] = useState("");
    const [dimecolratio,setDimeColratio] = useState("");
    const [usdccolratio,setUsdcColratio] = useState("");
    

    const[dimeValue,setdimeValue] = useState("")
    const[creditsValue,setcreditsValue] = useState("")
    const[daiAmount,setdaiAmount] = useState("")
    const[dimeAmount,setdimeAmount] = useState("")
   
    
    const [daiBalance, setDaiBalance] = useState("");
    const [dimeBalance, setDimeBalance] = useState("");
    const [creditsBalance, setCreditsBalance] = useState("");
    
    const [daiPrice,setDaiPrice] = useState("");
    const [dimePrice,setDimePrice] = useState("");
    const [creditsPrice,setCreditsPrice] = useState("");
    

    const[collatout,setcollatout] = useState("")
    const[dimeOut,setdimeOut] = useState("")
    const[inputValue,setinputValue] = useState("")

    const [showTr, setShowTr] = useState(false);
    const [pending, setPending] = useState("");
    const [txId, setTxId] = useState("");
    const [TxnImage, setTxnImage] = useState("");
    const [errormsg, seterrormsg] = useState("");
    const handleCloseTransation = () => setShowTr(false);
    const handleShowTransation = () => setShowTr(true);

   // Create contract instance with the correct order of arguments
    const creditPoolContractinstance = new ethers.Contract(Credits_Pool_Address, credits_pool_ABI, provider);
    const daiContractinstance = new ethers.Contract(DAI_TOKEN_Address, DAI_TOKEN_ABI, provider);
    const creditscontractinstance = new ethers.Contract(CREDITS_Token_Address, CREDITS_Token_ABI, provider);
    const dimecontractinstance = new ethers.Contract(DIME_Token_Address, DIME_Token_ABI, provider);
 const getAllBalanceFunction = async() =>{
        if(isConnected){
            let daiBalance = await getTokenBalance(address,DAI_TOKEN_Address,DAI_TOKEN_ABI);
            console.log("daiBalance",daiBalance)
         
            setDaiBalance(daiBalance);
           
            let dimeBalance = await getTokenBalance(address,DIME_Token_Address,DIME_Token_ABI);
            console.log("dimeBalance",dimeBalance)
         
            setDimeBalance(dimeBalance);

            let creditsBalance = await getTokenBalance(address,CREDITS_Token_Address,CREDITS_Token_ABI);
            console.log("creditBalance",creditsBalance)
         
            setCreditsBalance(creditsBalance);

            let priceOracleDai = await getDAIPrice();
            setDaiPrice(priceOracleDai)
            let priceOracleDime = await getDimePrice();
            setDimePrice(priceOracleDime)

            let priceOraclecredit = await getCreditsPrice();
            setCreditsPrice(priceOraclecredit)
            // let jokerallowance = await CheckAllowance(JOKER_Token_Address,JOKER_Token_ABI,address,address);
            // setJokerAllowance(jokerallowance);

        }
        
    }

    useEffect(() =>{getAllBalanceFunction()},[address,isConnected]);
    function formatValues (values,digitsToPrint) {
        return parseFloat(values).toLocaleString(undefined, { minimumFractionDigits: digitsToPrint, useGrouping: false });
    }

    const creditsCalculation = async() =>{
        await recordUserVisits(address, "Credits Redeem");
        if(isConnected){
          
            let credits_price =  ethers.utils.formatUnits(await creditPoolContractinstance.getFRAXPrice(),0);
            console.log("credits_price")
            let mint_price_threshold =  ethers.utils.formatUnits(await creditPoolContractinstance.mint_price_threshold(),0);
            console.log("collateral_price", credits_price,mint_price_threshold)
            
            let redeem_price_threshold =  ethers.utils.formatUnits(await creditPoolContractinstance.redeem_price_threshold(),0);
            if(parseInt(credits_price) <= parseInt(redeem_price_threshold)){
                setRedeemEnabled(true)
                console.log("Redeem ENabled",true,redeem_price_threshold,credits_price)
            }
            else{
                setRedeemEnabled(false)
                console.log("Redeem ENabled",false)
            }
            
            let daiallowance =  ethers.utils.formatUnits(await daiContractinstance.allowance(address,Credits_Pool_Address),0);
            console.log("allowance", daiallowance)
            setDaiAllowance(daiallowance);

            let creditsallowance = ethers.utils.formatUnits(await creditscontractinstance.allowance(address,Credits_Pool_Address),0);
            setCreditsAllowance(creditsallowance)

            let dimesallowance = ethers.utils.formatUnits(await dimecontractinstance.allowance(address,Credits_Pool_Address),0);
            console.log("dimeallowance", dimesallowance)
            setDimeAllowance(dimesallowance)

            const PRICE_PRECISION = 1e6; // Adjust as needed, ensure it matches the Solidity contract's precision
            let redemption_fee = 5500;
            setRedeempercent(redemption_fee/PRICE_PRECISION);
            let col_ratio =  await ethers.utils.formatUnits((await creditscontractinstance.global_collateral_ratio()),0);
            let Overallcol_ratio=parseFloat(col_ratio/PRICE_PRECISION)*100;
            setUsdcColratio(Overallcol_ratio);



        }
      }
      useEffect(()=>{creditsCalculation()},[address,isConnected])


      const  calculateRedeemValues = async(
        col_idx,
        credits_amount,
        dime_out_min,
        col_out_min        
      )  => {
        // Constants
        const PRICE_PRECISION = 1e6; // Adjust as needed, ensure it matches the Solidity contract's precision
        let redemption_fee = 5500;
        setRedeempercent(redemption_fee/PRICE_PRECISION);
        // Calculate frax_after_fee
        const credits_after_fee = (credits_amount * (PRICE_PRECISION - redemption_fee)) / PRICE_PRECISION;
        setRedeemfee(credits_after_fee)
        console.log("col_ratio",credits_after_fee, credits_amount)
        // Initialize values
        let collat_out = 0;
        let dime_out = 0;
       
        let col_ratio =  await ethers.utils.formatUnits((await creditscontractinstance.global_collateral_ratio()),0);
        let Overallcol_ratio=parseFloat(col_ratio/PRICE_PRECISION)*100;
        let dimecol_ratio=parseFloat(100 - Overallcol_ratio);
        setUsdcColratio(Overallcol_ratio);
        setDimeColratio(dimecol_ratio);
        // Assumes $1 FRAX in all cases
        if (col_ratio >= PRICE_PRECISION) {
          // 1-to-1 or overcollateralized
          collat_out = await ethers.utils.formatUnits(await creditPoolContractinstance.getFRAXInCollateral(col_idx, BigInt(credits_after_fee)),0);
        } else if (col_ratio == 0) {
          // Algorithmic
          const splited_value = (credits_after_fee * 50) / 100;
          dime_out = (splited_value * PRICE_PRECISION) /await ethers.utils.formatUnits(await creditPoolContractinstance.getFXSPrice(), 0);
         // black_out = (frax_after_fee - splited_value) * PRICE_PRECISION / ((await ethers.utils.formatUnits(await JusdPoolContract.getFRAXPrice(), 0)) );
        } else {
          // Fractional
        
          let credits_in = ( ethers.utils.formatUnits(await creditPoolContractinstance.getFRAXInCollateral(col_idx, BigInt(credits_after_fee)),0));
          console.log("col_ratio",credits_in)
          collat_out = ( credits_in * col_ratio) / PRICE_PRECISION;
         
          const splited_value = (credits_after_fee * 50) / 100;
          dime_out = (splited_value * (PRICE_PRECISION - col_ratio)) / await ethers.utils.formatUnits(await creditPoolContractinstance.getFXSPrice(), 0);;
          //black_out = (frax_after_fee - splited_value) * (PRICE_PRECISION - col_ratio) / ((await ethers.utils.formatUnits(await JusdPoolContract.getFRAXPrice(), 0)) );
        }
        setcollatout(collat_out);
        setdimeOut(dime_out)
       
        return {
          collat_out,
          dime_out,
        };
      }

      const getReddemValue = async(val) =>{
        setinputValue(val*1e18)
        let col_idx = 0;
        // let frax_amount = e *1e18;
        const values = await calculateRedeemValues(col_idx,val*1e18,1,1);
        console.log("values",values)
    }


    const redeemdime = async() =>{
        if(isConnected){
        try{
            const ethersProvider =  new ethers.providers.Web3Provider(walletProvider)
            const signer =  ethersProvider.getSigner()
            // Create contract instance with the correct order of arguments
            const creditPoolContractinstance = new ethers.Contract(Credits_Pool_Address, credits_pool_ABI, signer);
            await showPopUp("yes",TransactionPending,"","");
            // Send the transaction and wait for it to be mined
            const redeemTx = await creditPoolContractinstance.redeemFrax(0,BigInt(inputValue),1,1);
            const collectRedemptionTx = await creditPoolContractinstance.collectRedemption(0);
            console.log("redeemTx",redeemTx.hash);
            await redeemTx.wait();
            await collectRedemptionTx.wait();
            await TxnId_PopUp(redeemTx.hash);
            await balanceOfTokens();
            await createTxn("DAI",redeemTx.hash,"Redeem",address,Credits_Pool_Address);
            await resetState();
        
            
        }catch(err){
            showPopUp("not",TransactionPending,"",(err.reason).toString());
            console.log("error",err);
        }
        }
    }

    const approve = async() =>{
        if(isConnected){
          
        try{
           
            const ethersProvider =  new ethers.providers.Web3Provider(walletProvider)
            const signer =  ethersProvider.getSigner() 
            
            // Create contract instance with the correct order of arguments
            const creditscontractinstance = new ethers.Contract(CREDITS_Token_Address, CREDITS_Token_ABI, signer);
            await showPopUp("yes",TransactionPending,"","");
            
            const approveTx = await creditscontractinstance.approve(Credits_Pool_Address,BigInt(100000*1e18));
          
            await approveTx.wait();
            await TxnId_PopUp(approveTx.hash);
        }catch(err){
            showPopUp("not",TransactionPending,"",(err.reason).toString());
            console.log("error",err);
        }
        }
    }

    const TxnId_PopUp =async(hash) =>{
        let txnid = TXN_ID+hash;
        showPopUp("no",TransactionPending,txnid,"");
        await creditsCalculation();
       
    }

    const showPopUp = async(pendingvalue,imgSrc,txid,errormsg)=>{
        // console.log("showPopUp",pendingvalue,imgSrc,txid,errormsg)
        setPending(pendingvalue);
        setTxId(txid);
        setTxnImage(imgSrc);
        seterrormsg(errormsg);    
        handleShowTransation();        
    }

    const resetState = async() =>{
        
        setdimeAmount("");
        setdaiAmount("");
    }
    const connectWallet = async (e) => {
        e.preventDefault();
        await ConnectWallet();
    }


    return (  
        <LayoutAfterLogin menuActive={"tau"} balances={balances}>
            <div className="py-3 px-3 price-list border-bottom border-3">
                <Row className="g-3 justify-content-around align-items-center">
                    <Col lg="2" md={3} xs={6}>
                        <h4 className="mb-1">CREDITS Price </h4>
                        <h4 className="text-gray mb-0">${creditsPrice?(creditsPrice/1e8):'0.0'}</h4>
                    </Col>
                    <Col lg="2" md={3} xs={6}>
                        <h4 className="mb-1">DIME Price </h4>
                        <h4 className="text-gray mb-0">${dimePrice?(dimePrice/1e8):'0.0'}</h4>
                    </Col>
                    <Col lg="2" md={3} xs={6}>
                        <h4 className="mb-1">Collateral Ratio </h4>
                        <h4 className="text-gray mb-0"> {(parseFloat(usdccolratio)) ? (parseFloat(usdccolratio)): '0.00'}%</h4>
                    </Col>
                    <Col lg="2" md={3} xs={6}>
                        <h4 className="mb-1">Redemption Fee </h4>
                        <h4 className="text-gray mb-0">{(parseFloat(redeempercent)) ? (parseFloat(redeempercent)): '0.00'}%</h4>
                    </Col>
                  
                </Row>
            </div>
            <div className="p-24 app-contain">
                <div className="box p-3 px-md-4">
                    <Row className="align-items-center g-md-5">
                        <Col md={4}>
                            <div className="redeem-card pb-lg-0 pb-4 pt-lg-4 mt-lg-1">
                                <div className="text-center mt-lg-4 mb-lg-0 mb-md-4 pb-lg-0 pb-md-1">
                                    <h4 className="text-blue flex justify-content-center g-2 mb-1"><img width='18' src={CreditsIcon} alt="DimeIcon" /> CREDITS</h4>

                                    <input type='number' placeholder="0.00" className="form-control border-0 mb-1" onChange={(e) => getReddemValue(e.target.value)} />
                                    <p className="mb-2"><small>{parseFloat(creditsBalance) === 'NaN' ? '0.00' :  formatValues((creditsBalance/1e18),2)} Available</small></p>
                                    <p><small>Credits_Pool : <a href={`${config.explorerUrl}/address/${Credits_Pool_Address}`} target="_blank" rel="noopener noreferrer" className="btn-link">{`${Credits_Pool_Address.substring(0, 8)}...${Credits_Pool_Address.substring(Credits_Pool_Address.length - 4)}`}</a></small></p>
                                </div>
                            </div>
                        </Col>
                        <Col md={8}>
                            <div className="redeem-chart d-flex flex-column justify-content-between">
                                <div className="redeem-chart-row ">
                                    <Row className="g-lg-5 g-2 align-items-end ">
                                        <Col lg={8}>
                                            <div className="flex g-1 redeem-chart-cell mt-4 ">
                                                <div className="redeem-chart-percent ">
                                                {(parseFloat(redeempercent)) ? (parseFloat(redeempercent)): '0.00'}%
                                
                                                </div>
                                                <div className="redeem-chart-mid ">
                                                    <h5 className="text-blue mb-0">Redemption Fee</h5>
                                                </div>
                                                <button className="btn-reset"><img src={ArrowRight} alt="arrow right" /></button>
                                            </div>
                                        </Col>
                                        <Col lg={4}>

                                        <div>
                                        {/* <h4 className="text-blue mb-1">You will Receive</h4> */}
                                        <div className="flex g-2">
                                            <img width={25} src={CreditsIcon} alt="DollerIcon" />
                                            <input type="text" className="form-control" placeholder="0.00" value={(parseFloat(redeemfee)/1e18) ? (parseFloat(redeemfee)/1e18).toFixed(8) : '0.00'} />
                                        </div>
                                        </div>

                                        </Col>
                                    </Row>
                                </div>
                                <div className="redeem-chart-row">
                                    <Row className="g-lg-5 g-2 align-items-end">
                                        <Col lg={8}>
                                            <div className="flex g-1 redeem-chart-cell">
                                                <div className="redeem-chart-percent">
                                                {(parseFloat(usdccolratio)) ? (parseFloat(usdccolratio)): '0.00'}%
                                                </div>
                                                <div className="redeem-chart-mid">
                                                    <h5 className="text-blue mb-0">Collateral</h5>
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="dark" id="dropdown-basic" className="no-caret">
                                                            <img src={DollerIcon} width={13} alt="DollerIcon" /> DAI
                                                        </Dropdown.Toggle>

                                                        {/* <Dropdown.Menu>
                                                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                                        </Dropdown.Menu> */}
                                                    </Dropdown>
                                                </div>
                                                <button className="btn-reset"><img src={ArrowRight} alt="arrow right" /></button>
                                            </div>
                                        </Col>
                                        <Col lg={4}>

                                        <div>
                                            <h4 className="text-blue mb-1">You will Receive</h4>
                                        <div className="flex g-2">
                                        <img width={25} src={DollerIcon} alt="DollerIcon" />
                                            <input readonly type="number" className="form-control" placeholder="0.00" value={parseFloat(collatout) ? (parseFloat(collatout)/1e18).toFixed(8) : '0.00'} />
                                        </div>
                                        </div>

                                        </Col>
                                    </Row>
                                </div>
                                <div className="redeem-chart-row">
                                    <Row className="g-lg-5 g-2 align-items-end">
                                        <Col lg={8}>
                                            <div className="flex g-1 redeem-chart-cell">
                                                <div className="redeem-chart-percent">
                                                {(parseFloat(dimecolratio)) ? (parseFloat(dimecolratio)): '0.00'}%
                                                </div>
                                                <div className="redeem-chart-mid">
                                                    <h5 className="text-blue flex h-2 mb-0"><img width='18' src={DimeIcon} alt="DimeIcon" /> DIME</h5>
                                                </div>
                                                <button className="btn-reset"><img src={ArrowRight} alt="arrow right" /></button>
                                            </div>
                                        </Col>
                                        <Col lg={4}>

                                        <div>
                                        <h4 className="text-blue mb-1">You will Receive</h4>
                                        <div className="flex g-2">
                                        <img width={25} src={DimeIcon} alt="DollerIcon" />
                                            <input type="text" className="form-control" placeholder="0.00" value={parseFloat(dimeOut) ? (parseFloat(dimeOut)/1e18).toFixed(8) : '0.00'} />
                                            </div>
                                            </div>

                                        </Col>
                                        
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <div className="py-md-4 text-center py-3">
                    {isConnected?(<>
                    
                        {parseFloat(creditsAllowance) >=parseFloat(inputValue)?
                    (<>
                        {RedeemEnabled ? 
                                        (<>
                                            <button className="btn btn-grad btn-min px-5"  onClick={redeemdime}>Redeem</button>
                                        </>):(<>
                                                <button disabled={true} className="btn btn-grad btn-min px-5">CREDITS Redeem is Currently Not Available</button>
                                            </>)
                        }
                                            </>):(<>
                                                <button className="btn btn-grad btn-min px-5"onClick={approve}>Approve</button>
                                            </>)
                    
                    }
                    </>):(<>
                        <button className="btn btn-grad btn-min px-5" onClick={connectWallet}>Connect Wallet</button>
                    
                    </>)}
                    
                    
                    </div>
                </div>



                
            </div>
            <PendingModal pending={pending} showTr={showTr}  handleCloseTransation={handleCloseTransation} imgSrc={TxnImage} txId={txId} errormsg={errormsg}/>

        </LayoutAfterLogin>
    );
}

export default Redeem;