import { NavLink } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import SocialIcon1 from '../asserts/IMAGES/social-icon-1.svg'
import SocialIcon2 from '../asserts/IMAGES/social-icon-2.svg'
import SocialIcon3 from '../asserts/IMAGES/social-icon-3.svg'
import SocialIcon4 from '../asserts/IMAGES/social-icon-4.svg'
import MenuIcon1 from '../asserts/IMAGES/menu-icon-db.svg'
import MenuIcon2 from '../asserts/IMAGES/menu-icon-jp.svg'
import MenuIconWallet from '../asserts/IMAGES/menu-icon-wallet.svg'
import MenuIconBond from '../asserts/IMAGES/menu-icon-bond.svg'
import MenuIconMCD from '../asserts/IMAGES/menu-icon-mcd.svg'
import MenuIconStaking from '../asserts/IMAGES/menu-icon-staking.svg'
import MenuIconLP from '../asserts/IMAGES/menu-icon-lp.svg'
import MenuIconCredits from '../asserts/IMAGES/menu-icon-credits.svg'
import MenuIconCDB from '../asserts/IMAGES/menu-icon-cdb.svg'
import MenuIconVault from '../asserts/IMAGES/menu-icon-vault.svg'
import MenuIconStabilizer from '../asserts/IMAGES/menu-icon-stabilizer.svg'
import Logo from '../asserts/IMAGES/logo-icon.svg'
import swapLogo from '../asserts/IMAGES/Swap.svg';
import faucetlogo from '../asserts/IMAGES/Faucet.svg';
import Anayticslogo from '../asserts/IMAGES/Analytics.svg';
import Recollateralizelogo from '../asserts/IMAGES/Recollateralize.svg';
import Buybacklogo from '../asserts/IMAGES/Buyback.svg';
import Redeemlogo from '../asserts/IMAGES/Redeem.svg';
import Carbonixlogo from '../asserts/IMAGES/Carbonix.svg';
import Rolloverlogo from '../asserts/IMAGES/Rollover.svg';
import Mintlogo from '../asserts/IMAGES/Mint.svg';

function Sidebar({view, menuActive}) {
    return (    
        <aside className={`sidebar ${view === true && 'active'}`}>
            <div className="header-logo d-xl-none py-2 mb-2" to="/"><img src={Logo} alt="logo" /></div>

            <div className='sidebar-menu'>
                <Accordion defaultActiveKey={menuActive}>
                    <Accordion.Item eventKey="joker-protocol">
                        <Accordion.Header><img src={MenuIcon2} alt='Icon' /> Joker Protocol</Accordion.Header>
                        <Accordion.Body>
                            <NavLink activeClassName='active'  to="/">
                                <img src={MenuIcon1} alt="Icon" />
                                <span>Dashboard</span>
                            </NavLink>
                            <NavLink activeClassName='active'  to="/mint-credit-dime">
                                <img src={MenuIconMCD} alt="Icon" />
                                <span>Genesis Minting</span>
                            </NavLink>
                            <NavLink activeClassName='active'  to="/bond">
                                <img src={MenuIconBond} alt="Icon" />
                                <span>Bond</span>
                            </NavLink>
                            <NavLink activeClassName='active'  to="/swap">
                                <img src={swapLogo} alt="Icon" />
                                <span>Controlled Swap</span>
                            </NavLink>
                            <NavLink activeClassName='active'  to="/tau-bond">
                                <img src={Rolloverlogo} alt="Icon" />
                                <span>RollOver</span>
                            </NavLink>                          
                           <NavLink activeClassName='active'  to="/staking">
                                <img src={MenuIconStaking} alt="Icon" />
                                <span>Staking</span>
                            </NavLink>
                            {/* <NavLink activeClassName='active'  to="/launch-pad">
                                <img src={MenuIconLP} alt="Icon" />
                                <span>Launch Pad</span>
                            </NavLink> */}
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="carbonix">
                        <Accordion.Header><img src={Carbonixlogo} alt='Icon' /> Carbonix</Accordion.Header>
                        <Accordion.Body>
                            <NavLink activeClassName='active'  to="/carbonix">
                                <img src={MenuIconCDB} alt="Icon" />
                                <span>Dashboard</span>
                            </NavLink>
                            <NavLink activeClassName='active'  to="/vault">
                                <img src={MenuIconVault} alt="Icon" />
                                <span>Vault</span>
                            </NavLink>
                            <NavLink activeClassName='active'  to="/stabilizer">
                                <img src={MenuIconStabilizer} alt="Icon" />
                                <span>Stabilizer</span>
                            </NavLink>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="tau">
                        <Accordion.Header><img src={MenuIconCredits} alt='Icon' /> Credits</Accordion.Header>
                        <Accordion.Body>
                            <NavLink activeClassName='active'  to="/mint-frax">
                            <img src={Mintlogo} alt="Icon" />
                                <span>Mint</span>
                            </NavLink>
                            <NavLink activeClassName='active'  to="/redeem">
                            <img src={Redeemlogo} alt="Icon" />
                                <span>Redeem</span>
                            </NavLink>
                            <NavLink activeClassName='active'  to="/buyback">
                            <img src={Buybacklogo} alt="Icon" />
                                <span>BuyBack</span>
                            </NavLink>
                            <NavLink activeClassName='active'  to="/recollateralize">
                            <img src={Recollateralizelogo} alt="Icon" />
                                <span>Recollateralize</span>
                            </NavLink>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item>
                    {/* <Accordion.Item eventKey="analytics">
                        <Accordion.Header><img src={MenuIcon2} alt='Icon' /> Analytics</Accordion.Header>
                        <Accordion.Body> */}
                          <div className="accordion-collapse collapse show">
                            <div className='accordion-body'>
                            <NavLink activeClassName='active'  to="/analytics">
                            <img src={Anayticslogo} alt='Icon' /> 
                                <span>Analytics</span>
                            </NavLink>
                            </div>
                            </div>
                            </Accordion.Item>
                        {/* </Accordion.Body>
                    </Accordion.Item> */}
                    {/* <Accordion.Item eventKey="faucet">
                        <Accordion.Header><img src={MenuIcon2} alt='Icon' /> Faucet</Accordion.Header>
                        <Accordion.Body> */}
                        <Accordion.Item>
                         <div className="accordion-collapse collapse show">
                            <div className='accordion-body'>
                            <NavLink activeClassName='active'  to="/faucet">
                            <img src={faucetlogo} alt='Icon' />
                                <span>Faucet</span>
                            </NavLink>
                            </div>
                            </div>
                        </Accordion.Item>
                        {/* </Accordion.Body>
                    </Accordion.Item> */}
                </Accordion>
            </div>
            
            <div className='sidebar-footer text-center d-flex flex-column align-items-center justify-content-center'>
                <div className='d-flex align-items-center justify-content-center'>
                    {/* <a href='/#'><img src={SocialIcon1} alt="Icon" /></a> */}
                    <a href='https://twitter.com/Jok3rFi' target ='_blank' rel='noreferrer'><img src={SocialIcon2} alt="Icon" /></a>
                    <a href='https://t.me/Jokerprotocol' target ='_blank' rel='noreferrer'><img src={SocialIcon3} alt="Icon" /></a>
                    <a href='https://github.com/JOK3RFI' target ='_blank' rel='noreferrer'><img src={SocialIcon4} alt="Icon" /></a>
                </div>
                <p>© 2024  Joker Foundation. <br />All rights reserved.</p>
            </div>
        </aside>
     );
}

export default Sidebar;