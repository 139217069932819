import { Form } from "react-bootstrap";
import Bardata from "./BarChart";
import { getTreasuryOneMonth, getTreasuryOneWeek, getTreasuryOneYear, updatetreasuryPrice } from "../../../abi/firebasecode";
import { getJokerPrice, getDimePrice, getCreditsPrice, getDAIPrice, getTokenBalance, NumberAbbreviation } from "../../../abi/CommonSolFunctions";
import { useEffect, useState } from "react";
import { CHAIN_URL, ECO_Reserve_Treasury_Address, Chainlink_Oracle_ABI, DAI_TOKEN_ABI, DAI_TOKEN_Address, DAI_Chainlink_Oracle_Address, DIME_Token_Address, DIME_Token_ABI, DIME_Chainlink_Oracle_Address, JOKER_Token_ABI, JOKER_Token_Address, JOKER_Chainlink_Oracle_Address,  CREDITS_Token_Address, CREDITS_Token_ABI, CREDITS_Chainlink_Oracle_Address } from "../../../abi/ABI&ContractAddress";

function TreasuryCard() {
    
    const [Oneyearvalue, setOneyearvalue] = useState(0);
    const [Oneyeardates, setOneyeardates] = useState(0);
    const [Oneyearprices, setOneyearprices] = useState(0);
    
    const [activeButton, setActiveButton] = useState('1Y');
    const [jokerPrice, setjokerPrice] = useState("");
    const[daiPrice,setdaiPrice] = useState("");
    const[dimePrice,setdimePrice] = useState("");
    const[creditsPrice,setcreditsPrice] = useState("");
    const [TotalSupply, setTotalSupply] = useState("");
    const [title, setTitle] = useState("price");

    const[daiBalanceT,setdaiBalanceT] = useState("");
    const[jokerBalanceT,setjokerBalanceT] = useState("");
    const[dimeBalanceT,setdimeBalanceT] = useState("");
    const[creditsBalanceT,setcreditsBalanceT] = useState("");
    const[Price,setPrice] = useState(0.00);
    const[Supply,setSupply] = useState(0.00);

    // console.log("getMonthValues", Oneyearvalue);

    const getallvalues = async() =>{
        let oneyearvalue = await getTreasuryOneYear("price")
        setOneyearvalue(oneyearvalue)
        const dates = Object.keys(oneyearvalue);
        setOneyeardates(dates)
        const prices = Object.values(oneyearvalue);
        
        setOneyearprices(prices)

        setjokerPrice(await getJokerPrice())
        // setTotalSupply(await JOKERContract.totalSupply())


    }
    useEffect(() =>{getallvalues();},[])

    const getAssetValues = async() => {
      let dprice = await getDimePrice()
      setdimePrice(dprice);
      let cprice = await getCreditsPrice()
      setcreditsPrice(cprice);
      let daiprice = await getDAIPrice()
      setdaiPrice(daiprice);
      let jprice = await getJokerPrice()

          let daiBalanceT = await getTokenBalance(ECO_Reserve_Treasury_Address,DAI_TOKEN_Address,DAI_TOKEN_ABI);
          setdaiBalanceT(daiBalanceT);
          let jokerBalanceT = await getTokenBalance(ECO_Reserve_Treasury_Address,JOKER_Token_Address,JOKER_Token_ABI);
          setjokerBalanceT(jokerBalanceT);
          let dimeBalanceT = await getTokenBalance(ECO_Reserve_Treasury_Address,DIME_Token_Address,DIME_Token_ABI);
          setdimeBalanceT(dimeBalanceT);
          let creditsBalanceT = await getTokenBalance(ECO_Reserve_Treasury_Address,CREDITS_Token_Address,CREDITS_Token_ABI);
          setcreditsBalanceT(creditsBalanceT);
          handleOptionChange("price");

          //push to database
          let currentTime = (new Date().getTime())/1000;
          let totalprice = ((jokerBalanceT*jprice/1e17)+(dimeBalanceT*dprice/1e17),(creditsBalanceT*cprice/1e26),(daiBalanceT*daiprice/1e26))
          await updatetreasuryPrice(totalprice,currentTime,(jokerBalanceT/1e9),(dimeBalanceT/1e9),(creditsBalanceT/1e18),(daiBalanceT/1e18)) 
    }
    useEffect(() =>{getAssetValues();},[])

     // Function to handle button click
     const handleButtonClick = async(value) => {
        setActiveButton(value);
        if(value == '1W'){
          let oneyearvalue = await getTreasuryOneWeek("price")
          setfunction(oneyearvalue)
        }else if(value == '1M'){
          let oneyearvalue = await getTreasuryOneMonth("price")
          setfunction(oneyearvalue)
  
        }else if(value == '1Y'){
          let oneyearvalue = await getTreasuryOneYear("price")
          setfunction(oneyearvalue)
        }else{
  
        }
  
      };
      const handleButtonClicksupply = async(value) => {
          setActiveButton(value);
          if(value == '1W'){
            let oneyearvalue = await getTreasuryOneWeek("jokerValue")
            setfunction(oneyearvalue)
          }else if(value == '1M'){
            let oneyearvalue = await getTreasuryOneMonth("jokerValue")
            setfunction(oneyearvalue)
    
          }else if(value == '1Y'){
            let oneyearvalue = await getTreasuryOneYear("jokerValue")
            setfunction(oneyearvalue)
          }else{
    
          }
    
        };
  
        const handleButtonClickMarketcap = async(value) => {
          setActiveButton(value);
          if(value == '1W'){
            let oneyearvalue = await getTreasuryOneWeek("dimeValue")
            setfunction(oneyearvalue)
          }else if(value == '1M'){
            let oneyearvalue = await getTreasuryOneMonth("dimeValue")
            setfunction(oneyearvalue)
    
          }else if(value == '1Y'){
            let oneyearvalue = await getTreasuryOneYear("dimeValue")
            setfunction(oneyearvalue)
          }else{
    
          }
    
        };

        const handleButtonClickCredits = async(value) => {
            setActiveButton(value);
            if(value == '1W'){
              let oneyearvalue = await getTreasuryOneWeek("creditsValue")
              setfunction(oneyearvalue)
            }else if(value == '1M'){
              let oneyearvalue = await getTreasuryOneMonth("creditsValue")
              setfunction(oneyearvalue)
      
            }else if(value == '1Y'){
              let oneyearvalue = await getTreasuryOneYear("creditsValue")
              setfunction(oneyearvalue)
            }else{
      
            }
      
          };
          const handleButtonClickUSDC = async(value) => {
            setActiveButton(value);
            if(value == '1W'){
              let oneyearvalue = await getTreasuryOneWeek("USDCValue")
              setfunction(oneyearvalue)
            }else if(value == '1M'){
              let oneyearvalue = await getTreasuryOneMonth("USDCValue")
              setfunction(oneyearvalue)
      
            }else if(value == '1Y'){
              let oneyearvalue = await getTreasuryOneYear("USDCValue")
              setfunction(oneyearvalue)
            }else{
      
            }
      
          };
  
      const callselectedFunction = async(value) =>{
          console.log("selected function",title)
          if(title === "price"){
              handleButtonClick(value)
          }else if(title === "jokerValue"){
              handleButtonClicksupply(value)
  
          }else if(title === "dimeValue"){
              handleButtonClickMarketcap(value)
          }else if(title === "creditsValue"){
            handleButtonClickCredits(value)
        }else if(title === "USDCValue"){
            handleButtonClickUSDC(value)
        }
  
      }
  
      const setfunction = async(oneyearvalue) => {
          const dates = Object.keys(oneyearvalue);
          setOneyeardates(dates)
          const prices = Object.values(oneyearvalue);
          setOneyearprices(prices)
      }
  
      const OptionCall = async(titlevalue) =>{
          console.log("titlevalue", titlevalue)
          setTitle(titlevalue)
          setActiveButton('1Y')
          handleOptionChange(titlevalue);
        let oneyearvalue = await getTreasuryOneYear(titlevalue)
        setOneyearvalue(oneyearvalue)
        const dates = Object.keys(oneyearvalue);
        setOneyeardates(dates)
        const prices = Object.values(oneyearvalue); 
        setOneyearprices(prices)
  
      }

      const handleOptionChange = (event) => {
        console.log("event", event);
        if(event === "jokerValue" || event === "price"){
          setPrice(jokerPrice);
          setSupply(parseFloat((jokerBalanceT)/1e9));
      }else if(event === "dimeValue"){
          setPrice(dimePrice);
          setSupply(parseFloat((dimeBalanceT)/1e9));
      }else if(event === "creditsValue"){
          setPrice(creditsPrice);
          setSupply(parseFloat((creditsBalanceT)/1e18));
      }else if(event === "USDCValue"){
          setPrice(daiPrice);
          setSupply(parseFloat((daiBalanceT)/1e18));
      }
      }

    return ( 
        <div className="box">
            <div className="p-3 d-flex align-items-center flex-warp">
                <h4 className="mb-0">Treasury</h4>
                
                <div className="chart-filter d-flex align-items-center ms-auto">
                {/* <button
                    className={activeButton === '1D' ? 'active' : ''}
                    onClick={() => callselectedFunction('1D')} >
                    1D
                    </button> */}
                    <button
                    className={activeButton === '1W' ? 'active' : ''}
                    onClick={() => callselectedFunction('1W')} >
                    1W
                    </button>
                    <button
                    className={activeButton === '1M' ? 'active' : ''}
                    onClick={() => callselectedFunction('1M')} >
                    1M
                    </button>
                    <button
                    className={activeButton === '1Y' ? 'active' : ''}
                    onClick={() => callselectedFunction('1Y')} >
                    1Y
                    </button>
                </div>
            </div>

            <div className="chart-header d-flex align-items-center flex-wrap">
                <div>
                    <h6>Price</h6>
                    <h5>${Price ? (Price / 1e8).toFixed(2) : "0.00"}</h5>
                </div>
                <div>
                    <h6>Supply</h6>
                    <h5><NumberAbbreviation number={Supply ? (Supply).toFixed(2) : "0.00"}/></h5>
                </div>
                <div>
                    <h6>Market Cap</h6>
                    <h5>$<NumberAbbreviation number={(Price && Supply) ? (Supply *(Price / 1e8)).toFixed(2) : "0.00"}/></h5>
                </div>

                <div className="border-0">
                <Form.Select size="lg" onChange={(e) => OptionCall(e.target.value)}>
                    <option value="price">Total Price</option>
                    <option value="jokerValue">JOKER value</option>
                    <option value="dimeValue">DIME value</option>
                    <option value="creditsValue">CREDITS value</option>
                    <option value="USDCValue">USDC value</option>
                </Form.Select>
                </div>

                {/* <div className="border-0">
                    <Form.Select size="lg">
                        <option>Price</option>
                        <option>JOKER value</option>
                        <option>DIME value</option>
                        <option>CREDITS value</option>
                        <option>USDT value</option>
                    </Form.Select>
                </div> */}
            </div>

            <div className="p-3">
                <Bardata labels={Oneyeardates?Oneyeardates:'0'} datas={Oneyearprices?Oneyearprices:'0'} />
            </div>
        </div>
     );
}

export default TreasuryCard;