import React, { useEffect, useState } from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import LayoutAfterLogin from "../../component/LayoutAL";
import Tooltip from "../../Snippets/Tooltip";
import DollerIcon from '../../asserts/IMAGES/dai.svg'
import DimeIcon from '../../asserts/IMAGES/dimeCyanLogo.svg'
import ArrowRight from '../../asserts/IMAGES/arrowRightLG.svg'
import TransactionPending from '../../asserts/IMAGES/transsationPending.svg';
import { CheckAllowance, PendingModal, convertEpochToDateTime, getGasBalance, getTokenBalance ,getDAIPrice,getDimePrice,getCreditsPrice, ConnectWallet,config} from "../../abi/CommonSolFunctions";
import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers5/react";
import { CHAIN_URL,DAI_TOKEN_ABI,DIME_Token_ABI,CREDITS_Token_ABI,credits_pool_ABI,DAI_TOKEN_Address,DIME_Token_Address,CREDITS_Token_Address, Credits_Pool_Address,TXN_ID, API_KEY, Network_Name} from '../../abi/ABI&ContractAddress';
// import { formatJsonRpcRequest } from "@json-rpc-tools/utils";
import {ethers} from 'ethers';
import { createTxn, recordUserVisits } from "../../abi/firebasecode";
/* global BigInt */
const Buyback = ({balances, balanceOfTokens}) => {
    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
    const provider = ethers.getDefaultProvider(Network_Name, {
        etherscan: API_KEY});
    

    const[dimeValue,setdimeValue] = useState("")
    const[creditsValue,setcreditsValue] = useState("")
    const[daiAmount,setdaiAmount] = useState("")
    const[dimeAmount,setdimeAmount] = useState("")
   
    
    const [daiBalance, setDaiBalance] = useState("");
    const [dimeBalance, setDimeBalance] = useState("");
    const [creditsBalance, setCreditsBalance] = useState("");
    
    const [daiPrice,setDaiPrice] = useState("");
    const [dimePrice,setDimePrice] = useState("");
    const [creditsPrice,setCreditsPrice] = useState("");
    

    const[collatout,setcollatout] = useState("")
    const[dimeOut,setdimeOut] = useState("")
    const[inputValue,setinputValue] = useState("")

    const [dimeamount, setdimeamount] = useState("");
    const [collvalue, setcollvalue] = useState("");
    const[recollAvaible,setrecollAvaible] = useState(false);
    const [recolfee,setRecolFee] = useState("");
    const [usdccolratio,setUsdcColratio] = useState("");
    const [theoriticallyavail, setTheoriticallyAvail] = useState("");

    const [showTr, setShowTr] = useState(false);
    const [pending, setPending] = useState("");
    const [txId, setTxId] = useState("");
    const [TxnImage, setTxnImage] = useState("");
    const [errormsg, seterrormsg] = useState("");
    const handleCloseTransation = () => setShowTr(false);
    const handleShowTransation = () => setShowTr(true);

   // Create contract instance with the correct order of arguments
    const creditPoolContractinstance = new ethers.Contract(Credits_Pool_Address, credits_pool_ABI, provider);
    const daiContractinstance = new ethers.Contract(DAI_TOKEN_Address, DAI_TOKEN_ABI, provider);
    const creditscontractinstance = new ethers.Contract(CREDITS_Token_Address, CREDITS_Token_ABI, provider);
    const dimecontractinstance = new ethers.Contract(DIME_Token_Address, DIME_Token_ABI, provider);
    
    const getAllBalanceFunction = async() =>{
        await recordUserVisits(address, "Credits Buyback");
        if(isConnected){
            let daiBalance = await getTokenBalance(address,DAI_TOKEN_Address,DAI_TOKEN_ABI);
            console.log("daiBalance",daiBalance)
         
            setDaiBalance(daiBalance);
           
            let dimeBalance = await getTokenBalance(address,DIME_Token_Address,DIME_Token_ABI);
            console.log("dimeBalance",dimeBalance)
         
            setDimeBalance(dimeBalance);

            let creditsBalance = await getTokenBalance(address,CREDITS_Token_Address,CREDITS_Token_ABI);
            console.log("creditBalance",creditsBalance)
         
            setCreditsBalance(creditsBalance);

            let priceOracleDai = await getDAIPrice();
            setDaiPrice(priceOracleDai)
            let priceOracleDime = await getDimePrice();
            setDimePrice(priceOracleDime)

            let priceOraclecredit = await getCreditsPrice();
            setCreditsPrice(priceOraclecredit)
            const theoritically_avil =await ethers.utils.formatUnits(await creditPoolContractinstance.recollatTheoColAvailableE18(), 0) ;
            setTheoriticallyAvail(theoritically_avil);
            const PRICE_PRECISION = 1e6;
            let col_ratio =  await ethers.utils.formatUnits((await creditscontractinstance.global_collateral_ratio()),0);
            let Overallcol_ratio=parseFloat(col_ratio/PRICE_PRECISION)*100;
            setUsdcColratio(Overallcol_ratio);
            const buyback_fee = 6000; 
            setRecolFee(parseFloat(buyback_fee/PRICE_PRECISION)*100);
        }
        
    }

    useEffect(() =>{getAllBalanceFunction()},[address,isConnected]);


    const calculateBuyBackValues = async(
        col_idx,
        dime_dollar_value_d18,
        col_out_min       
      ) =>{
        
       
        // Constants
        const PRICE_PRECISION = 1e6; // Adjust as needed, ensure it matches the Solidity contract's precision
      
        // Get the number of missing decimals for collateral_amount
        const missing_decimals = 0; // Replace with the actual number of missing decimals
        let dime_price = await ethers.utils.formatUnits(await creditPoolContractinstance.getFXSPrice(), 0);
        // Get the BLACK price and other required parameters
        //const black_price = (await ethers.utils.formatUnits(await JusdPoolContract.getBLACKPrice(), 0))// Replace with the actual function to get the BLACK price
        const buyback_fee = 6000; // Replace with the actual buyback fee value (5% fee)
        let col_price = (await ethers.utils.formatUnits(await creditPoolContractinstance.collateral_prices(0), 0));
        let buybackamount = (await ethers.utils.formatUnits(await creditPoolContractinstance.buybackAvailableCollat(), 0));
        //newcode
        if(buybackamount > 0){
            setrecollAvaible(true);
        }
        else{
            setrecollAvaible(false);
        }
        const splited_value = (dime_dollar_value_d18 * PRICE_PRECISION) / dime_price;

        // Calculate fxs_amount
        const dime_amount = (splited_value * 100) / PRICE_PRECISION;

        console.log("dime_amount:", dime_amount);
        const splited_value1 = (dime_amount);
        // const fxs_dollar_value_d181 = (splited_value1 * fxs_price) / PRICE_PRECISION;
        console.log("dime_dollar_value_d181",splited_value,dime_amount,splited_value1)
        // const black_dollar_value_d18 = ((fxs_amount - splited_value1) * (black_price )) / PRICE_PRECISION;
        const collateral_equivalent_d18 = ((dime_dollar_value_d18 ) * PRICE_PRECISION) / (await ethers.utils.formatUnits(await creditPoolContractinstance.collateral_prices(0), 0));
        console.log("collateral_equivalent_d18",collateral_equivalent_d18)
        const col_out = collateral_equivalent_d18 / 1; // In its natural decimals()
        const col_out_after_fee = (col_out * (PRICE_PRECISION - buyback_fee)) / PRICE_PRECISION;

      
        
        // setblackvalue(black_dollar_value_d18);
        setcollvalue(col_out_after_fee)
        // Check for slippage
        if (col_out < col_out_min) {
          throw new Error("Collateral slippage");
        }
        await calculateDimeAmount(dime_dollar_value_d18,col_price,dime_price)
      
        return {
          dime_amount,
          col_out,
        };
      }


      const getbuybackvalue = async(val) =>{

        // Example usage:
                const col_idx = 0; // Replace with your value
                const dime_dollar_value_d18 = val*1e18; // Replace with your value
                const col_out_min = 0; // Replace with your value
                const dime_price = 2; // Replace with your value
                const buyback_fee = 5000; // Replace with your value (5% fee)
                let PRICE_PRECISION = 1e6;
        
                        // Calculate splited_value
                
        
                const values = await calculateBuyBackValues(
                col_idx,
                dime_dollar_value_d18,
                col_out_min    
                );
        
                console.log("dime_amount:", values.dime_amount);
                // console.log("black_dollar_value_d18:", values.black_dollar_value_d18);
                console.log("col_out_after_fee:", values.col_out);
            }

            const calculateDimeAmount = async(dime_dollar_value_d18,collateral_prices,dime_price) =>{
                const PRICE_PRECISION = 1e6; // Replace with the actual value
                const missing_decimals = 0
                const col_idx = 0; // Replace with the desired col_idx value
                const buyback_fee = 6000; 
        
                // Calculate collateral_equivalent_d18
                const collateral_equivalent_d18 = (dime_dollar_value_d18 * collateral_prices) / PRICE_PRECISION;
              
                // Calculate col_out without considering the fee and slippage
                const col_out = collateral_equivalent_d18 * 1;
              
                // Calculate the equivalent black_dollar_value_d18
                // const black_dollar_value_d18 = (col_out / (PRICE_PRECISION - buyback_fee)) * 1e9;
              
                // Calculate the total fxs_dollar_value_d18
                const total_dime_dollar_value_d18 = dime_dollar_value_d18;
              
                // Calculate fxs_amount based on the split (50%)
                const splited_value = (total_dime_dollar_value_d18 * 2) / (dime_price / PRICE_PRECISION);
                const dime_amount = splited_value;
                console.log("dime_amount",dime_amount)
                setdimeamount(dime_amount);
              
                return dime_amount;
              }


              const buyback = async() =>{
                if(isConnected){
                try{
                    const ethersProvider =  new ethers.providers.Web3Provider(walletProvider)
                    const signer =  ethersProvider.getSigner()
                    // Create contract instance with the correct order of arguments
                    const creditPoolContractinstance = new ethers.Contract(Credits_Pool_Address, credits_pool_ABI, signer);
                    await showPopUp("yes",TransactionPending,"","");
                    // Send the transaction and wait for it to be mined
                    const redeemTx = await creditPoolContractinstance.buyBackFxs(0,BigInt(parseInt(dimeamount)),1);
                    console.log("redeemTx",redeemTx.hash);
                    await redeemTx.wait();
                    await TxnId_PopUp(redeemTx.hash);
                    await balanceOfTokens();
                    await createTxn("DAI",redeemTx.hash,"BuyBack",address,Credits_Pool_Address);
                    await resetState();
                
                    
                }catch(err){
                    showPopUp("not",TransactionPending,"",(err.reason).toString());
                    console.log("error",err);
                }
                }
            }
            const TxnId_PopUp =async(hash) =>{
                let txnid = TXN_ID+hash;
                showPopUp("no",TransactionPending,txnid,"");
                await calculateBuyBackValues();
               
            }
        
            const showPopUp = async(pendingvalue,imgSrc,txid,errormsg)=>{
                // console.log("showPopUp",pendingvalue,imgSrc,txid,errormsg)
                setPending(pendingvalue);
                setTxId(txid);
                setTxnImage(imgSrc);
                seterrormsg(errormsg);    
                handleShowTransation();        
            }
        
            const resetState = async() =>{
                
                setdimeAmount("");
                setdaiAmount("");
            }
            
            function formatValues (values,digitsToPrint) {
                return parseFloat(values).toLocaleString(undefined, { minimumFractionDigits: digitsToPrint, useGrouping: false });
            }

            const connectWallet = async (e) => {
                e.preventDefault();
                await ConnectWallet();
            }
    return (  
        <LayoutAfterLogin menuActive={"tau"} balances={balances}>
            <div className="py-3 px-3 price-list border-bottom border-3">
                <Row className="g-3 justify-content-around align-items-center">
                    <Col lg="3" md={3} xs={6}>
                        <div className="redeem-chart-mid">
                            <h5 className="text-blue mb-0">Collateral</h5>
                            <Dropdown>
                                <Dropdown.Toggle variant="dark" id="dropdown-basic" className="no-caret">
                                    <img src={DollerIcon} width={13} alt="DollerIcon" /> DAI
                                </Dropdown.Toggle>

                                {/* <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                </Dropdown.Menu> */}
                            </Dropdown>
                        </div>
                    </Col>
                    <Col lg="3" md={3} xs={6}>
                        <h4 className="mb-1">Theoretically Available </h4>
                        <h4 className="text-gray mb-0">${parseFloat(theoriticallyavail *daiPrice/1e26).toFixed(8)}</h4>
                    </Col>
                    <Col lg="3" md={3} xs={6}>
                        <h4 className="mb-1">Collateral Ratio </h4>
                        <h4 className="text-gray mb-0">{(parseFloat(usdccolratio)) ? (parseFloat(usdccolratio)): '0.00'}%</h4>
                    </Col>
                </Row>
            </div>
            <div className="p-24 py-lg-5 py-md-4 app-contain">
                <div className="box p-3 px-md-4">
                    <Row className="align-items-center my-lg-5 my-md-4">
                        <Col md={4}>
                            <div className="redeem-card">
                                <div className="text-center">
                                    <h4 className="text-blue flex justify-content-center g-2 mb-1"><img width='18' src={DimeIcon} alt="DimeIcon" /> DIME</h4>

                                    <input type="number" placeholder="0.00" className="form-control border-0 mb-1" onChange={(e) => getbuybackvalue(e.target.value)} />
                                    <p className="mb-2"><small>Available</small></p>
                                    <p className="mb-0"><small>{parseFloat(dimeBalance) === 'NaN' ? '0.00' :  formatValues((dimeBalance/1e9),2)}</small></p>
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="redeem-card text-center">
                                <h4 className="text-blue mb-1">Buyback Fee</h4>
                                {/* <h5 className="flex g-1 justify-content-center mb-1"><img width={25} src={DollerIcon} alt="DollerIcon" /> USDC: <span className="text-gray">---</span></h5> */}
                                <h5 className="flex g-1 justify-content-center mb-0"><img width={25} src={DimeIcon} alt="DimeIcon" /> DIME <span className="text-gray"></span></h5>
                                <div className="py-1">
                                    <img src={ArrowRight} alt="ArrowRight" />
                                </div>
                                {/* <p className="mb-1"><small>0.0000% SWAP FEE</small></p> */}
                                <p className="text-warning mb-1"><small> {(parseFloat(recolfee)) ? (parseFloat(recolfee)): '0.00'}</small></p>
                                <p><small>Credits_Pool : <a href={`${config.explorerUrl}/address/${Credits_Pool_Address}`} target="_blank" rel="noopener noreferrer" className="btn-link">{`${Credits_Pool_Address.substring(0, 8)}...${Credits_Pool_Address.substring(Credits_Pool_Address.length - 4)}`}</a></small></p>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="redeem-card text-center">
                                <h4 className="text-blue mb-1">You will Receive</h4>
                                <div className="flex justify-content-center g-2">
                                    <img width={25} src={DollerIcon} alt="DollerIcon" />
                                    <div>
                                        <input readonly type="text" placeholder="0.00" value={parseFloat(collvalue) ? (parseFloat(collvalue)/1e18).toFixed(8): '0.00'} className="form-control border-0" />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                
                    <div className="py-md-4 text-center py-3">
                    {isConnected?(<>
                        {recollAvaible ? (<>
                                
                                <button className="btn btn-grad btn-min px-5" onClick={buyback}>BuyBack</button>
                    </>):(<>
    
                        <button disabled={true} className="btn btn-grad btn-min px-5" onClick={buyback}> Buyback  Not Available</button>
                    
                    </>)}

                    </>):(<>
                        <button className="btn btn-grad btn-min px-5" onClick={connectWallet}>Connect Wallet</button>
                    
                    </>)}
                
                    
                    </div>
                </div>
            </div>
            <PendingModal pending={pending} showTr={showTr}  handleCloseTransation={handleCloseTransation} imgSrc={TxnImage} txId={txId} errormsg={errormsg}/>

        </LayoutAfterLogin>
    );
}

export default Buyback;