import { Badge, Col, ProgressBar, Row } from "react-bootstrap";
import LayoutAfterLogin from "../../component/LayoutAL";
import Tooltip from "../../Snippets/Tooltip";
import BackIcon from '../../asserts/IMAGES/back-white-icon.svg'
import JokerLogo from '../../asserts/IMAGES/joker-icon.svg'
import { Link } from "react-router-dom";
import { useState } from "react";

function StakeWithdrawBorrow({balances}) {
    return (  
        <LayoutAfterLogin menuActive={"joker-protocol"} balances={balances}>
            <div className="p-24 px-xl-5 app-contain">
                <div className="mb-4 mb-lg-5">
                    <Link className="flex g-2 mb-3 text-white"><img src={BackIcon} alt="icon" /> Back</Link>
                    <Row>
                        <Col md={6} xl={8}>
                            <h2 className="mb-2">Stake and Borrow More</h2>
                            <h6 className="text-gray mb-0">Mint sUSD by staking your SNX. SNX stakers earn weekly staking rewards in exchange for managing their Collateralization Ratio and debt. Your staked SNX will be locked for 7 days.</h6>
                        </Col>
                    </Row>
                </div>

                <Row className="g-lg-4 g-3 justify-content-center">
                    <Col md={8} sm={6}>
                        <div className="box mb-20 d-flex flex-column p-3">
                            <div className="d-flex align-items-center text-gray justify-content-between">
                                <span>Liquidate &lt; 180%</span>
                                <span>Target &lt; 540% <Tooltip pos="right" text="Texting" /></span>
                            </div>
                            <ProgressBar className="progress-shadow my-4" variant="green-light" now={90} />
                        </div>
                        <div className="box p-3 p-md-4">
                            <h5 className="flex g-1">Withdraw  <Tooltip text="Lorem Ipsum" /></h5>
                            <div className="swap-card mb-20">
                                <div className="mb-20 d-flex justify-content-between align-items-center">
                                    <h3 className="flex mb-0 g-2">
                                        <img src={JokerLogo} alt="SNXLogo" />
                                        Joker
                                    </h3>
                                    <div className="text-end">
                                        <h3 className="mb-1">Enter Amount</h3>
                                        <h6 className="text-gray mb-0">Unstaked SNX 20.92</h6>
                                    </div>
                                </div>

                                <Row className="g-2">
                                    <Col>
                                        <button className="btn btn-toggle active w-100"><span>25%</span></button>
                                    </Col>
                                    <Col>
                                        <button className="btn btn-toggle w-100"><span>50%</span></button>
                                    </Col>
                                    <Col>
                                        <button className="btn btn-toggle w-100"><span>75%</span></button>
                                    </Col>
                                    <Col>
                                        <button className="btn btn-toggle w-100"><span>100%</span></button>
                                    </Col>
                                </Row>
                            </div>

                            <button type="button" className="btn btn-grad w-100" disabled><span>Withdraw</span></button>
                        </div>
                    </Col>
                    <Col md={4} sm={6}>
                        <div className="box mb-2 swap-value">
                            <div className="p-4 d-flex justify-content-between border-bottom">
                                <h5 className="flex g-1">Current Health  <Tooltip text="Lorem Ipsum" /></h5>
                                <div className="d-flex g-2 text-end flex-column justify-content-end">
                                    <h3 className="text-green-light mb-1">500%</h3>
                                    <Badge bg="green-light" className="me-auto">HEALTHY</Badge>
                                </div>
                            </div>
                            <div className="p-4 d-flex justify-content-between">
                                <h5 className="flex g-1">Target Health  <Tooltip text="Lorem Ipsum" /></h5>
                                <div className="d-flex g-2 text-end flex-column justify-content-end">
                                    <h3 className="text-green-light mb-0">500%</h3>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </LayoutAfterLogin>
    );
}

export default StakeWithdrawBorrow;