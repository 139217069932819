import React, { useEffect, useState } from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import LayoutAfterLogin from "../../component/LayoutAL";
import Tooltip from "../../Snippets/Tooltip";
import DollerIcon from '../../asserts/IMAGES/dai.svg'
import DimeIcon from '../../asserts/IMAGES/dimeCyanLogo.svg'
import ArrowRight from '../../asserts/IMAGES/arrowRightLG.svg'
import TransactionPending from '../../asserts/IMAGES/transsationPending.svg';
import CreditsIcon from '../../asserts/IMAGES/creditsBlackLogo.svg'
import { CheckAllowance, PendingModal,   convertEpochToDateTime, getGasBalance, getTokenBalance ,getDAIPrice,getDimePrice,getCreditsPrice, ConnectWallet,config} from "../../abi/CommonSolFunctions";
import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers5/react";
import { CHAIN_URL,DAI_TOKEN_ABI,DIME_Token_ABI,CREDITS_Token_ABI,credits_pool_ABI,DAI_TOKEN_Address,DIME_Token_Address,CREDITS_Token_Address, Credits_Pool_Address,TXN_ID, Network_Name, API_KEY} from '../../abi/ABI&ContractAddress';
// import { formatJsonRpcRequest } from "@json-rpc-tools/utils";
import {ethers} from 'ethers';
import { createTxn, recordUserVisits } from "../../abi/firebasecode";

/* global BigInt */
const MintFRAX = ({balances, balanceOfTokens}) => {
    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
    const provider = ethers.getDefaultProvider(Network_Name, {
        etherscan: API_KEY});
    const[daiallowance,setDaiAllowance] = useState("")
    const[creditsAllowance,setCreditsAllowance] = useState("")
    const[dimeAllowance,setDimeAllowance] = useState("")
    const[mintenabled,setMintEnabled] = useState(true)
    

    const[dimeValue,setdimeValue] = useState("")
    const[creditsValue,setcreditsValue] = useState("")
    const[daiAmount,setdaiAmount] = useState("")
    const[dimeAmount,setdimeAmount] = useState("")
    const [daiBalance, setDaiBalance] = useState("");
    const [dimeBalance, setDimeBalance] = useState("");
    const [daiPrice,setDaiPrice] = useState("");
    const [dimePrice,setDimePrice] = useState("");
    const [creditsPrice,setCreditsPrice] = useState("");
    const [colratio,setCollateralRatio] = useState("");
    const [usdccolratio,setUsdcColratio] = useState("");
    const [mintfee,setMintfee] = useState("");
    const [poolceiling,setpoolceiling] = useState("");
    const [mintpricethreshold,setmintpricethreshold] = useState("");
    
    const [showTr, setShowTr] = useState(false);
    const [pending, setPending] = useState("");
    const [txId, setTxId] = useState("");
    const [TxnImage, setTxnImage] = useState("");
    const [errormsg, seterrormsg] = useState("");
    const handleCloseTransation = () => setShowTr(false);
    const handleShowTransation = () => setShowTr(true);

   // Create contract instance with the correct order of arguments
    const creditPoolContractinstance = new ethers.Contract(Credits_Pool_Address, credits_pool_ABI, provider);
    const daiContractinstance = new ethers.Contract(DAI_TOKEN_Address, DAI_TOKEN_ABI, provider);
    const creditscontractinstance = new ethers.Contract(CREDITS_Token_Address, CREDITS_Token_ABI, provider);
    const dimecontractinstance = new ethers.Contract(DIME_Token_Address, DIME_Token_ABI, provider);
    
    const getAllBalanceFunction = async() =>{
        if(isConnected){
            let daiBalance = await getTokenBalance(address,DAI_TOKEN_Address,DAI_TOKEN_ABI);
            console.log("daiBalance",daiBalance)
         
            setDaiBalance(daiBalance);
           
            let dimeBalance = await getTokenBalance(address,DIME_Token_Address,DIME_Token_ABI);
            console.log("dimeBalance",dimeBalance)
         
            setDimeBalance(dimeBalance);

            let priceOracleDai = await getDAIPrice();
            setDaiPrice(priceOracleDai)
            let priceOracleDime = await getDimePrice();
            setDimePrice(priceOracleDime)

            // let priceOraclecredit = await getCreditsPrice();
            // setCreditsPrice(priceOraclecredit)
            // let jokerallowance = await CheckAllowance(JOKER_Token_Address,JOKER_Token_ABI,address,address);
            // setJokerAllowance(jokerallowance);

        }
        
    }

    useEffect(() =>{getAllBalanceFunction()},[address,isConnected]);
    function formatValues (values,digitsToPrint) {
        return parseFloat(values).toLocaleString(undefined, { minimumFractionDigits: digitsToPrint, useGrouping: false });
    }
      const creditsCalculation = async() =>{
        await recordUserVisits(address, "Credits Mint");
        if(isConnected){
          
            let credits_price =  ethers.utils.formatUnits(await creditPoolContractinstance.getFRAXPrice(),0);
            console.log("credits_price")
            setCreditsPrice(credits_price);
            let mint_price_threshold =  ethers.utils.formatUnits(await creditPoolContractinstance.mint_price_threshold(),0);
            console.log("collateral_price", credits_price,mint_price_threshold)
            if((credits_price) >= mint_price_threshold){
                setMintEnabled(true)
            }
            else{
                setMintEnabled(false)
            }

            let daiallowance =  ethers.utils.formatUnits(await daiContractinstance.allowance(address,Credits_Pool_Address),0);
            console.log("allowance", daiallowance)
            setDaiAllowance(daiallowance);

            let creditsallowance = ethers.utils.formatUnits(await creditscontractinstance.allowance(address,Credits_Pool_Address),0);
            setCreditsAllowance(creditsallowance)

            let dimesallowance = ethers.utils.formatUnits(await dimecontractinstance.allowance(address,Credits_Pool_Address),0);
            console.log("dimeallowance", dimesallowance)
            setDimeAllowance(dimesallowance)

           const PRICE_PRECISION = 1e6;
           let col_ratio =  await ethers.utils.formatUnits((await creditscontractinstance.global_collateral_ratio()),0);
           let Overallcol_ratio=parseFloat(col_ratio/PRICE_PRECISION)*100;
           setUsdcColratio(Overallcol_ratio);
           let mint_fee = 3000;
           setMintfee(mint_fee/PRICE_PRECISION);
           let pool_ceiling =  await ethers.utils.formatUnits((await creditPoolContractinstance.pool_ceilings(0)),0);
           setpoolceiling(pool_ceiling/PRICE_PRECISION);
           let mintpricethreshold =  await ethers.utils.formatUnits((await creditPoolContractinstance.mint_price_threshold()),0);
           setmintpricethreshold(mintpricethreshold/PRICE_PRECISION);
        }
      }
      useEffect(()=>{creditsCalculation()},[address,isConnected])
      const calculateCREDITSmint = async(e)=>{
        if(isConnected){
        let collateral_price = await creditPoolContractinstance.collateral_prices(0);
        let col_ratio = await creditscontractinstance.global_collateral_ratio();
        setCollateralRatio(col_ratio);
        
        let dime_price = await creditPoolContractinstance.getFXSPrice();
       
        console.log("collateral_price", await ethers.utils.formatUnits(collateral_price, 0))
        let collat_needed = e * 1e18;
        let PRICE_PRECISION = 1e6;

        // let  frax_amount = (collat_needed * (await ethers.utils.formatUnits(collateral_price, 0))) / ((await ethers.utils.formatUnits(col_ratio, 0)* PRICE_PRECISION) ); //amount need to pass
        // console.log("frax_amount",frax_amount)

        let  credits_amount = (((collat_needed * PRICE_PRECISION)/(await ethers.utils.formatUnits(collateral_price, 0))) * PRICE_PRECISION)/ (await ethers.utils.formatUnits(col_ratio, 0) ); //amount need to pass
        console.log("credits_amount",credits_amount)
        setdaiAmount(collat_needed);
        console.log("collat_needed1",e)
        console.log("collat_needed",collat_needed)
        setdimeAmount(credits_amount)
        const credits_for_collat = (credits_amount * (await ethers.utils.formatUnits(col_ratio, 0))) / PRICE_PRECISION;
        console.log("frax_for_collat",credits_for_collat)
        const credits_for_dime = credits_amount - credits_for_collat;
        const dime_needed = (credits_for_dime * PRICE_PRECISION) / (await ethers.utils.formatUnits(dime_price, 0)); // Implement getFXSPrice function
        //const black_needed = (frax_for_fxs - splited_value) * PRICE_PRECISION / ((await ethers.utils.formatUnits(black_price, 0)) ); // Implement getBLACKPrice function
        const total_credits_mint = (credits_amount *  (PRICE_PRECISION - 3000)) / PRICE_PRECISION; //minting_fee[col_idx] = 3000;
        console.log("fxs_needed",dime_needed,total_credits_mint)
        setdimeValue(dime_needed);
        console.log("dimevalue",)
        //setblackValue(black_needed);
        setcreditsValue(total_credits_mint)
       //fxs are dime
      //frax are credits
        }
      }

    const mintCredits = async() =>{
        if(isConnected){
        try{
            const ethersProvider =  new ethers.providers.Web3Provider(walletProvider)
            const signer =  ethersProvider.getSigner()
            // Create contract instance with the correct order of arguments
            const creditPoolContractinstance = new ethers.Contract(Credits_Pool_Address, credits_pool_ABI, signer);
            await showPopUp("yes",TransactionPending,"","");
            // Send the transaction and wait for it to be mined
            const mintTx = await creditPoolContractinstance.mintFrax(0,BigInt(creditsValue),0,BigInt(daiAmount+1e18),BigInt(dimeAmount+1e18),false);
            console.log("minttx",mintTx.hash);
            await mintTx.wait();
            await TxnId_PopUp(mintTx.hash);
            await balanceOfTokens();
            await createTxn("Credits",mintTx.hash,"Mint Credits",address,Credits_Pool_Address);
            await resetState();
        
            
        }catch(err){
            showPopUp("not",TransactionPending,"",(err.reason).toString());
            console.log("error",err);
        }
        }
    }
    

    const approve = async() =>{
        if(isConnected){
          
        try{
           
            const ethersProvider =  new ethers.providers.Web3Provider(walletProvider)
            const signer =  ethersProvider.getSigner() 
            
            // Create contract instance with the correct order of arguments
            const daiContractinstance = new ethers.Contract(DAI_TOKEN_Address, DAI_TOKEN_ABI, signer);
            await showPopUp("yes",TransactionPending,"","");
            
            const approveTx = await daiContractinstance.approve(Credits_Pool_Address,BigInt(100000*1e18));
          
            await approveTx.wait();
           
            const dimecontractinstance = new ethers.Contract(DIME_Token_Address, DIME_Token_ABI, signer);
    
            const approveTx2 = await dimecontractinstance.approve(Credits_Pool_Address,BigInt(10000000000*1e18));
            await approveTx2.wait();
            await TxnId_PopUp(approveTx2.hash);
        }catch(err){
            showPopUp("not",TransactionPending,"",(err.reason).toString());
            console.log("error",err);
        }
        }
    }
    const TxnId_PopUp =async(hash) =>{
        let txnid = TXN_ID+hash;
        showPopUp("no",TransactionPending,txnid,"");
        await creditsCalculation();
       
    }

    const showPopUp = async(pendingvalue,imgSrc,txid,errormsg)=>{
        // console.log("showPopUp",pendingvalue,imgSrc,txid,errormsg)
        setPending(pendingvalue);
        setTxId(txid);
        setTxnImage(imgSrc);
        seterrormsg(errormsg);    
        handleShowTransation();        
    }

    const resetState = async() =>{
        setdaiAmount("");
        setdimeAmount("");
    }
    
    const connectWallet = async (e) => {
        e.preventDefault();
        await ConnectWallet();
    }

    return (  
        <LayoutAfterLogin menuActive={"tau"} balances={balances}>
            <div className="py-3 px-3 price-list border-bottom border-3">
                <Row className="g-3 justify-content-around align-items-center">
                    <Col lg="2" md={3} xs={6}>
                        <h4 className="mb-1">CREDITS  PRICE </h4>
                        <h4 className="text-gray mb-0">${creditsPrice?(creditsPrice/1e8):'0.0'}</h4>
                    </Col>
                    <Col lg="2" md={3} xs={6}>
                        <h4 className="mb-1">Collateral Ratio </h4>
                        <h4 className="text-gray mb-0"> {(parseFloat(usdccolratio)) ? (parseFloat(usdccolratio)): '0.00'}%</h4>
                    </Col>
                    <Col lg="2" md={3} xs={6}>
                        <h4 className="mb-1">Pool Balance / Ceiling </h4>
                        <h4 className="text-gray mb-0">${(parseFloat(poolceiling)) ? (parseFloat((poolceiling)*daiPrice)/1e26): '0.00'}</h4>
                    </Col>
                    <Col lg="2" md={3} xs={6}>
                        <h4 className="mb-1">Mint Threshold </h4>
                        <h4 className="text-gray mb-0">{(parseFloat(mintpricethreshold)) ? (parseFloat(mintpricethreshold)): '0.00'}</h4>
                    </Col>
                </Row>
            </div>
            <div className="p-24 app-contain">
                <div className="box p-3 px-md-4">
                    <Row className="align-items-center">
                        <Col md={4}>
                            <div className="redeem-card">
                                <div className="mb-20 text-center">
                                    <h4 className="text-blue mb-1">Mint CREDITS</h4>
                                    <p className="mb-1"><small>Choose normal if you already have DAI and DIME.</small></p>
                                    <h5><span className="dot" style={{backgroundColor: '#00C2FF'}}></span> Normal</h5>
                                </div>

                                <div className="text-center mb-20">
                                    <h4 className="text-blue mb-1">Collateral Pool</h4>
                            
                                    <Dropdown>
                                        <Dropdown.Toggle  variant="dark" id="dropdown-basic" className="no-caret">
                                            <img src={DollerIcon} alt="DollerIcon" /> DAI
                                        </Dropdown.Toggle>

                                        {/* <Dropdown.Menu>
                                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                        </Dropdown.Menu> */}
                                    </Dropdown>
                                </div>

                                <div className="text-center mb-20">
                                    <h4 className="text-blue mb-1">Amount</h4>

                                    <input 
                                    type="numbers" 
                                    
                                    // value={parseFloat(daiAmount/1e18)}
                                    placeholder="0.00" 
                                    className="form-control border-0 mb-1" 
                                    onChange={(e) => calculateCREDITSmint(e.target.value)}/>
                                    <p><small>  {parseFloat(daiBalance) === 'NaN' ? '0.00' :  formatValues((daiBalance/1e18),2)}</small></p>
                                </div>

                                <div className="text-center">
                                    <h4 className="text-blue flex justify-content-center g-2 mb-1"><img width='18' src={DimeIcon} alt="DimeIcon" /> DIME</h4>

                                    <input  readonly  type="number" placeholder='0.00' value={parseFloat(dimeValue) ? (parseFloat(dimeValue)/1e18).toFixed(8) : '0.00'}  className="form-control border-0 mb-1" />
                                    <p className="mb-2"><small>{parseFloat(dimeBalance) === 'NaN' ? '0.00' :  formatValues((dimeBalance/1e18),2)}</small></p>
                                    {/* <p><small>Credits_Pool : <button className="btn-link">{`${Credits_Pool_Address.substring(0, 8)}...${Credits_Pool_Address.substring(Credits_Pool_Address.length - 4)}`}</button></small></p> */}
                                    <p><small>Credits_Pool : <a href={`${config.explorerUrl}/address/${Credits_Pool_Address}`} target="_blank" rel="noopener noreferrer" className="btn-link">{`${Credits_Pool_Address.substring(0, 8)}...${Credits_Pool_Address.substring(Credits_Pool_Address.length - 4)}`}</a></small></p>
                                
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="redeem-card text-center">
                                <h4 className="text-blue mb-1">Collateral Pool</h4>
                                <h5 className="flex g-1 justify-content-center mb-1"><img width={25} src={DollerIcon} alt="DollerIcon" /> DAI: <span className="text-gray">{daiAmount/1e18}</span></h5>
                                <h5 className="flex g-1 justify-content-center mb-0"><img width={25} src={DimeIcon} alt="DimeIcon" /> DIME: <span className="text-gray">{parseFloat(dimeValue) ? (parseFloat(dimeValue)/1e18).toFixed(8) : '0.00'}</span></h5>
                                <div className="py-1">
                                    <img src={ArrowRight} alt="ArrowRight" />
                                </div>
                                <p className="mb-1"><small>MINTING FEE</small></p>
                                <p className="text-warning mb-1"><small>{(parseFloat(mintfee)) ? (parseFloat(mintfee)): '0.00'}</small></p>
                                <p><small>Credits_Pool : <a href={`${config.explorerUrl}/address/${Credits_Pool_Address}`} target="_blank" rel="noopener noreferrer" className="btn-link">{`${Credits_Pool_Address.substring(0, 8)}...${Credits_Pool_Address.substring(Credits_Pool_Address.length - 4)}`}</a></small></p>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="redeem-card text-center">
                                <h4 className="text-blue mb-1">You will Receive</h4>
                                <div className="flex justify-content-center g-2">
                                            <img width={25} src={CreditsIcon} alt="DollerIcon" />
                                        
                                            <div>   
                                <input readonly  type="number" placeholder="_ _ _" value={parseFloat(creditsValue) ? (parseFloat(creditsValue)/1e18).toFixed(8) : '0.00'} className="form-control border-0" />
                                </div>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    
                    <div className="text-center py-3">
                    {isConnected?(<>
                    
                        {parseFloat(dimeAllowance) >=parseFloat(dimeValue/1e9) && parseFloat(daiallowance) >= parseFloat(daiAmount)  ? (<>

    {parseFloat(mintpricethreshold)>=parseFloat(creditsPrice)?(<>

        {mintenabled ? 
                            (<>
    <button className="btn btn-grad btn-min px-5"  onClick={mintCredits}>Mint</button>
    </>):(<>
        <button disabled={true} className="btn btn-grad btn-min px-5">CREDITS Minting is Currently Not Available</button>
                            </>)}
                            
                            </>):(<>
                                <button className="btn btn-grad btn-min px-5"onClick={approve}>Approve</button>
                            </>)

    }

    </>):(<>
        <button disabled className="btn btn-grad btn-min px-5">CREDITS Minting is Currently Not Available</button>

    </>)}


                    </>):(<>
                        <button className="btn btn-grad btn-min px-5" onClick={connectWallet}>Connect Wallet</button>
                    
                    </>)}
                    
                    

                    </div>
                </div>
            </div>
                        <PendingModal pending={pending} showTr={showTr}  handleCloseTransation={handleCloseTransation} imgSrc={TxnImage} txId={txId} errormsg={errormsg}/>

        </LayoutAfterLogin>
    );
}

export default MintFRAX;