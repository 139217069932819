import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
        display: false,
      position: 'top',
    },
    title: {
      display: false
    },
  },
  aspectRatio:3,
  scales: {
      x: {
          ticks: {
              color: "#979797",
              // fontSize: 18,
              stepSize: 1,
              beginAtZero: true
          },
          display: true,
          grid: {
              color: '#333333'
          }
      },
      y: {
          ticks: {
              color: "#979797",
              // fontSize: 18,
              stepSize: 1,
              beginAtZero: true
          },
          display: true,
          grid: {
              color: '#333333'
          }
      }
  }
};

// const labels = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

// const createBarGradient = (startColor, endColor) => {
//     const gradient = document.createElement('canvas').getContext('2d');
//     gradient.canvas.height = 1;
//     gradient.canvas.width = 100;

//     const barGradient = gradient.createLinearGradient(0, 0, 100, 0);
//     barGradient.addColorStop(0, startColor);
//     barGradient.addColorStop(1, endColor);

//     return barGradient;
//   };

// export const data = {
//   labels,
//   datasets: [
//       {
//         type: 'line',
//         label: 'Joker',
//         data: [45,60,58,45,60,30,43,70,35,40,35,65],
//         borderColor: '#B7EDE0',
//         backgroundColor: '#FFFFFF',
//         tooltip: {
//           callbacks: {
//             label: function(context) {
//               // 'DS'+index.dataIndex+1
//               let label = 'DS'+ (context.dataIndex + 1) || '';
//               // let label = context.dataset.label || '';

//               // if (label) {
//               //   label += ': ';
//               // }
//               // if (context.parsed.y !== null) {
//               //   label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
//               // }
//               return label;
//             }
//           }
//         }
//       },
//     {
//       type: 'bar',
//       label: 'Dime',
//       data: [60,30,40,35,65,43,70,35,45,65,58,45],
//       backgroundColor: '#00C2FF',

//     },
//     {
//         type: 'bar',
//       label: 'Credit',
//       data: [43,70,35,45,60,58,45,60,30,40,35,65],
//       backgroundColor: '#20E2D0',
//     }
//   ],
// };

export default function BarLinedata({labels,data1,data2}) {

  const data = {
    labels,
    datasets: [
        // {
        //   type: 'line',
        //   label: 'Joker',
        //   data: [45,60,58,45,60,30,43,70,35,40,35,65],
        //   borderColor: '#B7EDE0',
        //   backgroundColor: '#FFFFFF',
        //   tooltip: {
        //     callbacks: {
        //       label: function(context) {
        //         // 'DS'+index.dataIndex+1
        //         let label = 'DS'+ (context.dataIndex + 1) || '';
        //         // let label = context.dataset.label || '';
  
        //         // if (label) {
        //         //   label += ': ';
        //         // }
        //         // if (context.parsed.y !== null) {
        //         //   label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
        //         // }
        //         return label;
        //       }
        //     }
        //   }
        // },
      {
        type: 'bar',
        label: 'JOKER',
        data: data2,
        backgroundColor: '#00C2FF',
  
      },
      {
          type: 'bar',
        label: 'DIME + CREDITS',
        data: data1,
        backgroundColor: '#20E2D0',
      }
    ],
  };
  return <Bar options={options} data={data} />;
}
