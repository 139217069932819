import { Col, Row, Table } from "react-bootstrap";
import LayoutAfterLogin from "../../component/LayoutAL";
import Icon3 from '../../asserts/IMAGES/creditsBlackLogo.svg';
import TransactionPending from '../../asserts/IMAGES/transsationPending.svg';
import { useState, useEffect } from "react";

import { CheckAllowance, PendingModal, SuccessModal, getGasBalance, getTokenBalance, convertEpochToDateTime } from "../../abi/CommonSolFunctions";
import { CHAIN_URL, JOKER_Token_ABI, JOKER_Token_Address, TXN_ID,  Chainlink_Oracle_ABI, DAI_TOKEN_ABI, DAI_TOKEN_Address, Carbonix_Stabiliser_Address, Carbonix_Stabiliser_ABI, DIME_Chainlink_Oracle_Address, DAI_Chainlink_Oracle_Address, DIME_Token_ABI, DIME_Token_Address, JOKER_Chainlink_Oracle_Address, CREDITS_Token_Address, CREDITS_Token_ABI, API_KEY, Network_Name } from "../../abi/ABI&ContractAddress";
import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers5/react";
import { ethers } from "ethers";
import { createTxn, recordUserVisits } from "../../abi/firebasecode";

function Stabilizer({balances, balanceOfTokens}) {

    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
    const provider = ethers.getDefaultProvider(Network_Name, {
        etherscan: API_KEY});

    const [showTr, setShowTr] = useState(false);
    const [pending, setPending] = useState("");
    const [txId, setTxId] = useState("");
    const [TxnImage, setTxnImage] = useState("");
    const [errormsg, seterrormsg] = useState("");

    const handleCloseTransation = () => setShowTr(false);
    const handleShowTransation = () => setShowTr(true);

    const[depositAmt, setdepositAmt] = useState(0.00);
    const[withdrawtAmt, setwithdrawAmt] = useState(0.00);

    const[daiBalance,setdaiBalance] = useState("");
    const[daiBalanceContract,setdaiBalanceContract] = useState("");
    const[creditsBalance,setCreditsBalance] = useState("");
    const [creditsAllowance,setcreditsAllowance] = useState("");
    const[depositedCredits, setdepositedCredits] = useState("");
    const[stabilizableDai, setstabilizableDai] = useState("");
    const[totalCreditsDeposited,settotalCreditsDeposited] = useState("");
    const[alpacaDeposit,setalpacaDeposit] = useState("");

    const getAssetDetails = async() =>{
        await recordUserVisits(address, "Stabilizer");
        if(isConnected){
          console.log("useeffect");

          const StabiliserContract = new ethers.Contract(Carbonix_Stabiliser_Address, Carbonix_Stabiliser_ABI, provider);

          let creditsBalance = await getTokenBalance(address,CREDITS_Token_Address,CREDITS_Token_ABI);
          setCreditsBalance(creditsBalance);
          let daiBalance = await getTokenBalance(address,DAI_TOKEN_Address,DAI_TOKEN_ABI);
          setdaiBalance(daiBalance);
          let daiBalanceContract = await getTokenBalance(Carbonix_Stabiliser_Address,DAI_TOKEN_Address,DAI_TOKEN_ABI);
          setdaiBalanceContract(daiBalanceContract);
          let creditsallowance = await CheckAllowance(CREDITS_Token_Address,CREDITS_Token_ABI,address,Carbonix_Stabiliser_Address);
          setcreditsAllowance(creditsallowance);

          let depositedCredits = ethers.utils.formatUnits(await StabiliserContract.depositedCfTokens(address),0);
          console.log("deposits:", depositedCredits);
          setdepositedCredits(depositedCredits);
          let stabilizableDai = ethers.utils.formatUnits(await StabiliserContract.tokensInBucket(address),0);
          setstabilizableDai(stabilizableDai);
          let totalCreditsDeposited = ethers.utils.formatUnits(await StabiliserContract.totalSupplyCftokens(),0);
          settotalCreditsDeposited(totalCreditsDeposited);
          let alpacaDeposit = ethers.utils.formatUnits(await StabiliserContract.getVaultTotalDeposited(0),0);
          setalpacaDeposit(alpacaDeposit);

        }
    }

    const approveCredits = async() =>{
        if(isConnected){
        try{
            const ethersProvider =  new ethers.providers.Web3Provider(walletProvider)
            const signer =  ethersProvider.getSigner()
            const creditsContract = new ethers.Contract(CREDITS_Token_Address, CREDITS_Token_ABI, signer);
            
            await showPopUp("yes",TransactionPending,"","");
            let givenAmount = ethers.utils.parseEther(depositAmt.toString());
            const approveTx = await creditsContract.approve(Carbonix_Stabiliser_Address,givenAmount);
            await approveTx.wait();
          
            console.log("minttx",approveTx.hash);
            await getAssetDetails();
            await TxnId_PopUp(approveTx.hash);
        }catch(err){
            showPopUp("not",TransactionPending,"",(err.reason).toString());
            console.log("error",err)
        }
      }
    }

    const deposit = async() => {
        if (isConnected) {
            try {
                const ethersProvider = new ethers.providers.Web3Provider(walletProvider)
                const signer = ethersProvider.getSigner()
                const StabilserContract = new ethers.Contract(Carbonix_Stabiliser_Address, Carbonix_Stabiliser_ABI, signer);
                
                await showPopUp("yes", TransactionPending, "", "");
                let givenAmount = ethers.utils.parseEther(depositAmt.toString()); // Convert value to wei
                console.log("givenAmt", givenAmount);
                const depositTx = await StabilserContract.stake(givenAmount);
                          
                await depositTx.wait();             
                console.log("depositTx", depositTx.hash);
                await getAssetDetails();
                resetState();
                await TxnId_PopUp(depositTx.hash);
                await balanceOfTokens();
                await createTxn("Credits",depositTx.hash,"Deposit in Stablizer",address,Carbonix_Stabiliser_Address);
            } catch (err) {
                showPopUp("not", TransactionPending, "", (err.reason).toString());
                console.log("error", err);
            }
        }
    }

    const withdraw = async() => {
        if (isConnected) {
            try {
                const ethersProvider = new ethers.providers.Web3Provider(walletProvider)
                const signer = ethersProvider.getSigner()
                const StabilserContract = new ethers.Contract(Carbonix_Stabiliser_Address, Carbonix_Stabiliser_ABI, signer);
                
                await showPopUp("yes", TransactionPending, "", "");
                let givenAmount = ethers.utils.parseEther(withdrawtAmt.toString()); // Convert value to wei
                console.log("givenAmt", givenAmount);
                const withdrawTx = await StabilserContract.unstake(givenAmount);
                          
                await withdrawTx.wait();             
                console.log("withdrawTx", withdrawTx.hash);
                await getAssetDetails();
                resetState();
                await TxnId_PopUp(withdrawTx.hash);
                await balanceOfTokens();
                await createTxn("Credits",withdrawTx.hash,"Winthdraw in Stabilizer",address,Carbonix_Stabiliser_Address);
            } catch (err) {
                showPopUp("not", TransactionPending, "", (err.reason).toString());
                console.log("error", err);
            }
        }
    }

    const stabilize = async() => {
        if (isConnected) {
            try {
                const ethersProvider = new ethers.providers.Web3Provider(walletProvider)
                const signer = ethersProvider.getSigner()
                const StabilserContract = new ethers.Contract(Carbonix_Stabiliser_Address, Carbonix_Stabiliser_ABI, signer);
                
                await showPopUp("yes", TransactionPending, "", "");
                const stabilizeTx = await StabilserContract.transmute();
                          
                await stabilizeTx.wait();             
                console.log("stabilizeTx", stabilizeTx.hash);
                await getAssetDetails();
                resetState();
                await TxnId_PopUp(stabilizeTx.hash);
                await balanceOfTokens();
                await createTxn("Credits",stabilizeTx.hash,"Transmute",address,Carbonix_Stabiliser_Address);
            } catch (err) {
                showPopUp("not", TransactionPending, "", (err.reason).toString());
                console.log("error", err);
            }
        }
    }

    const claim = async() => {
        if (isConnected) {
            try {
                const ethersProvider = new ethers.providers.Web3Provider(walletProvider)
                const signer = ethersProvider.getSigner()
                const StabilserContract = new ethers.Contract(Carbonix_Stabiliser_Address, Carbonix_Stabiliser_ABI, signer);
                
                await showPopUp("yes", TransactionPending, "", "");
                const claimTx = await StabilserContract.transmuteAndClaim();
                          
                await claimTx.wait();             
                console.log("claimTx", claimTx.hash);
                await getAssetDetails();
                resetState();
                await TxnId_PopUp(claimTx.hash);
                await balanceOfTokens();
                await createTxn("Credit",claimTx.hash,"Claim in Stabilizer",address,Carbonix_Stabiliser_Address);
            } catch (err) {
                showPopUp("not", TransactionPending, "", (err.reason).toString());
                console.log("error", err);
            }
        }
    }

    const TxnId_PopUp =async(hash) =>{
        let txnid = TXN_ID+hash;
        showPopUp("no",TransactionPending,txnid,"");
    }

    const showPopUp = async(pendingvalue,imgSrc,txid,errormsg)=>{
        setPending(pendingvalue);
        setTxId(txid);
        setTxnImage(imgSrc);
        seterrormsg(errormsg);    
        handleShowTransation();        
    }

    const handlechange = (event) =>{ 
        setdepositAmt(event.target.value);
    }

    const handlechange2 = (event) =>{ 
        setwithdrawAmt(event.target.value);
    }

    const handleInput = (value) => {
        setdepositAmt((( creditsBalance*value)/100)/1e18);
        console.log(value);
    }

    const handleInput2 = (value) => {
        setwithdrawAmt((( depositedCredits*value)/100)/1e18);
        console.log((( depositedCredits*value)/100)/1e18);
    }

    const resetState = async() =>{
        setdepositAmt(0.00);
        setwithdrawAmt(0.00);
    }

    useEffect(()=>{getAssetDetails()},[address, isConnected]);

    return (  
        <LayoutAfterLogin menuActive={"carbonix"} balances={balances}>
            <div className="p-24 app-contain">
                <Row className="justify-content-center">
                    <Col style={{maxWidth: '900px'}}>
                        <div className="mb-20 text-center">
                            <h2 className="mb-2">Stabilize CREDITS to DAI</h2>
                            <h6 className="text-gray mb-0">The Stabilizer exists to ensure CREDITS is pegged to the dollar. Depositing your CREDITS will gradually convert it into DAI. This is only useful if CREDITS is trading under one dollar on Curve.</h6>
                        </div>

                        <div className="box table-border p-3 p-md-4">
                            <div className="mb-20">
                                <Table bordered responsive>
                                    <tbody>
                                        <tr>
                                            <td>Your CREDITS</td>
                                            <td>Deposited CREDITS</td>
                                            <td>Stabilizable DAI</td>
                                            <td>Your DAI</td>
                                        </tr>
                                        <tr>
                                            <td>{creditsBalance ? (creditsBalance / 1e18).toFixed(4) : "0.00"}</td>
                                            <td>{depositedCredits ? (depositedCredits / 1e18).toFixed(4) : "0.00"}</td>
                                            <td>{stabilizableDai ? (stabilizableDai / 1e18).toFixed(4) : "0.00"}</td>
                                            <td>{daiBalance ? (daiBalance / 1e18).toFixed(4) : "0.00"}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>

                            <Row className="g-3 mb-20">
                                <Col md={6}>
                                    <div className="swap-card mb-3 d-flex align-items-center">
                                        <div>
                                            <img src={Icon3} alt="Icon1" height={'42px'} />
                                        </div>
                                        <div className="flex-grow-1 ms-2 ps-3 py-2">
                                            <input value= {depositAmt ? depositAmt : ""} placeholder="0.00" className="input-reset" onChange={(e) => handlechange(e)}/>
                                        </div>
                                        {creditsAllowance >= (depositAmt * 1e18) ?
                                        <button className="btn btn-grad ouline py-2" onClick={deposit}><span>Deposit</span></button>
                                        :
                                        <button className="btn btn-grad ouline py-2" onClick={approveCredits}><span>Approve</span></button>}
                                    </div>

                                    <Row className="mb-20 g-2">
                                        <Col>
                                            <button className="btn btn-toggle w-100" onClick={() => handleInput(25)}><span>25%</span></button>
                                        </Col>
                                        <Col>
                                            <button className="btn btn-toggle w-100" onClick={() => handleInput(50)}><span>50%</span></button>
                                        </Col>
                                        <Col>
                                            <button className="btn btn-toggle w-100" onClick={() => handleInput(75)}><span>75%</span></button>
                                        </Col>
                                        <Col>
                                            <button className="btn btn-toggle w-100" onClick={() => handleInput(100)}><span>100%</span></button>
                                        </Col>
                                    </Row>
                                    {depositedCredits > 0 && depositedCredits != "" ?
                                    <button className="btn btn-grad w-100 py-2" onClick={stabilize}>Stabilize</button>
                                    :
                                    <button className="btn btn-grad w-100 outline py-2" ><span>Stabilize</span></button>   }
                                </Col>
                                <Col md={6}>
                                    <div className="swap-card mb-3 d-flex align-items-center">
                                        <div>
                                            <img src={Icon3} alt="Icon1" height={'42px'}/>
                                        </div>
                                        <div className="flex-grow-1 ms-2 ps-3 py-2">
                                            <input value= {withdrawtAmt ? withdrawtAmt : ""} placeholder="0.00" className="input-reset" onChange={(e) => handlechange2(e)} />
                                        </div>
                                        {depositedCredits > 0 && depositedCredits != "" ?
                                        <button className="btn btn-grad ouline py-2" onClick={withdraw}><span>Withdraw</span></button>
                                        :
                                        <button className="btn btn-grad ouline outline py-2" ><span>Withdraw</span></button>}
                                    </div>

                                    <Row className="mb-20 g-2">
                                        <Col>
                                            <button className="btn btn-toggle w-100" onClick={() => handleInput2(25)}><span>25%</span></button>
                                        </Col>
                                        <Col>
                                            <button className="btn btn-toggle w-100" onClick={() => handleInput2(50)}><span>50%</span></button>
                                        </Col>
                                        <Col>
                                            <button className="btn btn-toggle w-100" onClick={() => handleInput2(75)}><span>75%</span></button>
                                        </Col>
                                        <Col>
                                            <button className="btn btn-toggle w-100" onClick={() => handleInput2(100)}><span>100%</span></button>
                                        </Col>
                                    </Row>
                                    {depositedCredits > 0 && depositedCredits != "" ?
                                    <button className="btn btn-grad w-100 py-2" onClick={claim}>Claim & Withdraw</button>
                                    :
                                    <button className="btn btn-grad w-100 outline py-2" ><span>Claim & Withdraw</span></button>}
                                </Col>
                            </Row>

                            <h4 className="text-muted">Global Stabilizer Status</h4>
                            <div className="swap-card p-3">
                                <div className="flex mb-2 justify-content-between">
                                    <h4 className="mb-0">Total Deposited Credits:</h4>
                                    <h5 className="mb-0 text-end text-nowrap">{totalCreditsDeposited ? (totalCreditsDeposited/1e18).toFixed(4) : "0.00"} CREDITS</h5>
                                </div>
                                <div className="flex mb-1 justify-content-between">
                                    <h5 className="mb-0 text-gray">Total DAI Deposited in alpaca:</h5>
                                    <h5 className="mb-0 text-end text-nowrap">{alpacaDeposit ? (alpacaDeposit/1e18).toFixed(4) : "0.00"} DAI</h5>
                                </div>
                                <div className="flex justify-content-between">
                                    <h5 className="mb-0 text-gray">Total DAI Available for Stabilization</h5>
                                    <h5 className="mb-0 text-end text-nowrap">{daiBalanceContract ? (daiBalanceContract/1e18).toFixed(4) : "0.00"} DAI</h5>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <PendingModal pending={pending} showTr={showTr}  handleCloseTransation={handleCloseTransation} imgSrc={TxnImage} txId={txId} errormsg={errormsg}/>
            </div>
        </LayoutAfterLogin>
    );
}

export default Stabilizer;