import fireDb from "./firebase";

export const updatePrice = async(Price,timing,cicultingsupply) =>{
    let ref2= await fireDb.database().ref(`PriceHistory/JokerPrice`);
        const db = ref2.push().key;   
        // let k = new Date().getTime();                                                                   
        ref2.child(db).set({
          key:db,price:Price,entryTime:timing,circulatingSupply:cicultingsupply
        })
        .then(()=>{
          console.log(" updatePrice done")
        }).catch((error) => {
            console.error("Error:", error);
          });

}

export const updatedimePrice = async(Price,timing,cicultingsupply) =>{
    let ref2= await fireDb.database().ref(`PriceHistory/DimePrice`);
        const db = ref2.push().key;   
        // let k = new Date().getTime();                                                                   
        ref2.child(db).set({
          key:db,price:Price,entryTime:timing,circulatingSupply:cicultingsupply
        })
        .then(()=>{
          console.log("updatedimePrice")
        }).catch((error) => {
            console.error("Error:", error);
          });

}

export const updatecreditPrice = async(Price,timing,cicultingsupply) =>{
    let ref2= await fireDb.database().ref(`PriceHistory/CreditPrice`);
        const db = ref2.push().key;   
        // let k = new Date().getTime();                                                                   
        ref2.child(db).set({
          key:db,price:Price,entryTime:timing,circulatingSupply:cicultingsupply
        })
        .then(()=>{
          console.log("done")
        }).catch((error) => {
            console.error("Error:", error);
          });

}

export const updatetauPrice = async(Price,timing,cicultingsupply) =>{
    let ref2= await fireDb.database().ref(`PriceHistory/TauPrice`);
        const db = ref2.push().key;   
        // let k = new Date().getTime();                                                                   
        ref2.child(db).set({
          key:db,price:Price,entryTime:timing,circulatingSupply:cicultingsupply
        })
        .then(()=>{
          console.log("done")
        }).catch((error) => {
            console.error("Error:", error);
          });

}

export const updatetreasuryPrice = async(Price,timing,jokervalue,dimevalue,creditsvalue,usdcvalue) =>{
    let ref2= await fireDb.database().ref(`PriceHistory/TreasuryPrice`);
        const db = ref2.push().key;   
        // let k = new Date().getTime();                                                                   
        ref2.child(db).set({
          key:db,price:Price,entryTime:timing,jokerValue:jokervalue,dimeValue:dimevalue,creditsValue:creditsvalue,USDCValue:usdcvalue
        })
        .then(()=>{
          console.log("done")
        }).catch((error) => {
            console.error("Error:", error);
          });

}

export const updateComparisonPrice = async(jokerprice,timing,dimeCreditsprice) =>{
    let ref2= await fireDb.database().ref(`PriceHistory/JokerDimeCredits`);
        const db = ref2.push().key;   
        // let k = new Date().getTime();                                                                   
        ref2.child(db).set({
          key:db,jokerValue:jokerprice,entryTime:timing,dimeCreditValue:dimeCreditsprice
        })
        .then(()=>{
          console.log("done")
        }).catch((error) => {
            console.error("Error:", error);
          });

}

export const updateCollateralValue = async(collateral,timing) =>{
    let ref2= await fireDb.database().ref(`PriceHistory/collateralValue`);
        const db = ref2.push().key;   
        // let k = new Date().getTime();                                                                   
        ref2.child(db).set({
          key:db,Collateral:collateral,entryTime:timing
        })
        .then(()=>{
          console.log("done")
        }).catch((error) => {
            console.error("Error:", error);
          });

}

export const getupdatePrice = async (Price, timing, circulatingSupply) => {
    try {
        let ref2 = await fireDb.database().ref(`PriceHistory/JokerPrice`);

        // Retrieve data
        ref2.once("value", (snapshot) => {
            const data = snapshot.val();
            console.log("Retrieved data:", data);
            // Use the retrieved data as needed
        });
    } catch (error) {
        console.error("Error:", error);
    }
}

// export const getPriceOneDay = async (tokenname) => {
//     try {
//         let ref2 = await fireDb.database().ref(`PriceHistory/${tokenname}`);

//         // Retrieve data
//         const snapshot = await ref2.once("value");
//         const data = snapshot.val();

//         // Get the current time in milliseconds
//         const currentTime = Date.now();

//         // Calculate the timestamp for 24 hours ago
//         const twentyFourHoursAgo =( currentTime - (24 * 60 * 60 *1000 ))/1000;

//         console.log("twentyFourHoursAgo",twentyFourHoursAgo)

//         // Extract price and epoch from the retrieved data and combine them for the last 24 hours
//         const priceEpochDataLast24Hours = [];
//         for (const key in data) {
//             if (data.hasOwnProperty(key)) {
//                 const entry = data[key];
//                 // Check if the epoch is within the last 24 hours
//                 if (entry.entryTime >= twentyFourHoursAgo && entry.entryTime <= currentTime) {
//                     priceEpochDataLast24Hours.push({ price: entry.price, epoch: entry.entryTime });
//                 }
//             }
//         }

//         console.log("Price and Epoch Data for the Last 24 Hours:", priceEpochDataLast24Hours);
//         // Use the retrieved data as needed
//     } catch (error) {
//         console.error("Error:", error);
//     }
// }
export const getPriceOneDay = async (tokenname) => {
    try {
        // Set the start and end time for the last day
        const currentDate = new Date();
        const startOfLastDay = new Date(currentDate);
        startOfLastDay.setDate(currentDate.getDate() - 1);
        startOfLastDay.setHours(0, 0, 0, 0); // Set to the beginning of the last day

        const endOfLastDay = new Date(currentDate);
        endOfLastDay.setDate(currentDate.getDate() - 1);
        endOfLastDay.setHours(23, 59, 59, 999); // Set to the end of the last day

        let ref2 = await fireDb.database().ref(`PriceHistory/${tokenname}`);

        // Retrieve data for the last day
        const snapshot = await ref2.orderByChild('entryTime').startAt(startOfLastDay.getTime() / 1000).endAt(endOfLastDay.getTime() / 1000).once('value');
        const data = snapshot.val();

        // Process data by hour within the last day
        const pricesByHour = {};
        for (let hour = 0; hour < 24; hour++) {
            const hourStart = new Date(startOfLastDay);
            hourStart.setHours(hour, 0, 0, 0);

            const hourEnd = new Date(startOfLastDay);
            hourEnd.setHours(hour, 59, 59, 999);

            const pricesInHour = Object.values(data).filter(entry => {
                const entryTime = new Date(entry.entryTime * 1000);
                return entryTime >= hourStart && entryTime <= hourEnd;
            });

            if (pricesInHour.length > 0) {
                const averagePriceInHour = pricesInHour.reduce((total, entry) => total + parseFloat(entry.price), 0) / pricesInHour.length;
                const formattedHour = formatHour(hour);
                pricesByHour[formattedHour] = averagePriceInHour;
            }
        }

        console.log("Average Prices By Hour:", pricesByHour);
        return pricesByHour;
        // Use the retrieved data as needed
    } catch (error) {
        console.error("Error:", error);
    }
}

// Function to format hour in 12-hour format with AM/PM
const formatHour = (hour) => {
    if (hour === 0) {
        return '12AM';
    } else if (hour < 12) {
        return hour + 'AM';
    } else if (hour === 12) {
        return '12PM';
    } else {
        return (hour - 12) + 'PM';
    }
};


export const getPriceOneWeek = async (tokenname) => {
    try {
        let ref2 = await fireDb.database().ref(`PriceHistory/${tokenname}`);


        // Retrieve data
        const snapshot = await ref2.once("value");
        const data = snapshot.val();

        // Filter data for the last week
        const currentDate = new Date();
        const oneWeekAgo = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000); // Subtract 7 days in milliseconds
        const filteredData = Object.entries(data).filter(([key, entry]) => {
            const entryDate = new Date(entry.entryTime * 1000); // Convert epoch to milliseconds
            return entryDate >= oneWeekAgo && entryDate <= currentDate;
        });

        // Process data by day within the last week
        const pricesByDay = {};
        filteredData.forEach(([key, entry]) => {
            const date = new Date(entry.entryTime * 1000).getDate(); // Get day of the month (1-31)
            if (!pricesByDay[date]) {
                pricesByDay[date] = [];
            }
            pricesByDay[date].push(entry.price);
        });

        // Calculate average price for each day
        const averagePricesByDay = {};
        for (const date in pricesByDay) {
            if (pricesByDay.hasOwnProperty(date)) {
                const prices = pricesByDay[date];
                const averagePrice = prices.reduce((total, price) => total + parseFloat(price), 0) / prices.length;
                const entryDate = new Date(filteredData[0][1].entryTime * 1000);
                const month = entryDate.toLocaleString('default', { month: 'short' });
                const formattedMonthDay = `${month} ${date}`;
                averagePricesByDay[formattedMonthDay] = averagePrice;
            }
        }

        console.log("Average Prices By Day:", averagePricesByDay);
        return averagePricesByDay;
        // Use the retrieved data as needed
    } catch (error) {
        console.error("Error:", error);
    }
}


export const getPriceOneMonth = async (tokenname) => {
    try {
        let ref2 = await fireDb.database().ref(`PriceHistory/${tokenname}`);

        // Retrieve data
        const snapshot = await ref2.once("value");
        const data = snapshot.val();

        // Filter data for the last month
        const currentDate = new Date();
        const oneMonthAgo = new Date(currentDate);
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

        const filteredData = Object.entries(data).filter(([key, entry]) => {
            const entryDate = new Date(entry.entryTime * 1000); // Convert epoch to milliseconds
            return entryDate > oneMonthAgo;
        });

        // Process data by day within the last month
        const pricesByDay = {};
        filteredData.forEach(([key, entry]) => {
            const date = new Date(entry.entryTime * 1000).getDate(); // Get day of the month (1-31)
            if (!pricesByDay[date]) {
                pricesByDay[date] = [];
            }
            pricesByDay[date].push(entry.price);
        });

        // Calculate average price for each day
        const averagePricesByDay = {};
        for (const date in pricesByDay) {
            if (pricesByDay.hasOwnProperty(date)) {
                const prices = pricesByDay[date];
                const averagePrice = prices.reduce((total, price) => total + parseFloat(price), 0) / prices.length;
                const entryDate = new Date(filteredData[0][1].entryTime * 1000);
                const month = entryDate.toLocaleString('default', { month: 'short' });
                const formattedMonthDay = `${month} ${date}`;
                averagePricesByDay[formattedMonthDay] = averagePrice;
            }
        }

        console.log("Average Prices By Day:", averagePricesByDay);
        return averagePricesByDay;
        // Use the retrieved data as needed
    } catch (error) {
        console.error("Error:", error);
    }
}

export const getPriceOneYear =async (tokenname) => {
    try {
        let ref2 = await fireDb.database().ref(`PriceHistory/${tokenname}`);

        // Retrieve data
        const snapshot = await ref2.once("value");
        const data = snapshot.val();

        // Extract price and epoch from the retrieved data and combine them
        const priceEpochData = [];
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const entry = data[key];
                priceEpochData.push({ price: entry.price, epoch: entry.entryTime });
            }
        }

        // Process data by month
        const pricesByMonth = {};
        priceEpochData.forEach(({ price, epoch }) => {
            const monthYear = new Date(epoch * 1000).toISOString().slice(0, 7); // Get month and year
            if (!pricesByMonth[monthYear]) {
                pricesByMonth[monthYear] = [];
            }
            pricesByMonth[monthYear].push(price);
        });

        // Calculate average price for each month
        const averagePricesByMonth = {};
        for (const monthYear in pricesByMonth) {
            if (pricesByMonth.hasOwnProperty(monthYear)) {
                const prices = pricesByMonth[monthYear];
                const averagePrice = prices.reduce((total, price) => total + price, 0) / prices.length;

                const [year, month] = monthYear.split('-');
                const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                const monthName = monthNames[parseInt(month, 10) - 1]; // Subtract 1 since month index starts from 0
                const formattedMonthYear = `${monthName} ${year}`;
                
                averagePricesByMonth[formattedMonthYear] = averagePrice;
            }
        }

        console.log("Average Prices By Month:", averagePricesByMonth);
        return averagePricesByMonth;
        // Use the retrieved data as needed
    } catch (error) {
        console.error("Error:", error);
    }
}

export const getCirculatingSupplyOneDay = async (tokenname) => {
    try {
        // Set the start and end time for the last day
        const currentDate = new Date();
        const startOfLastDay = new Date(currentDate);
        startOfLastDay.setDate(currentDate.getDate() - 1);
        startOfLastDay.setHours(0, 0, 0, 0); // Set to the beginning of the last day

        const endOfLastDay = new Date(currentDate);
        endOfLastDay.setDate(currentDate.getDate() - 1);
        endOfLastDay.setHours(23, 59, 59, 999); // Set to the end of the last day

        let ref2 = await fireDb.database().ref(`PriceHistory/${tokenname}`);

        // Retrieve data for the last day
        const snapshot = await ref2.orderByChild('entryTime').startAt(startOfLastDay.getTime() / 1000).endAt(endOfLastDay.getTime() / 1000).once('value');
        const data = snapshot.val();

        // Process data by hour within the last day
        const pricesByHour = {};
        for (let hour = 0; hour < 24; hour++) {
            const hourStart = new Date(startOfLastDay);
            hourStart.setHours(hour, 0, 0, 0);

            const hourEnd = new Date(startOfLastDay);
            hourEnd.setHours(hour, 59, 59, 999);

            const pricesInHour = Object.values(data).filter(entry => {
                const entryTime = new Date(entry.entryTime * 1000);
                return entryTime >= hourStart && entryTime <= hourEnd;
            });

            if (pricesInHour.length > 0) {
                const averagePriceInHour = pricesInHour.reduce((total, entry) => total + parseFloat(entry.circulatingSupply), 0) / pricesInHour.length;
                const formattedHour = formatHour(hour);
                pricesByHour[formattedHour] = averagePriceInHour;
            }
        }

        console.log("Average Prices By Hour:", pricesByHour);
        return pricesByHour;
        // Use the retrieved data as needed
    } catch (error) {
        console.error("Error:", error);
    }
}


export const getCirculatingsupplyOneWeek = async (tokenname) => {
    try {
        let ref2 = await fireDb.database().ref(`PriceHistory/${tokenname}`);


        // Retrieve data
        const snapshot = await ref2.once("value");
        const data = snapshot.val();

        // Filter data for the last week
        const currentDate = new Date();
        const oneWeekAgo = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000); // Subtract 7 days in milliseconds
        const filteredData = Object.entries(data).filter(([key, entry]) => {
            const entryDate = new Date(entry.entryTime * 1000); // Convert epoch to milliseconds
            return entryDate >= oneWeekAgo && entryDate <= currentDate;
        });

        // Process data by day within the last week
        const pricesByDay = {};
        filteredData.forEach(([key, entry]) => {
            const date = new Date(entry.entryTime * 1000).getDate(); // Get day of the month (1-31)
            if (!pricesByDay[date]) {
                pricesByDay[date] = [];
            }
            pricesByDay[date].push(entry.circulatingSupply);
        });
      
        // Calculate average price for each day
        const averagePricesByDay = {};
        for (const date in pricesByDay) {
            if (pricesByDay.hasOwnProperty(date)) {
                const prices = pricesByDay[date];
                const averagePrice = prices.reduce((total, price) => total + parseFloat(price), 0) / prices.length;
                const entryDate = new Date(filteredData[0][1].entryTime * 1000);
                const month = entryDate.toLocaleString('default', { month: 'short' });
                const formattedMonthDay = `${month} ${date}`;
                averagePricesByDay[formattedMonthDay] = averagePrice;
            }
        }

        console.log("Average Prices By Day:", averagePricesByDay);
        return averagePricesByDay;
        // Use the retrieved data as needed
    } catch (error) {
        console.error("Error:", error);
    }
}


export const getCirculatingsupplyOneMonth = async (tokenname) => {
    try {
        let ref2 = await fireDb.database().ref(`PriceHistory/${tokenname}`);


        // Retrieve data
        const snapshot = await ref2.once("value");
        const data = snapshot.val();

        // Filter data for the last month
        const currentDate = new Date();
        const oneMonthAgo = new Date(currentDate);
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

        const filteredData = Object.entries(data).filter(([key, entry]) => {
            const entryDate = new Date(entry.entryTime * 1000); // Convert epoch to milliseconds
            return entryDate > oneMonthAgo;
        });

        // Process data by day within the last month
        const pricesByDay = {};
        filteredData.forEach(([key, entry]) => {
            const date = new Date(entry.entryTime * 1000).getDate(); // Get day of the month (1-31)
            if (!pricesByDay[date]) {
                pricesByDay[date] = [];
            }
            pricesByDay[date].push(entry.circulatingSupply);
        });

        // Calculate average price for each day
        const averagePricesByDay = {};
        for (const date in pricesByDay) {
            if (pricesByDay.hasOwnProperty(date)) {
                const prices = pricesByDay[date];
                const averagePrice = prices.reduce((total, price) => total + parseFloat(price), 0) / prices.length;
                const entryDate = new Date(filteredData[0][1].entryTime * 1000);
                const month = entryDate.toLocaleString('default', { month: 'short' });
                const formattedMonthDay = `${month} ${date}`;
                averagePricesByDay[formattedMonthDay] = averagePrice;
            }
        }

        console.log("Average Prices By Day:", averagePricesByDay);
        return averagePricesByDay;
        // Use the retrieved data as needed
    } catch (error) {
        console.error("Error:", error);
    }
}

export const getCirculatingsupplyOneYear = async (tokenname) => {
    try {
        let ref2 = await fireDb.database().ref(`PriceHistory/${tokenname}`);


        // Retrieve data
        const snapshot = await ref2.once("value");
        const data = snapshot.val();

        // Extract price and epoch from the retrieved data and combine them
        const priceEpochData = [];
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const entry = data[key];
                priceEpochData.push({ price: parseFloat(entry.circulatingSupply), epoch: entry.entryTime });
            }
        }
        // console.log("priceEpochData Prices By Day:", priceEpochData);
        // Process data by month
        const pricesByMonth = {};
        priceEpochData.forEach(({ price, epoch }) => {
            const monthYear = new Date(epoch * 1000).toISOString().slice(0, 7); // Get month and year
            if (!pricesByMonth[monthYear]) {
                pricesByMonth[monthYear] = [];
            }
            pricesByMonth[monthYear].push(price);
        });

    

        // Calculate average price for each month
        const averagePricesByMonth = {};
        for (const monthYear in pricesByMonth) {
            if (pricesByMonth.hasOwnProperty(monthYear)) {
                const prices = pricesByMonth[monthYear];
                const averagePrice = prices.reduce((total, price) => total + price, 0) / prices.length;

                const [year, month] = monthYear.split('-');
                const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                const monthName = monthNames[parseInt(month, 10) - 1]; // Subtract 1 since month index starts from 0
                const formattedMonthYear = `${monthName} ${year}`;
                
                averagePricesByMonth[formattedMonthYear] = averagePrice;
            }
        }

        console.log("Average Prices By Month:", averagePricesByMonth);
        return averagePricesByMonth;
        // Use the retrieved data as needed
    } catch (error) {
        console.error("Error:", error);
    }
}

export const getMarketcapOneDay = async (tokenname) => {
    try {
        // Set the start and end time for the last day
        const currentDate = new Date();
        const startOfLastDay = new Date(currentDate);
        startOfLastDay.setDate(currentDate.getDate() - 1);
        startOfLastDay.setHours(0, 0, 0, 0); // Set to the beginning of the last day

        const endOfLastDay = new Date(currentDate);
        endOfLastDay.setDate(currentDate.getDate() - 1);
        endOfLastDay.setHours(23, 59, 59, 999); // Set to the end of the last day

        let ref2 = await fireDb.database().ref(`PriceHistory/${tokenname}`);

        // Retrieve data for the last day
        const snapshot = await ref2.orderByChild('entryTime').startAt(startOfLastDay.getTime() / 1000).endAt(endOfLastDay.getTime() / 1000).once('value');
        const data = snapshot.val();

        // Process data by hour within the last day
        const pricesByHour = {};
        for (let hour = 0; hour < 24; hour++) {
            const hourStart = new Date(startOfLastDay);
            hourStart.setHours(hour, 0, 0, 0);

            const hourEnd = new Date(startOfLastDay);
            hourEnd.setHours(hour, 59, 59, 999);

            const pricesInHour = Object.values(data).filter(entry => {
                const entryTime = new Date(entry.entryTime * 1000);
                return entryTime >= hourStart && entryTime <= hourEnd;
            });

            if (pricesInHour.length > 0) {
                const averagePriceInHour = pricesInHour.reduce((total, entry) => total + parseFloat(parseFloat(entry.circulatingSupply)*parseFloat(entry.price)), 0) / pricesInHour.length;
                const formattedHour = formatHour(hour);
                pricesByHour[formattedHour] = averagePriceInHour;
            }
        }

        console.log("Average Prices By Hour:", pricesByHour);
        return pricesByHour;
        // Use the retrieved data as needed
    } catch (error) {
        console.error("Error:", error);
    }
}



export const getMarketcapOneWeek = async (tokenname) => {
    try {
        let ref2 = await fireDb.database().ref(`PriceHistory/${tokenname}`);


        // Retrieve data
        const snapshot = await ref2.once("value");
        const data = snapshot.val();

        // Filter data for the last week
        const currentDate = new Date();
        const oneWeekAgo = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000); // Subtract 7 days in milliseconds
        const filteredData = Object.entries(data).filter(([key, entry]) => {
            const entryDate = new Date(entry.entryTime * 1000); // Convert epoch to milliseconds
            return entryDate >= oneWeekAgo && entryDate <= currentDate;
        });

        // Process data by day within the last week
        const pricesByDay = {};
        filteredData.forEach(([key, entry]) => {
            const date = new Date(entry.entryTime * 1000).getDate(); // Get day of the month (1-31)
            if (!pricesByDay[date]) {
                pricesByDay[date] = [];
            }
            pricesByDay[date].push(((parseFloat(entry.circulatingSupply)*parseFloat(entry.price))));
        });

        // Calculate average price for each day
        const averagePricesByDay = {};
        for (const date in pricesByDay) {
            if (pricesByDay.hasOwnProperty(date)) {
                const prices = pricesByDay[date];
                const averagePrice = prices.reduce((total, price) => total + parseFloat(price), 0) / prices.length;
                const entryDate = new Date(filteredData[0][1].entryTime * 1000);
                const month = entryDate.toLocaleString('default', { month: 'short' });
                const formattedMonthDay = `${month} ${date}`;
                averagePricesByDay[formattedMonthDay] = averagePrice;
            }
        }

        console.log("Average Prices By Day:", averagePricesByDay);
        return averagePricesByDay;
        // Use the retrieved data as needed
    } catch (error) {
        console.error("Error:", error);
    }
}


export const getMarketcapOneMonth = async (tokenname) => {
    try {
        let ref2 = await fireDb.database().ref(`PriceHistory/${tokenname}`);


        // Retrieve data
        const snapshot = await ref2.once("value");
        const data = snapshot.val();

        // Filter data for the last month
        const currentDate = new Date();
        const oneMonthAgo = new Date(currentDate);
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

        const filteredData = Object.entries(data).filter(([key, entry]) => {
            const entryDate = new Date(entry.entryTime * 1000); // Convert epoch to milliseconds
            return entryDate > oneMonthAgo;
        });

        // Process data by day within the last month
        const pricesByDay = {};
        filteredData.forEach(([key, entry]) => {
            const date = new Date(entry.entryTime * 1000).getDate(); // Get day of the month (1-31)
            if (!pricesByDay[date]) {
                pricesByDay[date] = [];
            }
            pricesByDay[date].push(((parseFloat(entry.circulatingSupply)*parseFloat(entry.price))));
        });

        // Calculate average price for each day
        const averagePricesByDay = {};
        for (const date in pricesByDay) {
            if (pricesByDay.hasOwnProperty(date)) {
                const prices = pricesByDay[date];
                const averagePrice = prices.reduce((total, price) => total + parseFloat(price), 0) / prices.length;
                const entryDate = new Date(filteredData[0][1].entryTime * 1000);
                const month = entryDate.toLocaleString('default', { month: 'short' });
                const formattedMonthDay = `${month} ${date}`;
                averagePricesByDay[formattedMonthDay] = averagePrice;
            }
        }

        console.log("Average Prices By Day:", averagePricesByDay);
        return averagePricesByDay;
        // Use the retrieved data as needed
    } catch (error) {
        console.error("Error:", error);
    }
}

export const getMarketcapOneYear = async (tokenname) => {
    try {
        let ref2 = await fireDb.database().ref(`PriceHistory/${tokenname}`);


        // Retrieve data
        const snapshot = await ref2.once("value");
        const data = snapshot.val();

        // Extract price and epoch from the retrieved data and combine them
        const priceEpochData = [];
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const entry = data[key];
                priceEpochData.push({ price: ((parseFloat(entry.circulatingSupply)*parseFloat(entry.price))), epoch: entry.entryTime });
            }
        }

        // Process data by month
        const pricesByMonth = {};
        priceEpochData.forEach(({ price, epoch }) => {
            const monthYear = new Date(epoch * 1000).toISOString().slice(0, 7); // Get month and year
            if (!pricesByMonth[monthYear]) {
                pricesByMonth[monthYear] = [];
            }
            pricesByMonth[monthYear].push(price);
        });

        // Calculate average price for each month
        const averagePricesByMonth = {};
        for (const monthYear in pricesByMonth) {
            if (pricesByMonth.hasOwnProperty(monthYear)) {
                const prices = pricesByMonth[monthYear];
                const averagePrice = prices.reduce((total, price) => total + price, 0) / prices.length;

                const [year, month] = monthYear.split('-');
                const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                const monthName = monthNames[parseInt(month, 10) - 1]; // Subtract 1 since month index starts from 0
                const formattedMonthYear = `${monthName} ${year}`;
                
                averagePricesByMonth[formattedMonthYear] = averagePrice;
            }
        }

        console.log("Average Prices By Month:", averagePricesByMonth);
        return averagePricesByMonth;
        // Use the retrieved data as needed
    } catch (error) {
        console.error("Error:", error);
    }
}


export const getTreasuryOneDay =async (tokenname) => {
    try {
        let ref2 = await fireDb.database().ref(`PriceHistory/TreasuryPrice`);


        // Retrieve data
        const snapshot = await ref2.once("value");
        const data = snapshot.val();

        // Get the current time in milliseconds
        const currentTime = Date.now();

        // Calculate the timestamp for 24 hours ago
        const twentyFourHoursAgo =( currentTime - (24 * 60 * 60 *1000 ))/1000;

        console.log("twentyFourHoursAgo",twentyFourHoursAgo)

        // Extract price and epoch from the retrieved data and combine them for the last 24 hours
        const priceEpochDataLast24Hours = [];
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const entry = data[key];
                // Check if the epoch is within the last 24 hours
                if (entry.entryTime >= twentyFourHoursAgo && entry.entryTime <= currentTime) {

                    if(tokenname === "price") {
                        priceEpochDataLast24Hours.push({ price: ((parseFloat(entry.price))), epoch: entry.entryTime });
                    }
                    else if(tokenname === 'jokerValue') {
                        priceEpochDataLast24Hours.push({ price: ((parseFloat(entry.jokerValue))), epoch: entry.entryTime });
                    }else if(tokenname === 'dimeValue') {
                        priceEpochDataLast24Hours.push({ price: ((parseFloat(entry.dimeValue))), epoch: entry.entryTime });
                    }else if(tokenname === 'creditsValue') {
                        priceEpochDataLast24Hours.push({ price: ((parseFloat(entry.creditsValue))), epoch: entry.entryTime });
                    }else if(tokenname === 'USDCValue') {
                        priceEpochDataLast24Hours.push({ price: ((parseFloat(entry.USDCValue))), epoch: entry.entryTime });
                    }
                    // priceEpochDataLast24Hours.push({ price: (parseFloat(entry.circulatingSupply)*parseFloat(entry.price)), epoch: entry.entryTime });
                }
            }
        }

        // console.log("priceEpochDataLast24Hours and Epoch Data for the Last 24 Hours:", priceEpochDataLast24Hours);
        // Use the retrieved data as needed
    } catch (error) {
        console.error("Error:", error);
    }
}


export const getTreasuryOneWeek = async (tokenname) => {
    try {
        let ref2 = await fireDb.database().ref(`PriceHistory/TreasuryPrice`);


        // Retrieve data
        const snapshot = await ref2.once("value");
        const data = snapshot.val();

        // Filter data for the last week
        const currentDate = new Date();
        const oneWeekAgo = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000); // Subtract 7 days in milliseconds
        const filteredData = Object.entries(data).filter(([key, entry]) => {
            const entryDate = new Date(entry.entryTime * 1000); // Convert epoch to milliseconds
            return entryDate >= oneWeekAgo && entryDate <= currentDate;
        });

        // Process data by day within the last week
        const pricesByDay = {};
        filteredData.forEach(([key, entry]) => {
            const date = new Date(entry.entryTime * 1000).getDate(); // Get day of the month (1-31)
            if (!pricesByDay[date]) {
                pricesByDay[date] = [];
            }

            if(tokenname === "price") {
                pricesByDay[date].push((parseFloat(entry.price)))
            }
            else if(tokenname === 'jokerValue') {
                pricesByDay[date].push((parseFloat(entry.jokerValue)))
            }else if(tokenname === 'dimeValue') {
                pricesByDay[date].push((parseFloat(entry.dimeValue)))
            }else if(tokenname === 'creditsValue') {
                pricesByDay[date].push((parseFloat(entry.creditsValue)))
            }else if(tokenname === 'USDCValue') {
                pricesByDay[date].push((parseFloat(entry.USDCValue)))
               
            }
        });

        // Calculate average price for each day
        const averagePricesByDay = {};
        for (const date in pricesByDay) {
            if (pricesByDay.hasOwnProperty(date)) {
                const prices = pricesByDay[date];
                const averagePrice = prices.reduce((total, price) => total + parseFloat(price), 0) / prices.length;
                const entryDate = new Date(filteredData[0][1].entryTime * 1000);
                const month = entryDate.toLocaleString('default', { month: 'short' });
                const formattedMonthDay = `${month} ${date}`;
                averagePricesByDay[formattedMonthDay] = averagePrice;
            }
        }

        console.log("Average Prices By Day:", averagePricesByDay);
        return averagePricesByDay;
        // Use the retrieved data as needed
    } catch (error) {
        console.error("Error:", error);
    }
}


export const getTreasuryOneMonth = async (tokenname) => {
    try {
        let ref2 = await fireDb.database().ref(`PriceHistory/TreasuryPrice`);


        // Retrieve data
        const snapshot = await ref2.once("value");
        const data = snapshot.val();

        // Filter data for the last month
        const currentDate = new Date();
        const oneMonthAgo = new Date(currentDate);
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

        const filteredData = Object.entries(data).filter(([key, entry]) => {
            const entryDate = new Date(entry.entryTime * 1000); // Convert epoch to milliseconds
            return entryDate > oneMonthAgo;
        });

        // Process data by day within the last month
        const pricesByDay = {};
        filteredData.forEach(([key, entry]) => {
            const date = new Date(entry.entryTime * 1000).getDate(); // Get day of the month (1-31)
            if (!pricesByDay[date]) {
                pricesByDay[date] = [];
            }
            if(tokenname === "price") {
                pricesByDay[date].push((parseFloat(entry.price)))
            }
            else if(tokenname === 'jokerValue') {
                pricesByDay[date].push((parseFloat(entry.jokerValue)))
            }else if(tokenname === 'dimeValue') {
                pricesByDay[date].push((parseFloat(entry.dimeValue)))
            }else if(tokenname === 'creditsValue') {
                pricesByDay[date].push((parseFloat(entry.creditsValue)))
            }else if(tokenname === 'USDCValue') {
                pricesByDay[date].push((parseFloat(entry.USDCValue)))
               
            }
        
        });

        // Calculate average price for each day
        const averagePricesByDay = {};
        for (const date in pricesByDay) {
            if (pricesByDay.hasOwnProperty(date)) {
                const prices = pricesByDay[date];
                const averagePrice = prices.reduce((total, price) => total + parseFloat(price), 0) / prices.length;
                const entryDate = new Date(filteredData[0][1].entryTime * 1000);
                const month = entryDate.toLocaleString('default', { month: 'short' });
                const formattedMonthDay = `${month} ${date}`;
                averagePricesByDay[formattedMonthDay] = averagePrice;
            }
        }

        console.log("Average Prices By Day:", averagePricesByDay);
        return averagePricesByDay;
        // Use the retrieved data as needed
    } catch (error) {
        console.error("Error:", error);
    }
}

export const getTreasuryOneYear = async (tokenname) => {
    try {
        let ref2 = await fireDb.database().ref(`PriceHistory/TreasuryPrice`);


        // Retrieve data
        const snapshot = await ref2.once("value");
        const data = snapshot.val();

        // Extract price and epoch from the retrieved data and combine them
        const priceEpochData = [];
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const entry = data[key];
                let pricevar = 'entry.price';
                // console.log("pricedata forma",pricevar)
                if(tokenname === "price") {
                    priceEpochData.push({ price: ((parseFloat(entry.price))), epoch: entry.entryTime });
                }
                else if(tokenname === 'jokerValue') {
                    priceEpochData.push({ price: ((parseFloat(entry.jokerValue))), epoch: entry.entryTime });
                }else if(tokenname === 'dimeValue') {
                    priceEpochData.push({ price: ((parseFloat(entry.dimeValue))), epoch: entry.entryTime });
                }else if(tokenname === 'creditsValue') {
                    priceEpochData.push({ price: ((parseFloat(entry.creditsValue))), epoch: entry.entryTime });
                }else if(tokenname === 'USDCValue') {
                    priceEpochData.push({ price: ((parseFloat(entry.USDCValue))), epoch: entry.entryTime });
                }
                
            }
        }
        // console.log("pricedata",priceEpochData)

        // Process data by month
        const pricesByMonth = {};
        priceEpochData.forEach(({ price, epoch }) => {
            const monthYear = new Date(epoch * 1000).toISOString().slice(0, 7); // Get month and year
            if (!pricesByMonth[monthYear]) {
                pricesByMonth[monthYear] = [];
            }
            pricesByMonth[monthYear].push(price);
        });

        // Calculate average price for each month
        const averagePricesByMonth = {};
        for (const monthYear in pricesByMonth) {
            if (pricesByMonth.hasOwnProperty(monthYear)) {
                const prices = pricesByMonth[monthYear];
                const averagePrice = prices.reduce((total, price) => total + price, 0) / prices.length;

                const [year, month] = monthYear.split('-');
                const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                const monthName = monthNames[parseInt(month, 10) - 1]; // Subtract 1 since month index starts from 0
                const formattedMonthYear = `${monthName} ${year}`;
                
                averagePricesByMonth[formattedMonthYear] = averagePrice;
            }
        }

        console.log("Average Prices By Month:", averagePricesByMonth);
        return averagePricesByMonth;
        // Use the retrieved data as needed
    } catch (error) {
        console.error("Error:", error);
    }
}

export const getADSBOneDay =async (tokenname) => {
    try {
        let ref2 = await fireDb.database().ref(`PriceHistory/JokerDimeCredits`);


        // Retrieve data
        const snapshot = await ref2.once("value");
        const data = snapshot.val();

        // Get the current time in milliseconds
        const currentTime = Date.now();

        // Calculate the timestamp for 24 hours ago
        const twentyFourHoursAgo =( currentTime - (24 * 60 * 60 *1000 ))/1000;

        console.log("twentyFourHoursAgo",twentyFourHoursAgo)

        // Extract price and epoch from the retrieved data and combine them for the last 24 hours
        const priceEpochDataLast24Hours = [];
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const entry = data[key];
                // Check if the epoch is within the last 24 hours
                if (entry.entryTime >= twentyFourHoursAgo && entry.entryTime <= currentTime) {

                    if(tokenname === "dime") {
                        priceEpochDataLast24Hours.push({ price: ((parseFloat(entry.dimeCreditValue))), epoch: entry.entryTime });
                    }
                    else if(tokenname === 'jokerValue') {
                        priceEpochDataLast24Hours.push({ price: ((parseFloat(entry.jokerValue))), epoch: entry.entryTime });
                    }
                    // priceEpochDataLast24Hours.push({ price: (parseFloat(entry.circulatingSupply)*parseFloat(entry.price)), epoch: entry.entryTime });
                }
            }
        }

        // console.log("priceEpochDataLast24Hours and Epoch Data for the Last 24 Hours:", priceEpochDataLast24Hours);
        // Use the retrieved data as needed
    } catch (error) {
        console.error("Error:", error);
    }
}


export const getADSBOneWeek = async (tokenname) => {
    try {
        let ref2 = await fireDb.database().ref(`PriceHistory/JokerDimeCredits`);


        // Retrieve data
        const snapshot = await ref2.once("value");
        const data = snapshot.val();

        // Filter data for the last week
        const currentDate = new Date();
        const oneWeekAgo = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000); // Subtract 7 days in milliseconds
        const filteredData = Object.entries(data).filter(([key, entry]) => {
            const entryDate = new Date(entry.entryTime * 1000); // Convert epoch to milliseconds
            return entryDate >= oneWeekAgo && entryDate <= currentDate;
        });

        // Process data by day within the last week
        const pricesByDay = {};
        filteredData.forEach(([key, entry]) => {
            const date = new Date(entry.entryTime * 1000).getDate(); // Get day of the month (1-31)
            if (!pricesByDay[date]) {
                pricesByDay[date] = [];
            }

            if(tokenname === "dime") {
                pricesByDay[date].push((parseFloat((entry.dimeCreditValue))))
            }
            else if(tokenname === 'jokerValue') {
                pricesByDay[date].push((parseFloat(entry.jokerValue)))
            }
        });

        // Calculate average price for each day
        const averagePricesByDay = {};
        for (const date in pricesByDay) {
            if (pricesByDay.hasOwnProperty(date)) {
                const prices = pricesByDay[date];
                const averagePrice = prices.reduce((total, price) => total + parseFloat(price), 0) / prices.length;
                const entryDate = new Date(filteredData[0][1].entryTime * 1000);
                const month = entryDate.toLocaleString('default', { month: 'short' });
                const formattedMonthDay = `${month} ${date}`;
                averagePricesByDay[formattedMonthDay] = averagePrice;
            }
        }

        console.log("Average Prices By Day:", averagePricesByDay);
        return averagePricesByDay;
        // Use the retrieved data as needed
    } catch (error) {
        console.error("Error:", error);
    }
}


export const getADSBOneMonth = async (tokenname) => {
    try {
        let ref2 = await fireDb.database().ref(`PriceHistory/JokerDimeCredits`);


        // Retrieve data
        const snapshot = await ref2.once("value");
        const data = snapshot.val();

        // Filter data for the last month
        const currentDate = new Date();
        const oneMonthAgo = new Date(currentDate);
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

        const filteredData = Object.entries(data).filter(([key, entry]) => {
            const entryDate = new Date(entry.entryTime * 1000); // Convert epoch to milliseconds
            return entryDate > oneMonthAgo;
        });

        // Process data by day within the last month
        const pricesByDay = {};
        filteredData.forEach(([key, entry]) => {
            const date = new Date(entry.entryTime * 1000).getDate(); // Get day of the month (1-31)
            if (!pricesByDay[date]) {
                pricesByDay[date] = [];
            }
            if(tokenname === "dime") {
                pricesByDay[date].push((parseFloat(entry.dimeCreditValue)))
            }
            else if(tokenname === 'jokerValue') {
                pricesByDay[date].push((parseFloat(entry.jokerValue)))
            }
        
        });

        // Calculate average price for each day
        const averagePricesByDay = {};
        for (const date in pricesByDay) {
            if (pricesByDay.hasOwnProperty(date)) {
                const prices = pricesByDay[date];
                const averagePrice = prices.reduce((total, price) => total + parseFloat(price), 0) / prices.length;
                const entryDate = new Date(filteredData[0][1].entryTime * 1000);
                const month = entryDate.toLocaleString('default', { month: 'short' });
                const formattedMonthDay = `${month} ${date}`;
                averagePricesByDay[formattedMonthDay] = averagePrice;
            }
        }

        console.log("Average Prices By Day:", averagePricesByDay);
        return averagePricesByDay;
        // Use the retrieved data as needed
    } catch (error) {
        console.error("Error:", error);
    }
}

export const getADSBOneYear = async (tokenname) => {
    try {
        let ref2 = await fireDb.database().ref(`PriceHistory/JokerDimeCredits`);


        // Retrieve data
        const snapshot = await ref2.once("value");
        const data = snapshot.val();

        // Extract price and epoch from the retrieved data and combine them
        const priceEpochData = [];
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const entry = data[key];
                let pricevar = 'entry.price';
                // console.log("pricedata forma",pricevar)
                if(tokenname === "dime") {
                    priceEpochData.push({ price: ((parseFloat(entry.dimeCreditValue))), epoch: entry.entryTime });
                }
                else if(tokenname === 'jokerValue') {
                    priceEpochData.push({ price: ((parseFloat(entry.jokerValue))), epoch: entry.entryTime });
                }
                
            }
        }
        // console.log("pricedata",priceEpochData)

        // Process data by month
        const pricesByMonth = {};
        priceEpochData.forEach(({ price, epoch }) => {
            const monthYear = new Date(epoch * 1000).toISOString().slice(0, 7); // Get month and year
            if (!pricesByMonth[monthYear]) {
                pricesByMonth[monthYear] = [];
            }
            pricesByMonth[monthYear].push(price);
        });

        // Calculate average price for each month
        const averagePricesByMonth = {};
        for (const monthYear in pricesByMonth) {
            if (pricesByMonth.hasOwnProperty(monthYear)) {
                const prices = pricesByMonth[monthYear];
                const averagePrice = prices.reduce((total, price) => total + price, 0) / prices.length;

                const [year, month] = monthYear.split('-');
                const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                const monthName = monthNames[parseInt(month, 10) - 1]; // Subtract 1 since month index starts from 0
                const formattedMonthYear = `${monthName} ${year}`;
                
                averagePricesByMonth[formattedMonthYear] = averagePrice;
            }
        }

        console.log("Average Prices By Month:", averagePricesByMonth);
        return averagePricesByMonth;
        // Use the retrieved data as needed
    } catch (error) {
        console.error("Error:", error);
    }
}


export const getCOllateralOneYear = async () => {
    try {
        let ref2 = await fireDb.database().ref(`PriceHistory/collateralValue`);


        // Retrieve data
        const snapshot = await ref2.once("value");
        const data = snapshot.val();

        // Extract price and epoch from the retrieved data and combine them
        const priceEpochData = [];
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const entry = data[key];
                let pricevar = 'entry.price';
                // console.log("pricedata forma",pricevar)
                
                    priceEpochData.push({ price: ((parseFloat(entry.Collateral))), epoch: entry.entryTime });
               
                
            }
        }
        // console.log("pricedata",priceEpochData)

        // Process data by month
        const pricesByMonth = {};
        priceEpochData.forEach(({ price, epoch }) => {
            const monthYear = new Date(epoch * 1000).toISOString().slice(0, 7); // Get month and year
            if (!pricesByMonth[monthYear]) {
                pricesByMonth[monthYear] = [];
            }
            pricesByMonth[monthYear].push(price);
        });

        // Calculate average price for each month
        const averagePricesByMonth = {};
        for (const monthYear in pricesByMonth) {
            if (pricesByMonth.hasOwnProperty(monthYear)) {
                const prices = pricesByMonth[monthYear];
                const averagePrice = prices.reduce((total, price) => total + price, 0) / prices.length;

                const [year, month] = monthYear.split('-');
                const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                const monthName = monthNames[parseInt(month, 10) - 1]; // Subtract 1 since month index starts from 0
                const formattedMonthYear = `${monthName} ${year}`;
                
                averagePricesByMonth[formattedMonthYear] = averagePrice;
            }
        }

        console.log("Average Prices By Month:", averagePricesByMonth);
        return averagePricesByMonth;
        // Use the retrieved data as needed
    } catch (error) {
        console.error("Error:", error);
    }
}

export const getCollateralIncrease = async (interval) => {
    try {
        let ref2 = await fireDb.database().ref(`PriceHistory/collateralValue`);

        // Retrieve data
        const snapshot = await ref2.once("value");
        const data = snapshot.val();

        // Extract price and epoch from the retrieved data and combine them
        const priceEpochData = [];
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const entry = data[key];
                priceEpochData.push({ price: parseFloat(entry.Collateral), epoch: entry.entryTime });
            }
        }

        // Sort the data by epoch in ascending order
        priceEpochData.sort((a, b) => a.epoch - b.epoch);

        // Get the epoch of the current date
        const currentDateEpoch = Date.now() / 1000;

        // Calculate the epoch for the start of the specified interval
        let intervalStartEpoch;
        if (interval === '1hour') {
            intervalStartEpoch = currentDateEpoch - (1 * 60 * 60);
        } else if (interval === '24hours') {
            intervalStartEpoch = currentDateEpoch - (24 * 60 * 60);
        } else if (interval === '1month') {
            intervalStartEpoch = currentDateEpoch - (30 * 24 * 60 * 60); // Assuming 30 days in a month
        } else {
            throw new Error("Invalid interval specified.");
        }

        // Filter the data for the specified interval
        const dataInterval = priceEpochData.filter(entry => entry.epoch >= intervalStartEpoch);

        // Calculate the collateral values at the beginning and end of the interval
        const collateralValueAtStart = dataInterval[0].price;
        const collateralValueAtEnd = dataInterval[dataInterval.length - 1].price;

        // Calculate the percentage increase
        const percentageIncrease = ((collateralValueAtEnd - collateralValueAtStart) / collateralValueAtStart) * 100;

        console.log(`Collateral value at start of ${interval}:`, collateralValueAtStart);
        console.log(`Collateral value at end of ${interval}:`, collateralValueAtEnd);
        console.log(`Percentage increase in collateral value over ${interval}:`, percentageIncrease);

        return percentageIncrease;
    } catch (error) {
        console.error("Error:", error);
    }
}

export const gepriceIncrease = async (tokenanem,interval) => {
    try {
        let ref2 = await fireDb.database().ref(`PriceHistory/${tokenanem}`);

        // Retrieve data
        const snapshot = await ref2.once("value");
        const data = snapshot.val();
       
        // Extract price and epoch from the retrieved data and combine them
        const priceEpochData = [];
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const entry = data[key];
                priceEpochData.push({ price: parseFloat(entry.price), epoch: entry.entryTime });
            }
        }
        

        // Sort the data by epoch in ascending order
        priceEpochData.sort((a, b) => a.epoch - b.epoch);

        // Get the epoch of the current date
        const currentDateEpoch = Date.now() / 1000;

        // Calculate the epoch for the start of the specified interval
        let intervalStartEpoch;
        if (interval === '1hour') {
            intervalStartEpoch = currentDateEpoch - (1 * 60 * 60 );
        } else if (interval === '24hours') {
            intervalStartEpoch = currentDateEpoch - (24 * 60 * 60 );
        } else if (interval === '1month') {
            intervalStartEpoch = currentDateEpoch - (30 * 24 * 60 * 60 ); // Assuming 30 days in a month
        } else {
            throw new Error("Invalid interval specified.");
        }
  
        // Filter the data for the specified interval
        const dataInterval = priceEpochData.filter(entry => parseFloat(entry.epoch) >= parseFloat(intervalStartEpoch));
        console.log("dataprice",intervalStartEpoch,priceEpochData,dataInterval);
        // Calculate the collateral values at the beginning and end of the interval
        const collateralValueAtStart = dataInterval[0].price;
        const collateralValueAtEnd = dataInterval[dataInterval.length - 1].price;

        // Calculate the percentage increase
        const percentageIncrease = ((collateralValueAtEnd - collateralValueAtStart) / collateralValueAtStart) * 100;

        console.log(`Collateral value at start of ${interval}:`, collateralValueAtStart);
        console.log(`Collateral value at end of ${interval}:`, collateralValueAtEnd);
        console.log(`Percentage increase in collateral value over ${interval}:`, percentageIncrease);

        return percentageIncrease;
    } catch (error) {
        console.error("Error:", error);
    }
}


export const createTxn = async(tokenname,txnhash,type,to,from) =>{
    let ref2 = await fireDb.database().ref(`PriceHistory/TxnHistory`);
        const db = ref2.push().key;   
        let currenttime = (new Date().getTime())/1000;                                                                   
        ref2.child(db).set({
          key:db,tokenNmae:tokenname,entryTime:currenttime,txnHash:txnhash,From:from,To:to,Type:type
        })
        .then(()=>{
          console.log(" updatePrice done")
        }).catch((error) => {
            console.error("Error:", error);
          });

}
// export const retrieveTxnByPage = async (pageNumber) => {
//     const itemsPerPage = 2;
//     const offset = (pageNumber - 1) * itemsPerPage;

//     let ref = fireDb.database().ref(`PriceHistory/TxnHistory`);
//     ref = ref.orderByChild('entryTime').limitToFirst(itemsPerPage).startAt(offset);

//     const snapshot = await ref.once('value');
//     const data = snapshot.val();
//     console.log("retrieved",data);

//     let ref1 = fireDb.database().ref(`PriceHistory/TxnHistory`);
//     ref1 = ref1.orderByChild('entryTime'); // Optionally, you can order by a specific child node

//     const snapshot1 = await ref1.once('value');
//     const data1 = snapshot1.val();
//     console.log("snapshot1",data1);
    
//     return data;
// };
export const retrieveTxnByPage = async (pageNumber,itemsPerPage) => {
    
    const totalItems = pageNumber * itemsPerPage;

    let ref = fireDb.database().ref(`PriceHistory/TxnHistory`);
    ref = ref.orderByChild('entryTime').limitToLast(totalItems);

    const snapshot = await ref.once('value');
    const data = snapshot.val();
    
    // Convert the object to an array and reverse it to get the most recent entries first
    const dataArray = Object.values(data).reverse();
    
    // Get the two entries corresponding to the current page
    const startIndex = Math.max(dataArray.length - itemsPerPage, 0);
    const pageData = dataArray.slice(startIndex);
    console.log("snapshot1",pageData);

    return pageData;
}

export const getTotalPages = async (itemsPerPage) => {
    let ref = fireDb.database().ref(`PriceHistory/TxnHistory`);
    const snapshot = await ref.once('value');
    const totalEntries = snapshot.numChildren();

    const totalPages = Math.ceil(totalEntries / itemsPerPage);
    
    return totalPages;
};

export const recordUserVisits = async (address, pageName) => {
    const locationDetails = await fetch('https://geolocation-db.com/json/')
    let location = await locationDetails.json();
    let ipAddress = location.IPv4;
    let region = location.country_name;

    let addressSet = address ? address : "Not Connected";

    let ref2 = fireDb.database().ref(`UserVisits`);
        const db = ref2.push().key;   
        let currentTime = (new Date().getTime())/1000;                                                                   
        ref2.child(db).set({
          address: addressSet,
          ipAddress: ipAddress,
          pageName: pageName,
          region: region,
          createdAt: currentTime,
        })
        .then(()=>{
          console.log(" updatePrice done")
        }).catch((error) => {
            console.error("Error:", error);
          });
}