import { Badge, Col, ProgressBar, Row } from "react-bootstrap";
import LayoutAfterLogin from "../../component/LayoutAL";
import Tooltip from "../../Snippets/Tooltip";
import EthereumIcon from '../../asserts/IMAGES/Ethereum-icon.svg'
import DollerLogo from '../../asserts/IMAGES/dai.svg'
import InfoIcon from '../../asserts/IMAGES/infoIcon-blue.svg'
import TransactionPending from '../../asserts/IMAGES/transsationPending.svg';
import ReCAPTCHA from 'react-google-recaptcha';

import Icon1 from '../../asserts/IMAGES/jokerRedLogo.svg';
import Icon2 from '../../asserts/IMAGES/dimeCyanLogo.svg';
import Icon3 from '../../asserts/IMAGES/creditsBlackLogo.svg';

import { ethers } from "ethers";

import { CHAIN_URL, JOKER_Token_Address, JOKER_Token_ABI, DIME_Token_Address, DIME_Token_ABI, CREDITS_Token_Address, CREDITS_Token_ABI, DAI_TOKEN_Address, DAI_TOKEN_ABI, Faucet_Address, TXN_ID, Faucet_ABI, API_KEY, Network_Name } from "../../abi/ABI&ContractAddress";
import { NumberAbbreviation, PendingModal, getTokenBalance } from "../../abi/CommonSolFunctions";
import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers5/react";
           
import { useEffect, useState, useRef } from "react";
import { createTxn, recordUserVisits } from "../../abi/firebasecode";

/* global BigInt */
function StakeReward({ balances, balanceOfTokens }) {

    const recaptchaRefDai = useRef(null);
    const recaptchaRefDime = useRef(null);
    const recaptchaRefJoker = useRef(null);
    const recaptchaRefCredits = useRef(null);

    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
    // const provider = new ethers.providers.JsonRpcProvider(CHAIN_URL);

    const [jokerBalance, setJokerBalance] = useState("");
    const [DimeBalance, setDimeBalance] = useState("");
    const [CreditsBalance, setCreditsBalance] = useState("");
    const [DaiBalance, setDaiBalance] = useState("");

    const [jokerContractBalance, setJokerContractBalance] = useState("");
    const [DimeContractBalance, setDimeContractBalance] = useState("");
    const [CreditsContractBalance, setCreditsContractBalance] = useState("");
    const [DaiContractBalance, setDaiContractBalance] = useState("");

    const [showTr, setShowTr] = useState(false);
    const [pending, setPending] = useState("");
    const [txId, setTxId] = useState("");
    const [TxnImage, setTxnImage] = useState("");
    const [errormsg, seterrormsg] = useState("");
    const [errorStatementDai, setErrorStatementDai] = useState("");
    const [errorStatementDime, setErrorStatementDime] = useState("");
    const [errorStatementJoker, setErrorStatementJoker] = useState("");
    const [errorStatementCredits, setErrorStatementCredits] = useState("");
    const [isVerified, setIsVerified] = useState(false);

    const handleCloseTransation = () => setShowTr(false);
    const handleShowTransation = () => setShowTr(true);

    const provider = ethers.getDefaultProvider(Network_Name, {
        etherscan: API_KEY});
        console.log("jokerBalance",provider)
    const JOKERContract = new ethers.Contract(JOKER_Token_Address, JOKER_Token_ABI, provider);

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }    

    const getAllBalanceFunction = async() =>{
        await recordUserVisits(address, "Faucet");
        if(isConnected){   
            let jokerBalance = await getTokenBalance(address,JOKER_Token_Address,JOKER_Token_ABI);
            console.log("jokerBalance",jokerBalance)
            setJokerBalance(jokerBalance);
            let dimeBalance = await getTokenBalance(address,DIME_Token_Address,DIME_Token_ABI);
            setDimeBalance(dimeBalance);
            let creditsBalance = await getTokenBalance(address,CREDITS_Token_Address,CREDITS_Token_ABI);
            setCreditsBalance(creditsBalance);
            let daiBalance = await getTokenBalance(address,DAI_TOKEN_Address,DAI_TOKEN_ABI);
            setDaiBalance(daiBalance);
        }

            let jokerContractBalance = await getTokenBalance(Faucet_Address,JOKER_Token_Address,JOKER_Token_ABI);
            setJokerContractBalance(jokerContractBalance/1e9);
            let dimeContractBalance = await getTokenBalance(Faucet_Address,DIME_Token_Address,DIME_Token_ABI);
            setDimeContractBalance(dimeContractBalance/1e9);
            let creditsContractBalance = await getTokenBalance(Faucet_Address,CREDITS_Token_Address,CREDITS_Token_ABI);
            setCreditsContractBalance(creditsContractBalance/1e18);
            console.log("credits",creditsContractBalance)
            let daiContractBalance = await getTokenBalance(Faucet_Address,DAI_TOKEN_Address,DAI_TOKEN_ABI);
            setDaiContractBalance(daiContractBalance/1e18);
    }

    useEffect(() =>{getAllBalanceFunction()},[address,isConnected]);

    const faucet = async(name) =>{
        if(!isVerified)
        {
            if(name === 'DAI')
            {
                setErrorStatementDai("Captcha not verified");
                await sleep(3000);
                setErrorStatementDai("");
            }
            else if(name === 'DIME')
            {
                setErrorStatementDime("Captcha not verified");
                await sleep(3000);
                setErrorStatementDime("");
            }
            else if(name === 'JOKER')
            {
                setErrorStatementJoker("Captcha not verified");
                await sleep(3000);
                setErrorStatementJoker("");
            }
            else
            {
                setErrorStatementCredits("Captcha not verified");
                await sleep(3000);
                setErrorStatementCredits("");
            }
        }
        else if(isConnected){
            try{
                const ethersProvider =  new ethers.providers.Web3Provider(walletProvider)
                const signer =  ethersProvider.getSigner() 
                const JOKERContract = new ethers.Contract(Faucet_Address, Faucet_ABI, signer);
                await showPopUp("yes",TransactionPending,"","");
                const swaptxn = await JOKERContract.dispense(name);
                
                await swaptxn.wait();
                await TxnId_PopUp(swaptxn.hash);
                await balanceOfTokens();
                await createTxn(name,swaptxn.hash,"faucet",address,Faucet_Address);
                setIsVerified(false);
                if(name === 'DAI')
                {
                    resetRecaptchaDai();
                }
                else if(name === 'DIME')
                {
                    resetRecaptchaDime();
                }
                else if(name === 'JOKER')
                {
                    resetRecaptchaJoker();
                }
                else
                {
                    resetRecaptchaCredits();
                }
            }catch(err){
                showPopUp("not",TransactionPending,"",(err.reason).toString());
                console.log("error",err);
                
            }
        }
    }
    const TxnId_PopUp =async(hash) =>{
        let txnid = TXN_ID+hash;
        showPopUp("no",TransactionPending,txnid,"");
        
        await getAllBalanceFunction();
    }

    const showPopUp = async(pendingvalue,imgSrc,txid,errormsg)=>{
        // console.log("showPopUp",pendingvalue,imgSrc,txid,errormsg)
        setPending(pendingvalue);
        setTxId(txid);
        setTxnImage(imgSrc);
        seterrormsg(errormsg);    
        handleShowTransation();        
    }

    const onChange = (value) => {
        if (value) {
            setIsVerified(true);
        } else {
            setIsVerified(false);
        }
    };

    const resetRecaptchaDai = () => {
        if (recaptchaRefDai.current) {
            recaptchaRefDai.current.reset();
        }
    };

    const resetRecaptchaDime = () => {
        if (recaptchaRefDime.current) {
            recaptchaRefDime.current.reset();
        }
    };

    const resetRecaptchaJoker = () => {
        if (recaptchaRefJoker.current) {
            recaptchaRefJoker.current.reset();
        }
    };

    const resetRecaptchaCredits = () => {
        if (recaptchaRefCredits.current) {
            recaptchaRefCredits.current.reset();
        }
    };

    return (  
        <LayoutAfterLogin menuActive={"faucet"} balances={balances}>
            <div className="p-24 px-xl-5 app-contain">
                <div className="mb-3">
                    {/* <Link className="flex g-2 mb-3 text-white"><img src={BackIcon} alt="icon" /> Back</Link> */}
                    <Row>
                        <Col md={6} xl={8}>
                            <h2 className="mb-2">Faucet</h2>
                            {/* <h6 className="text-gray mb-0"> Assets required to test our ecosystem is available here.</h6> */}
                        </Col>
                    </Row>
                </div>

                <div className="mb-3">
                    <Badge bg="blue"> Assets required to test our ecosystem is available here.
                     {/* <img src={InfoIcon} alt="InfoIcon" /> */}
                     </Badge>
                </div>

               

                <hr className="my-md-3 my-2" />
                <Row className="g-2 align-items-center">
                    <Col xs={6} md={2}>
                        <div className="flex g-2">
                            <img src={DollerLogo} alt="DollerLogo" height={'42px'}/>
                            <div>
                                <h5 className="mb-0">DAI</h5>
                                {/* <h6 className="text-gray mb-0">Staking Rewards</h6> */}
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} md={1}>
                        <h5 className="mb-0">Balance</h5>
                        <h6 className="text-gray mb-0"><NumberAbbreviation number={DaiBalance?parseFloat(DaiBalance/1e18).toFixed(3):'0.0'}/></h6>
                    </Col>
                    <Col xs={12} md={3}>
                        <ProgressBar style={{height: '5px'}} variant="white" now={(DaiContractBalance/10000)*100} />
                        <div className="flex mt-1 justify-content-between">
                            <h6 className="text-gray text-600 mb-0">Faucet Remaining</h6>
                            <h6 className="text-green-light text-600 mb-0">{parseFloat((DaiContractBalance/10000)*100).toFixed(2)}%</h6>
                        </div>
                    </Col>
                    <Col xs={12} md={5} className="ms-auto">
                        <div className="flex flex-wrap g-2 justify-content-between">
                            <div className="text-end">
                                <h5 className="mb-1 ">5 DAI</h5>
                                <Badge bg="green-light">DISPENSABLE</Badge>
                            </div>
                            <div>
                            <ReCAPTCHA
                                ref={recaptchaRefDai}
                                sitekey="6LdQ6q0mAAAAACT7MyMX6Cl_wZn1AVL4goqxF-oO"
                                onChange={onChange}
                                theme="dark"
                            />
                            </div>
                            <div>
                                <button className="btn btn-grad px-md-4 px-3 py-md-2" onClick={()=>faucet("DAI")}>Dispense</button>
                            </div>
                        </div>
                    </Col>
                    <div className="mt-3 text-end">
                        {errorStatementDai ? <h5 className="text-red mb-0">{errorStatementDai}</h5> : <></>}
                    </div>
                </Row>

                <hr className="my-md-3 my-2" />
                <Row className="g-2 align-items-center">
                    <Col xs={6} md={2}>
                        <div className="flex g-2">
                            <img src={Icon1} alt="DollerLogo" height={'42px'}/>
                            <div>
                                <h5 className="mb-0">JOKER</h5>
                                {/* <h6 className="text-gray mb-0">Staking Rewards</h6> */}
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} md={1}>
                        <h5 className="mb-0">Your Balance</h5>
                        <h6 className="text-gray mb-0"><NumberAbbreviation number={jokerBalance?parseFloat(jokerBalance/1e9).toFixed(3):'0.0'}/></h6>
                    </Col>
                    <Col xs={12} md={3}>
                        <ProgressBar style={{height: '5px'}} variant="white" now={(DaiContractBalance/10000)*100} />
                        <div className="flex mt-1 justify-content-between">
                            <h6 className="text-gray text-600 mb-0">Faucet Remaining</h6>
                            <h6 className="text-green-light text-600 mb-0">{parseFloat((DaiContractBalance/10000)*100).toFixed(2)}%</h6>
                        </div>
                    </Col>
                    <Col xs={12} md={5} className="ms-auto">
                        <div className="flex  flex-wrap g-2 justify-content-between">
                        <div className="text-end">
                                <h5 className="mb-1 ">5 JOKER</h5>
                                <Badge bg="green-light">DISPENSABLE</Badge>
                            </div>
                            <div>
                            <ReCAPTCHA
                                ref={recaptchaRefJoker}
                                sitekey="6LdQ6q0mAAAAACT7MyMX6Cl_wZn1AVL4goqxF-oO"
                                onChange={onChange}
                                theme="dark"
                            />
                            </div>
                            <div>
                            <button className="btn btn-grad px-md-4 px-3 py-md-2" onClick={()=>faucet("JOKER")}>Dispense</button>
                                {/* <button className="btn btn-grad outline px-md-4 px-3 py-md-2" onClick={()=>faucet("DAI")}>Dispense</button> */}
                            </div>
                        </div>
                    </Col>
                    <div className="mt-3 text-end">
                        {errorStatementJoker ? <h5 className="text-red mb-0">{errorStatementJoker}</h5> : <></>}
                    </div>
                </Row>
                <hr className="my-md-3 my-2" />
                <Row className="g-2 align-items-center">
                    <Col xs={6} md={2}>
                        <div className="flex g-2">
                            <img src={Icon2} alt="DollerLogo" height={'42px'}/>
                            <div>
                                <h5 className="mb-0">DIME</h5>
                                {/* <h6 className="text-gray mb-0">Staking Rewards</h6> */}
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} md={1}>
                        <h5 className="mb-0">Your Balance</h5>
                        <h6 className="text-gray mb-0"><NumberAbbreviation number={DimeBalance?parseFloat(DimeBalance/1e9).toFixed(3):'0.0'}/></h6>
                    </Col>
                    <Col xs={12} md={3}>
                        <ProgressBar style={{height: '5px'}} variant="white" now={(DaiContractBalance/10000)*100} />
                        <div className="flex mt-1 justify-content-between">
                            <h6 className="text-gray text-600 mb-0">Faucet Remaining</h6>
                            <h6 className="text-green-light text-600 mb-0">{parseFloat((DaiContractBalance/10000)*100).toFixed(2)}%</h6>
                        </div>
                    </Col>
                    <Col xs={12} md={5} className="ms-auto">
                        <div className="flex  flex-wrap g-2 justify-content-between">
                        <div className="text-end">
                                <h5 className="mb-1 ">5 DIME</h5>
                                <Badge bg="green-light">DISPENSABLE</Badge>
                            </div>
                            <div>
                            <ReCAPTCHA
                                ref={recaptchaRefDime}
                                sitekey="6LdQ6q0mAAAAACT7MyMX6Cl_wZn1AVL4goqxF-oO"
                                onChange={onChange}
                                theme="dark"
                            />
                            </div>
                            <div>
                            <button className="btn btn-grad px-md-4 px-3 py-md-2" onClick={()=>faucet("DIME")}>Dispense</button>
                                {/* <button className="btn btn-grad outline px-md-4 px-3 py-md-2"><span>Claim</span></button> */}
                            </div>
                        </div>
                    </Col>
                    <div className="mt-3 text-end">
                        {errorStatementDime ? <h5 className="text-red mb-0">{errorStatementDime}</h5> : <></>}
                    </div>
                </Row>
                <hr className="my-md-3 my-2" />
                <Row className="g-2 align-items-center">
                    <Col xs={6} md={2}>
                        <div className="flex g-2">
                            <img src={Icon3} alt="DollerLogo" height={'42px'}/>
                            <div>
                                <h5 className="mb-0">CREDITS</h5>
                                {/* <h6 className="text-gray mb-0">Staking Rewards</h6> */}
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} md={1}>
                        <h5 className="mb-0">Your Balance</h5>
                        <h6 className="text-gray mb-0"><NumberAbbreviation number={CreditsBalance?parseFloat(CreditsBalance/1e18).toFixed(3):'0.0'}/></h6>
                    </Col>
                    <Col xs={12} md={3}>
                        <ProgressBar style={{height: '5px'}} variant="white" now={(DaiContractBalance/10000)*100} />
                        <div className="flex mt-1 justify-content-between">
                            <h6 className="text-gray text-600 mb-0">Faucet Remaining</h6>
                            <h6 className="text-green-light text-600 mb-0">{parseFloat((DaiContractBalance/10000)*100).toFixed(2)}%</h6>
                        </div>
                    </Col>
                    <Col xs={12} md={5} className="ms-auto">
                        <div className="flex  flex-wrap g-2 justify-content-between">
                        <div className="text-end">
                                <h5 className="mb-1 ">5 CREDITS</h5>
                                <Badge bg="green-light">DISPENSABLE</Badge>
                            </div>
                            <div>
                            <ReCAPTCHA
                                ref={recaptchaRefCredits}
                                sitekey="6LdQ6q0mAAAAACT7MyMX6Cl_wZn1AVL4goqxF-oO"
                                onChange={onChange}
                                theme="dark"
                            />
                            </div>
                            <div>
                            <button className="btn btn-grad px-md-4 px-3 py-md-2" onClick={()=>faucet("CREDIT")}>Dispense</button>
                                {/* <button className="btn btn-grad outline px-md-4 px-3 py-md-2"><span>Claim</span></button> */}
                            </div>
                        </div>
                    </Col>
                    <div className="mt-3 text-end">
                        {errorStatementCredits ? <h5 className="text-red mb-0">{errorStatementCredits}</h5> : <></>}
                    </div>
                </Row>
                <hr className="my-md-3 my-2" />
                <Row className="g-2 align-items-center">
                    <Col xs={6} md={2}>
                        <div className="flex g-2">
                            <img src={EthereumIcon} alt="DollerLogo" height={'42px'}/>
                            <div>
                                <h5 className="mb-0">Sepolia ETH</h5>
                                {/* <h6 className="text-gray mb-0">Staking Rewards</h6> */}
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} md={1}>
                        <h5 className="mb-0">Your Balance</h5>
                        <h6 className="text-gray mb-0">{balances.eth?parseFloat(balances.eth).toFixed(3):'0.0'}</h6>
                    </Col>
                    <Col xs={12} md={3}>
                        {/* <ProgressBar style={{height: '5px'}} variant="white" now={(DaiContractBalance/10000)*100} /> */}
                        {/* <div className="flex mt-1 justify-content-between">
                            <h6 className="text-gray text-600 mb-0">Faucet Remaining</h6>
                            <h6 className="text-green-light text-600 mb-0">{(DaiContractBalance/10000)*100}%</h6>
                        </div> */}
                    </Col>
                    <Col xs={12} md={5} className="ms-auto">
                        <div className="flex g-2 justify-content-between">
                        <div className="text-end">
                                {/* <h5 className="mb-1 ">5 CREDITS</h5>
                                <Badge bg="green-light">DISPENSABLE</Badge> */}
                            </div>
                            <div>
                            {/* <ReCAPTCHA
                                ref={recaptchaRefCredits}
                                sitekey="6LdQ6q0mAAAAACT7MyMX6Cl_wZn1AVL4goqxF-oO"
                                onChange={onChange}
                                theme="dark"
                            /> */}
                            </div>
                            <div>
                            <button className="btn btn-grad px-md-4 px-3 py-md-2" ><a href='https://sepolia-faucet.pk910.de/#/' target="_blank" rel="noopener noreferrer" style={{ color: 'black' }}>Get Testnet ETH</a></button>
                                {/* <button className="btn btn-grad outline px-md-4 px-3 py-md-2"><span>Claim</span></button> */}
                            </div>
                        </div>
                    </Col>
                    {/* <div className="mt-3 text-end">
                        {errorStatementCredits ? <h5 className="text-red mb-0">{errorStatementCredits}</h5> : <></>}
                    </div> */}
                </Row>
                <hr className="my-md-3 my-2" />
            </div>
            <PendingModal pending={pending} showTr={showTr}  handleCloseTransation={handleCloseTransation} imgSrc={TxnImage} txId={txId} errormsg={errormsg}/>

        </LayoutAfterLogin>
    );
}

export default StakeReward;