import LayoutAfterLogin from "../../component/LayoutAL";
import JokerIcon from '../../asserts/IMAGES/jokerRedLogo.svg';
import { ProgressBar, Table } from "react-bootstrap";
import { LineChart } from "../Dashboard/Snippets/LineChart";
import TransferIcon from '../../asserts/IMAGES/transfer-icon.svg';
import DollerLogo from '../../asserts/IMAGES/dai.svg'
import Icon1 from '../../asserts/IMAGES/joker-icon.svg';
import Icon2 from '../../asserts/IMAGES/dimeCyanLogo.svg';
import Icon3 from '../../asserts/IMAGES/creditsBlackLogo.svg';
import tauIcon from '../../asserts/IMAGES/tau-original.png'
import { useEffect, useState } from "react";
import { NumberAbbreviation, getCreditsPrice, getCreditsSupply, getDimePrice, getDimeSupply, getJokerPrice, getJokerSupply, getTAUPrice, getTauSupply, getTokenBalance } from "../../abi/CommonSolFunctions";
import { gepriceIncrease, getPriceOneWeek, recordUserVisits, retrieveTxnByPage } from "../../abi/firebasecode";
import { CREDITS_Token_ABI, CREDITS_Token_Address, DAI_TOKEN_ABI, DAI_TOKEN_Address, DIME_Token_ABI, DIME_Token_Address, ECO_Reserve_Treasury_Address, JOKER_Token_ABI, JOKER_Token_Address, TAU_Token_ABI, TAU_Token_Address } from "../../abi/ABI&ContractAddress";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";

function Analytics({balances}) {
    const { address } = useWeb3ModalAccount();

    const[JokerPrice,setJokerPrice] = useState("");
    const[DimePrice,setDimePrice] = useState("");
    const[CreditsPrice,setCreditsPrice] = useState("");
    const[TAUPrice,setTauPrice] = useState("");


    const[marketcapJoker,setmarketcapJoker] = useState("");
    const[marketcapDime,setmarketcapDime] = useState("");
    const[marketcapCredits,setmarketcapCredits] = useState("");
    const[marketcapTau,setmarketcapTau] = useState("");

    const[joker1hour,setjoker1hour] = useState("");
    const[joker24Hour,setjoker24Hour] = useState("");
    const[joker1Month,setjoker1Month] = useState("");
    const[dime1hour,setDime1hour] = useState("");
    const[dime24hour,setDime24Hour] = useState("");
    const[dime1month,setDime1Month] = useState("");
    const[credit1hour,setCredit1hour] = useState("");
    const[credit24hour,setCredit24Hour] = useState("");
    const[credit1month,setCredit1Month] = useState("");
    const[tau1hour,setTau1hour] = useState("");
    const[tau24hour,setTau24Hour] = useState("");
    const[tau1month,setTau1Month] = useState("");

    const[daiBalanceT,setdaiBalanceT] = useState("");
    const[jokerBalanceT,setjokerBalanceT] = useState("");
    const[dimeBalanceT,setdimeBalanceT] = useState("");
    const[creditsBalanceT,setcreditsBalanceT] = useState("");

    const [Oneyeardates, setOneyeardates] = useState(0);
    const [Oneyearprices, setOneyearprices] = useState(0);
    const [Oneyeardates1, setOneyeardates1] = useState(0);
    const [Oneyearprices1, setOneyearprices1] = useState(0);
    const [Oneyeardates2, setOneyeardates2] = useState(0);
    const [Oneyearprices2, setOneyearprices2] = useState(0);
    const [Oneyeardates3, setOneyeardates3] = useState(0);
    const [Oneyearprices3, setOneyearprices3] = useState(0);
    const[txndata,settxndata] = useState([])
    const[startIndex,setstartindex] = useState(1);
    const[page,setpage] = useState(5);

    const labels = ['JAN', 'FEB', 'MAR', 'APR', 'MAY'];
    const data = [2,20,12,15,9.247737500000001]


    const getallvalues = async() =>{
        let oneyearvalue = await getPriceOneWeek("JokerPrice")
        const dates = Object.keys(oneyearvalue);
        setOneyeardates(dates)
        const prices = Object.values(oneyearvalue);
        setOneyearprices(prices)
      }
      useEffect(()=>{getallvalues()},[])

      const getallvalues1 = async() =>{
        let oneyearvalue = await getPriceOneWeek("DimePrice")
        const dates = Object.keys(oneyearvalue);
        setOneyeardates1(dates)
        const prices = Object.values(oneyearvalue);
        setOneyearprices1(prices)
      }
      useEffect(()=>{getallvalues1()},[])
      const getallvalues2 = async() =>{
        let oneyearvalue = await getPriceOneWeek("CreditPrice")
        const dates = Object.keys(oneyearvalue);
        setOneyeardates2(dates)
        const prices = Object.values(oneyearvalue);
        setOneyearprices2(prices)
      }
      useEffect(()=>{getallvalues2()},[])
      const getallvalues3 = async() =>{
        let oneyearvalue = await getPriceOneWeek("TauPrice")
        const dates = Object.keys(oneyearvalue);
        setOneyeardates3(dates)
        const prices = Object.values(oneyearvalue);
        setOneyearprices3(prices)
      }
      useEffect(()=>{getallvalues3()},[])
    const getallvalue = async() =>{
        let jprice =await getJokerPrice()
        setJokerPrice(jprice);
        let dprice =await getDimePrice()
        setDimePrice(dprice);
        let cprice =await getCreditsPrice()
        setCreditsPrice(cprice);
        let tprice =await getTAUPrice()
        setTauPrice(tprice);
        setmarketcapJoker(await getJokerSupply()*jprice)
        setmarketcapDime(await getDimeSupply()*dprice)
        setmarketcapCredits(await getCreditsSupply()*cprice)
        setmarketcapTau(await getTauSupply()*tprice)
        console.log("marketcap",await getJokerSupply())
    }
    useEffect(() =>{getallvalue()},[])

    const getHourBasedvalues = async() =>{
        setjoker1hour(await gepriceIncrease("JokerPrice","1hour"));
        setjoker24Hour(await gepriceIncrease("JokerPrice","24hours"));
        setjoker1Month(await gepriceIncrease("JokerPrice","1month"));
    }
    useEffect(() =>{getHourBasedvalues()},[])

    const getDimeHourBasedvalues = async() =>{
        setDime1hour(await gepriceIncrease("DimePrice","1hour"));
        setDime24Hour(await gepriceIncrease("DimePrice","24hours"));
        setDime1Month(await gepriceIncrease("DimePrice","1month"));
    }
    useEffect(() =>{getDimeHourBasedvalues()},[])

    const getCreditHourBasedvalues = async() =>{
        setCredit1hour(await gepriceIncrease("CreditPrice","1hour"));
        setCredit24Hour(await gepriceIncrease("CreditPrice","24hours"));
        setCredit1Month(await gepriceIncrease("CreditPrice","1month"));
    }
    useEffect(() =>{getCreditHourBasedvalues()},[])

    const getTauHourBasedvalues = async() =>{
        setTau1hour(await gepriceIncrease("TauPrice","1hour"));
        setTau24Hour(await gepriceIncrease("TauPrice","24hours"));
        setTau1Month(await gepriceIncrease("TauPrice","1month"));
    }
    useEffect(() =>{getTauHourBasedvalues()},[])

    const getAssetValues = async() => {
        await recordUserVisits(address, "Analytics");
            let daiBalanceT = await getTokenBalance(ECO_Reserve_Treasury_Address,TAU_Token_Address,TAU_Token_ABI);
            setdaiBalanceT(daiBalanceT);
            let jokerBalanceT = await getTokenBalance(ECO_Reserve_Treasury_Address,JOKER_Token_Address,JOKER_Token_ABI);
            setjokerBalanceT(jokerBalanceT);
            let dimeBalanceT = await getTokenBalance(ECO_Reserve_Treasury_Address,DIME_Token_Address,DIME_Token_ABI);
            setdimeBalanceT(dimeBalanceT);
            let creditsBalanceT = await getTokenBalance(ECO_Reserve_Treasury_Address,CREDITS_Token_Address,CREDITS_Token_ABI);
            setcreditsBalanceT(creditsBalanceT);
            // handleOptionChange("price");
      }
      useEffect(() =>{getAssetValues();},[address])

      const gettxnhistory = async() =>{
        let txndata = await retrieveTxnByPage(1,page);
        settxndata(txndata);
      }
      useEffect(() =>{gettxnhistory()},[])


      const formatEntryTime = (timestamp) => {
        const currentTime = new Date().getTime();
        const timeDifference = (currentTime - timestamp * 1000) / 1000;
    
        const secondsAgo = Math.floor(timeDifference);
        const minutesAgo = Math.floor(timeDifference / 60);
        const hoursAgo = Math.floor(timeDifference / 3600);
        const daysAgo = Math.floor(timeDifference / (3600 * 24));
    
        if (daysAgo > 0) {
            return `${daysAgo} day${daysAgo !== 1 ? 's' : ''} ago`;
        } else if (hoursAgo > 0) {
            return `${hoursAgo} hour${hoursAgo !== 1 ? 's' : ''} ago`;
        } else if (minutesAgo > 0) {
            return `${minutesAgo} minute${minutesAgo !== 1 ? 's' : ''} ago`;
        } else {
            return `${secondsAgo} second${secondsAgo !== 1 ? 's' : ''} ago`;
        }
    };
    

    const Pagination = async(index)=>{
        setstartindex(index);
        let txndata = await retrieveTxnByPage(index,page);
        settxndata(txndata);
    }
    return (  
        <LayoutAfterLogin menuActive={"analytics"} balances={balances}>
            <div className="p-24 app-contain">
                <h3>Price History</h3>
                
                <Table responsive striped hover size="sm">
                    <thead>
                        <tr>
                            <th className="text-center"><div>#</div></th>
                            <th><div>Name</div></th>
                            <th><div>Price</div></th>
                            <th><div>1h%</div></th>
                            <th><div>24h%</div></th>
                            <th><div>1M%</div></th>
                            <th className="text-center"><div className="sort">Market Cap</div></th>
                            <th className="text-center"><div className="sort">Treasury Volume</div></th>
                            <th className="text-center"><div className="sort">Circulationg Supply</div></th>
                            <th className="text-center"><div>Last 7 days</div></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="text-center">1</td>
                            <td>
                                <div className="d-flex align-items-center">
                                    <img src={JokerIcon} alt="JokerIcon" />
                                    JOKER
                                </div>
                            </td>
                            <td>${JokerPrice?parseFloat(JokerPrice/1e8).toFixed(3):'0.0'}</td>
                            <td>{parseFloat(joker1hour) === Infinity ? '0' : (parseFloat(joker1hour) > 0 ? parseFloat(joker1hour).toFixed(2) : '0')}%</td>

                            <td>{joker24Hour?parseFloat(joker24Hour).toFixed(2):'0'}%</td>
                            <td>{joker1Month?parseFloat(joker1Month).toFixed(2):'0'}%</td>
                            <td><center>$<NumberAbbreviation number={marketcapJoker?parseFloat(marketcapJoker/1e17).toFixed(2):'0.0'}/></center></td>
                            <td className="text-center"><div className="d-inline-block text-end">
                                <NumberAbbreviation number={jokerBalanceT?parseFloat(jokerBalanceT/1e9).toFixed(2):'0.0'} />
                            {/* <small className="d-block">$23,524,56904</small> */}
                            </div></td>
                            <td className="text-end">
                               
                            <NumberAbbreviation number={marketcapJoker?parseFloat((marketcapJoker/JokerPrice)/1e9).toFixed(2):'0.0'}/>
                                <ProgressBar style={{height: '5px'}} className="mt-1" variant="white" now={80} />
                            </td>
                            <td className="text-center">
                                <div className="sc-chart">
                                {/* <LineChart color="#45B68D" labels={labels} prices={data} /> */}
                                   <LineChart color={joker1Month < 0 ? "#EE6A5F" : "#45B68D"} labels={Oneyeardates?Oneyeardates:'0'} prices={Oneyearprices?Oneyearprices:'0'} name="Joker"/>
                                 
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center">2</td>
                            <td>
                                <div className="d-flex align-items-center">
                                    <img src={Icon2} alt="JokerIcon" />
                                    DIME
                                </div>
                            </td>
                            <td>${DimePrice?parseFloat(DimePrice/1e8).toFixed(3):'0.0'}</td>
                            <td>{parseFloat(dime1hour) === Infinity ? '0' : (parseFloat(dime1hour) > 0 ? parseFloat(dime1hour).toFixed(2) : '0')}%</td>

                            <td>{parseFloat(dime24hour) ?parseFloat(dime24hour).toFixed(2):'0'}%</td>
                            <td>{dime1month?parseFloat(dime1month).toFixed(2):'0'}%</td>
                            <td><center>$<NumberAbbreviation number={marketcapDime?parseFloat(marketcapDime/1e17).toFixed(2):'0.0'}/></center></td>
                            <td className="text-center"><div className="d-inline-block text-end"><NumberAbbreviation number={dimeBalanceT?parseFloat(dimeBalanceT/1e9).toFixed(2):'0.0'}/> 
                            {/* <small className="d-block">$23,524,56904</small> */}
                            </div></td>
                            <td className="text-end">
                         
                            <NumberAbbreviation number= {marketcapDime?parseFloat((marketcapDime/DimePrice)/1e9).toFixed(2):'0.0'}/>
                                <ProgressBar style={{height: '5px'}} className="mt-1" variant="white" now={65} />
                            </td>
                            <td className="text-center">
                                <div className="sc-chart">
                                    <LineChart color={dime1month < 0 ? "#EE6A5F" : "#45B68D"} labels={Oneyeardates1?Oneyeardates1:'0'} prices={Oneyearprices1?Oneyearprices1:'0'} name="Dime"/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center">3</td>
                            <td>
                                <div className="d-flex align-items-center">
                                    <img src={Icon3} alt="JokerIcon" />
                                    CREDITS
                                </div>
                            </td>
                            <td>${CreditsPrice?parseFloat(CreditsPrice/1e8).toFixed(3):'0.0'}</td>
                            <td>{parseFloat(credit1hour) === Infinity ? '0' : (parseFloat(credit1hour) > 0 ? parseFloat(credit1hour).toFixed(2) : '0')}%</td>

                            <td>{credit24hour?parseFloat(credit24hour).toFixed(2):'0'}%</td>
                            <td>{credit1month?parseFloat(credit1month).toFixed(2):'0'}%</td>
                            <td><center>$<NumberAbbreviation number={marketcapCredits?parseFloat(marketcapCredits/1e26).toFixed(2):'0.0'}/></center></td>
                            <td className="text-center"><div className="d-inline-block text-end">
                                <NumberAbbreviation number={creditsBalanceT?parseFloat(creditsBalanceT/1e18).toFixed(2):'0.0'} />
                            {/* <small className="d-block">$23,524,56904</small> */}
                            </div></td>
                            <td className="text-end">
                            
                            <NumberAbbreviation number={marketcapCredits?parseFloat((marketcapCredits/CreditsPrice)/1e18).toFixed(2):'0.0'}/>
                                <ProgressBar style={{height: '5px'}} className="mt-1" variant="white" now={55} />
                            </td>
                            <td className="text-center">
                                <div className="sc-chart">
                                    <LineChart color={credit1month < 0 ? "#EE6A5F" : "#45B68D"} labels={Oneyeardates2?Oneyeardates2:'0'} prices={Oneyearprices2?Oneyearprices2:'0'} name="Credits"/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center">4</td>
                            <td>
                                <div className="d-flex align-items-center">
                                    <img src={tauIcon} alt="JokerIcon" />
                                    TAU
                                </div>
                            </td>
                            <td>${TAUPrice?parseFloat(TAUPrice/1e8).toFixed(3):'0.0'}</td>
                            <td>{parseFloat(tau1hour) === Infinity ? '0' : (parseFloat(tau1hour) > 0 ? parseFloat(tau1hour).toFixed(2) : '0')}%</td>

                            <td>{tau24hour?parseFloat(tau24hour).toFixed(2):'0'}%</td>
                            <td>{tau1month?parseFloat(tau1month).toFixed(2):'0'}%</td>
                            <td><center>$<NumberAbbreviation number={marketcapTau?parseFloat(marketcapTau/1e17).toFixed(2):'0.0'}/></center></td>
                            <td className="text-center"><div className="d-inline-block text-end"><NumberAbbreviation number={daiBalanceT?parseFloat(daiBalanceT/1e9).toFixed(2):'0.0'} />
                            {/* <small className="d-block">$23,524,56904</small> */}
                            </div></td>
                            <td className="text-end">
                          
                            <NumberAbbreviation number={marketcapTau?parseFloat((marketcapTau/TAUPrice)/1e9).toFixed(2):'0.0'}/>
                                <ProgressBar style={{height: '5px'}} className="mt-1" variant="white" now={35} />
                            </td>
                            <td className="text-center">
                                <div className="sc-chart">
                                    <LineChart color={tau1month < 0 ? "#EE6A5F" : "#45B68D"} labels={Oneyeardates3?Oneyeardates3:'0'} prices={Oneyearprices3?Oneyearprices3:'0'} name="Tau"/>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </Table>

                <hr />

                <h3>Transaction History</h3>
                <div className="mb-4">
                    <Table responsive striped hover>
                        <thead>
                            <tr>
                                <th><div className="sort">Token</div></th>
                                <th><div className="sort">Txn Hash</div></th>
                                <th><div className="sort">Type</div></th>
                                <th><div className="sort">From</div></th>
                                <th><div className="sort">To</div></th>
                                {/* <th><div className="sort float-end">Contract</div></th> */}
                                <th><div className="sort float-end">Created</div></th>
                            </tr>
                        </thead>
                        <tbody>
                           {txndata[0] ? (
    txndata.map((transaction, index) => (
        <tr key={index}>
            <td>
                <div className="d-flex align-items-center">
                    <img src={transaction.tokenNmae ==="Joker"? JokerIcon :transaction.tokenNmae ==="JOKER"? JokerIcon: transaction.tokenNmae ==="Dime" ? Icon2:transaction.tokenNmae ==="DIME" ? Icon2: transaction.tokenNmae ==="CREDIT" ? Icon3 :transaction.tokenNmae ==="Credit" ? Icon3 : 
                    transaction.tokenNmae ==="Credits" ? Icon3 :transaction.tokenNmae ==="CREDITS" ? Icon3:
                    transaction.tokenNmae === "Tau" ? tauIcon : DollerLogo} alt="Transfer" />
                    {transaction.tokenNmae}
                </div>
            </td>
            <td><button type="button" className="btn-link"><a href={`https://sepolia.etherscan.io/tx/${transaction.txnHash}`} target="_black" rel="noreferrer">{transaction.txnHash.substring(0, 4)}...{transaction.txnHash.slice(-4)}</a></button></td>
            <td>{transaction.Type}</td>
            <td><button type="button" className="btn-link"><a href={`https://sepolia.etherscan.io/address/${transaction.From}`} target="_black" rel="noreferrer">{transaction.From.substring(0, 4)}...{transaction.From.slice(-4)}</a></button></td>
            <td><button type="button" className="btn-link"><a href={`https://sepolia.etherscan.io/address/${transaction.To}`} target="_black" rel="noreferrer">{transaction.To.substring(0, 4)}...{transaction.To.slice(-4)}</a></button></td>
            <td className="text-end">{formatEntryTime(transaction.entryTime)}</td>
        </tr>
    ))
) : (
    <></>
)}

                           
                            {/* <tr>
                                <td>
                                    <div className="d-flex align-items-center">
                                        <img src={TransferIcon} alt="Transfer" />
                                        Transfer
                                    </div>
                                </td>
                                <td><button type="button" className="btn-link">0xba...atwd</button></td>
                                <td>28083682</td>
                                <td><button type="button" className="btn-link">0xPa...qe2e</button></td>
                                <td><button type="button" className="btn-link">0xd5...h43e</button></td>
                                <td className="text-end">0</td>
                                <td className="text-end">2 minutes ago</td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="d-flex align-items-center">
                                        <img src={TransferIcon} alt="Transfer" />
                                        Transfer
                                    </div>
                                </td>
                                <td><button type="button" className="btn-link">0xba...atwd</button></td>
                                <td>28083682</td>
                                <td><button type="button" className="btn-link">0xPa...qe2e</button></td>
                                <td><button type="button" className="btn-link">0xd5...h43e</button></td>
                                <td className="text-end">0</td>
                                <td className="text-end">2 minutes ago</td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="d-flex align-items-center">
                                        <img src={TransferIcon} alt="Transfer" />
                                        Transfer
                                    </div>
                                </td>
                                <td><button type="button" className="btn-link">0xba...atwd</button></td>
                                <td>28083682</td>
                                <td><button type="button" className="btn-link">0xPa...qe2e</button></td>
                                <td><button type="button" className="btn-link">0xd5...h43e</button></td>
                                <td className="text-end">0</td>
                                <td className="text-end">2 minutes ago</td>
                            </tr> */}
                        </tbody>
                    </Table>
                </div>
                
                <div className="d-flex justify-content-md-end justify-content-center">
                    <ul className="paginate list-unstyled">
                        <li>
                            <button onClick={()=>Pagination(startIndex-1)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
                                </svg>
                            </button>
                        </li>
                        <li><a className='active'>{startIndex?startIndex:'0'}</a></li>
                        {/* <li><a href="/#">2</a></li>
                        <li><a href="/#">3</a></li>
                        <li><a href="/#">4</a></li>
                        <li><a href="/#">...</a></li>
                        <li><a href="/#">11</a></li> */}
                        <li>
                            <button onClick={()=>Pagination(startIndex+1)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                                </svg>
                            </button>
                        </li>
                    </ul>
                </div>

            </div>

        </LayoutAfterLogin>
    );
}

export default Analytics;