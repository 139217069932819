import { Form } from "react-bootstrap";
import { AreaChart } from "./AreaChart";
import { API_KEY, CHAIN_URL, CREDITS_Token_ABI, CREDITS_Token_Address, Network_Name } from "../../../abi/ABI&ContractAddress";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { getCirculatingSupplyOneDay, getCirculatingsupplyOneMonth, getCirculatingsupplyOneWeek, getCirculatingsupplyOneYear, getMarketcapOneDay, getMarketcapOneMonth, getMarketcapOneWeek, getMarketcapOneYear, getPriceOneDay, getPriceOneMonth, getPriceOneWeek, getPriceOneYear, updatecreditPrice, updatetauPrice } from "../../../abi/firebasecode";
import { NumberAbbreviation, getCreditsPrice, getCreditsSupply, getJokerPrice, getTAUPrice, getTauSupply } from "../../../abi/CommonSolFunctions";
import creditsLogo from '../../../asserts/IMAGES/creditsBlackLogo.svg';
function CreditsCard() {

  const provider = ethers.getDefaultProvider(Network_Name, {
    etherscan: API_KEY});
    const JOKERContract = new ethers.Contract(CREDITS_Token_Address, CREDITS_Token_ABI, provider);

    const [Oneyearvalue, setOneyearvalue] = useState(0);
    const [Oneyeardates, setOneyeardates] = useState(0);
    const [Oneyearprices, setOneyearprices] = useState(0);
    
    const [activeButton, setActiveButton] = useState('1Y');
    const [jokerPrice, setjokerPrice] = useState("");
    const [TotalSupply, setTotalSupply] = useState("");
    const [title, setTitle] = useState("price");

    const getallvalues = async() =>{
        let oneyearvalue = await getPriceOneYear("CreditPrice")
        setOneyearvalue(oneyearvalue)
        const dates = Object.keys(oneyearvalue);
        setOneyeardates(dates)
        const prices = Object.values(oneyearvalue);
        
        setOneyearprices(prices)

        setjokerPrice(await getCreditsPrice())
        setTotalSupply(await JOKERContract.totalSupply())


    }
    useEffect(() =>{getallvalues();},[])
    

    // Function to handle button click
    const handleButtonClick = async(value) => {
      setActiveButton(value);
      if(value == '1W'){
        let oneyearvalue = await getPriceOneWeek("CreditPrice")
        setfunction(oneyearvalue)
      }else if(value == '1M'){
        let oneyearvalue = await getPriceOneMonth("CreditPrice")
        setfunction(oneyearvalue)

      }else if(value == '1Y'){
        let oneyearvalue = await getPriceOneYear("CreditPrice")
        setfunction(oneyearvalue)
      }else{
        let oneyearvalue = await getPriceOneDay("CreditPrice")
        setfunction(oneyearvalue)
      }

    };
    const handleButtonClicksupply = async(value) => {
        setActiveButton(value);
        if(value == '1W'){
          let oneyearvalue = await getCirculatingsupplyOneWeek("CreditPrice")
          setfunction(oneyearvalue)
        }else if(value == '1M'){
          let oneyearvalue = await getCirculatingsupplyOneMonth("CreditPrice")
          setfunction(oneyearvalue)
  
        }else if(value == '1Y'){
          let oneyearvalue = await getCirculatingsupplyOneYear("CreditPrice")
          setfunction(oneyearvalue)
        }else{
          let oneyearvalue = await getCirculatingSupplyOneDay("CreditPrice")
          setfunction(oneyearvalue)
        }
  
      };

      const handleButtonClickMarketcap = async(value) => {
        setActiveButton(value);
        if(value == '1W'){
          let oneyearvalue = await getMarketcapOneWeek("CreditPrice")
          setfunction(oneyearvalue)
        }else if(value == '1M'){
          let oneyearvalue = await getMarketcapOneMonth("CreditPrice")
          setfunction(oneyearvalue)
  
        }else if(value == '1Y'){
          let oneyearvalue = await getMarketcapOneYear("CreditPrice")
          setfunction(oneyearvalue)
        }else{
          let oneyearvalue = await getMarketcapOneDay("CreditPrice")
          setfunction(oneyearvalue)
        }
  
      };

    const callselectedFunction = async(value) =>{
        console.log("selected function",title)
        if(title === "price"){
            handleButtonClick(value)
        }else if(title === "supply"){
            handleButtonClicksupply(value)

        }else if(title === "market"){
            handleButtonClickMarketcap(value)
        }

    }

    const setfunction = async(oneyearvalue) => {
        const dates = Object.keys(oneyearvalue);
        setOneyeardates(dates)
        const prices = Object.values(oneyearvalue);
        setOneyearprices(prices)
    }

    const OptionCall = async(titlevalue) =>{
        console.log("titlevalue", titlevalue)
        setTitle(titlevalue)
        setActiveButton('1Y')
        if(titlevalue === "price"){
            let oneyearvalue = await getPriceOneYear("CreditPrice")
            setOneyearvalue(oneyearvalue)
            const dates = Object.keys(oneyearvalue);
            setOneyeardates(dates)
            const prices = Object.values(oneyearvalue); 
            setOneyearprices(prices)

        }else if(titlevalue === "supply"){
            console.log("titlevalue",titlevalue)
            let oneyearvalue = await getCirculatingsupplyOneYear("CreditPrice")
            setOneyearvalue(oneyearvalue)
            const dates = Object.keys(oneyearvalue);
            setOneyeardates(dates)
            const prices = Object.values(oneyearvalue); 
            setOneyearprices(prices)
        }
        else if(titlevalue === "market"){
            let oneyearvalue = await getMarketcapOneYear("CreditPrice")
            setOneyearvalue(oneyearvalue)
            const dates = Object.keys(oneyearvalue);
            setOneyeardates(dates)
            const prices = Object.values(oneyearvalue); 
            setOneyearprices(prices)
        }
    }
    const pushPrice = async() =>{
      let jokerprice = await getCreditsPrice();
      let circulatingSupply = await getCreditsSupply();
      let currentTime = (new Date().getTime())/1000;
      await updatecreditPrice((jokerprice/1e8),currentTime,(circulatingSupply/1e18));
      // setOneyearvalue(oneyearvalue)
    }
    useEffect(() =>{pushPrice();},[])

    const pushPrice1 = async() =>{
      let jokerprice = await getTAUPrice();
      let circulatingSupply = await getTauSupply();
      let currentTime = (new Date().getTime())/1000;
      await updatetauPrice((jokerprice/1e8),currentTime,(circulatingSupply/1e9));
      // setOneyearvalue(oneyearvalue)
    }
    useEffect(() =>{pushPrice1();},[])

    return ( 
        <div className="box">
            <div className="p-3 d-flex align-items-center flex-warp">
                {/* <h4 className="mb-0">Credits</h4> */}
                <img src={creditsLogo} height={'35px'}  style={{minWidth: '35px'}} alt="jokerLogo" />

                <div className="chart-filter d-flex align-items-center ms-auto">
                <button
                    className={activeButton === '1D' ? 'active' : ''}
                    onClick={() => callselectedFunction('1D')} >
                    1D
                    </button>
                    <button
                    className={activeButton === '1W' ? 'active' : ''}
                    onClick={() => callselectedFunction('1W')} >
                    1W
                    </button>
                    <button
                    className={activeButton === '1M' ? 'active' : ''}
                    onClick={() => callselectedFunction('1M')} >
                    1M
                    </button>
                    <button
                    className={activeButton === '1Y' ? 'active' : ''}
                    onClick={() => callselectedFunction('1Y')} >
                    1Y
                    </button>
                </div>
            </div>

            <div className="chart-header d-flex align-items-center  flex-wrap">
            <div>
                    <h6>Price</h6>
                    <h5>${jokerPrice?parseFloat(jokerPrice/1e8).toFixed(2):'0.0'}</h5>
                </div>
                <div>
                    <h6>Supply</h6>
                    <h5><NumberAbbreviation number={TotalSupply?parseFloat((TotalSupply)/1e18).toFixed(2):'0.0'}/></h5>
                </div>
                <div>
                    <h6>Market Cap</h6>
                    <h5>$<NumberAbbreviation number={jokerPrice?parseFloat((jokerPrice*TotalSupply)/1e26).toFixed(2):'0.0'}/></h5>
                </div>

                <div className="border-0">
                <Form.Select size="lg" onChange={(e) => OptionCall(e.target.value)}>
                    <option value="price">Price</option>
                    <option value="supply">CirculatingSupply</option>
                    <option value="market">Marketcap</option>
                </Form.Select>
                </div>
            </div>

            <div className="p-3">
            <AreaChart dates={Oneyeardates?Oneyeardates:'0'} prices={Oneyearprices?Oneyearprices:'0'} name={"CREDITS"} checkvalue={title} />
            </div>
        </div>
     );
}

export default CreditsCard;