import { Col, Row, Table } from "react-bootstrap";
import LayoutAfterLogin from "../../component/LayoutAL";
import Tooltip from "../../Snippets/Tooltip";
import Timer from "../Dashboard/Snippets/Timer";
import SwapBtn from '../../asserts/IMAGES/add-icon.svg';
import Icon1 from '../../asserts/IMAGES/jokerRedLogo.svg';
import Icon2 from '../../asserts/IMAGES/dimeCyanLogo.svg';
import DaiIcon from '../../asserts/IMAGES/dai.svg';
import DollerIcon from '../../asserts/IMAGES/doller-icon.svg';
import Joker from "../../asserts/IMAGES/joker-icon.svg";
import Upload from "../../asserts/IMAGES/UploadIcon.svg";
import ProgressBar from "../Dashboard/Snippets/CircleTimer";
import TransactionPending from '../../asserts/IMAGES/transsationPending.svg';
import { useEffect,useState } from "react";
import { Link } from "react-router-dom";

import { CheckAllowance, PendingModal, SuccessModal, getGasBalance, getTokenBalance, convertEpochToDateTime } from "../../abi/CommonSolFunctions";
import { CHAIN_URL, JOKER_Token_ABI, JOKER_Token_Address, TXN_ID,  Chainlink_Oracle_ABI, DAI_TOKEN_ABI, DAI_TOKEN_Address, DIME_Bond_ABI, DIME_Bond_Address, DIME_Chainlink_Oracle_Address, DIME_TREASURY_ABI, DIME_TREASURY_Address, DIME_Token_ABI, DIME_Token_Address, JOKER_Chainlink_Oracle_Address, Network_Name, API_KEY, DAI_Chainlink_Oracle_Address } from "../../abi/ABI&ContractAddress";
import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers5/react";
import { ethers } from "ethers";
// import BigInt from 'big-integer';
import { createTxn, recordUserVisits } from "../../abi/firebasecode";
/* global BigInt */

function Bond({balances, balanceOfTokens}) {

    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
    const provider = ethers.getDefaultProvider(Network_Name, {
        etherscan: API_KEY});

    const[loader, setLoader] = useState(false);
    const handleShowLoad = () => setLoader(true);
    const handleHideLoad = () => setLoader(false);

    const [showTr, setShowTr] = useState(false);
    const [pending, setPending] = useState("");
    const [txId, setTxId] = useState("");
    const [TxnImage, setTxnImage] = useState("");
    const [errormsg, seterrormsg] = useState("");

    const handleCloseTransation = () => setShowTr(false);
    const handleShowTransation = () => setShowTr(true);

    const[allowan,setAllowance] = useState("")
    const[allowan2,setAllowance2] = useState("")
    const[daiBalance,setdaiBalance] = useState("")
    const[JokerBalance,setJokerBalance] = useState("");
    const[dimePrice,setdimePrice] = useState("")
    const[daiPrice,setdaiPrice] = useState("")
    const[jokerPrice,setjokerPrice] = useState("")
    const[bondPrice,setbondPrice] = useState("")
    const [activeBonds,setActiveBonds] = useState([]);
    const [bondInfo,setBondInfo] = useState([]);
    const[TreasuryDollarvalue,setTreasuryDollarvalue] = useState("")
    const[TreasuryJokerBal,setTreasuryJokerBal] = useState("")
    const[TreasuryDaiBal,setTreasuryDaiBal] = useState("")
    const[TreasuryDimeBal,setTreasuryDimeBal] = useState("")
    const [bondAmount, setBondAmount] = useState(0.00);
    const[JokerInput,setJokerInput] = useState("");
    const [selectedBond, setSelectedBond] = useState("");
    const [bondCount, setBondCount] = useState("");
    const [contractRatio, setContractRatio] = useState("");
    const [payout, setPayout] = useState(0);
    const [possiblePayout, setPossiblePayout] = useState(0.00);
    const [vesting, setVesting] = useState(0);
    const [percentVested, setPercentVested] = useState(0);
    const [creationTimeBond, setCreationTimeBond] = useState(0);
    const[claimableRewards, setClaimableRewards] = useState(0);
    const[maxPayout, setmaxPayout] = useState("");

    const getAssetDetails = async() =>{
        await recordUserVisits(address, "Bond");
        if(isConnected){
          console.log("useeffect");

        const DimePriceContract = new ethers.Contract(DIME_Chainlink_Oracle_Address, Chainlink_Oracle_ABI, provider);
        const JokerPriceContract = new ethers.Contract(JOKER_Chainlink_Oracle_Address, Chainlink_Oracle_ABI, provider);
        const DaiPriceContract = new ethers.Contract(DAI_Chainlink_Oracle_Address, Chainlink_Oracle_ABI, provider);
        const daiContract = new ethers.Contract(DAI_TOKEN_Address, DAI_TOKEN_ABI, provider);
        const TreasuryContract = new ethers.Contract(DIME_TREASURY_Address, DIME_TREASURY_ABI, provider);
        const JOKERContract = new ethers.Contract(JOKER_Token_Address, JOKER_Token_ABI, provider);
        const DimeBondContract = new ethers.Contract(DIME_Bond_Address, DIME_Bond_ABI, provider);
        let dimeprice = ethers.utils.formatUnits(await DimePriceContract.getChainlinkDataFeedLatestAnswer(),0);
        setdimePrice(dimeprice);
        let jokerprice = ethers.utils.formatUnits(await JokerPriceContract.getChainlinkDataFeedLatestAnswer(),0);
        setjokerPrice(jokerprice);
        let daiprice = ethers.utils.formatUnits(await DaiPriceContract.getChainlinkDataFeedLatestAnswer(),0);
        setdaiPrice(daiprice);

        let collateral_ratio = await DimeBondContract.global_collateral_ratio();
        setContractRatio(collateral_ratio / 1e4);

        let bondPrice = ethers.utils.formatUnits(await DimeBondContract.bondPrice(), 0);
        let bondPriceinUSD = ethers.utils.formatUnits(await DimeBondContract.getDimeInDai(bondPrice),0);
        setbondPrice(bondPriceinUSD);
        console.log("bond price:",bondPrice);
        
        let allowance =  ethers.utils.formatUnits(await daiContract.allowance(address,DIME_Bond_Address),0);
        console.log("allowance", allowance)
        setAllowance(allowance);
        let allowance2 =  ethers.utils.formatUnits(await JOKERContract.allowance(address,DIME_Bond_Address),0);
        console.log("allowance2", allowance2)
        setAllowance2(allowance2);

        let daibalance1 = ethers.utils.formatUnits(await daiContract.balanceOf(address),0);
          setdaiBalance(daibalance1)  
          let Jokerblance1 = ethers.utils.formatUnits(await JOKERContract.balanceOf(address),0);
          setJokerBalance(Jokerblance1)  

        let maxpayout = ethers.utils.formatUnits(await DimeBondContract.maxPayout(),0);
        setmaxPayout(maxpayout);    
        
        let treasurBalance1 = ethers.utils.formatUnits(await TreasuryContract.totalReserves(),0);
        let treasurBalanceinDai = ethers.utils.formatUnits(await DimeBondContract.getDimeInDai(treasurBalance1),0);
        // let treasurBalanceinDai = ( (treasueryJokerBal/1e9) * jokerprice) + ( (treasueryDaiBal/1e18) * daiprice) + ( (treasueryDimeBal/1e9) * dimeprice);
        setTreasuryDollarvalue((treasurBalanceinDai/1e18) * daiprice);

        let treasueryJokerBal = await getTokenBalance(DIME_TREASURY_Address, JOKER_Token_Address, JOKER_Token_ABI);
        setTreasuryJokerBal(treasueryJokerBal);
        let treasueryDaiBal = await getTokenBalance(DIME_TREASURY_Address, DAI_TOKEN_Address, DAI_TOKEN_ABI);
        setTreasuryDaiBal(treasueryDaiBal);
        let treasueryDimeBal = await getTokenBalance(DIME_TREASURY_Address, DIME_Token_Address, DIME_Token_ABI);
        setTreasuryDimeBal(treasueryDimeBal);
        console.log( "bal:",treasueryJokerBal / 1e9, treasueryDaiBal / 1e18); 
        await getBondInfo();
      }
    }

    const getBondInfo = async() => {
        const DimeBondContract = new ethers.Contract(DIME_Bond_Address, DIME_Bond_ABI, provider);
        let userbonds = await DimeBondContract.getActiveBonds(address);
        setActiveBonds(userbonds);
        let bondInfo1 = [];
        console.log(" active bonds:", userbonds);
        
            for (const bond of userbonds) {
                console.log("new bond:");
                // Access properties of each bond object
                // let bondresult = await bonfInfo(bond._hex);
                bondInfo1.push(await bonfInfo(bond._hex));
                // Add more properties as needed
            }
            setBondInfo(bondInfo1);
            console.log("bonds:", bondInfo1);
    }
    const bonfInfo = async(bondNumber) => {
        const DimeBondContract = new ethers.Contract(DIME_Bond_Address, DIME_Bond_ABI, provider);
        const bigNumberObject = ethers.BigNumber.from(bondNumber);
        const result = await DimeBondContract.bondInfo(address, bigNumberObject.toNumber());
        console.log(" bondInfo:", result);
        return result;
    }

    const approveJoker = async() =>{
        if(isConnected){
        try{
            const ethersProvider =  new ethers.providers.Web3Provider(walletProvider)
            const signer =  ethersProvider.getSigner()
            const JokerContract = new ethers.Contract(JOKER_Token_Address, JOKER_Token_ABI, signer);
            
            await showPopUp("yes",TransactionPending,"","");
            let givenAmount = ethers.utils.parseUnits((548949849846516854849848651651468848451n).toString(), 0)
            const approveTx = await JokerContract.approve(DIME_Bond_Address,givenAmount);
            await approveTx.wait();
          
            console.log("minttx",approveTx.hash);
            await getAssetDetails();
            await TxnId_PopUp(approveTx.hash);
            // await TxnId_PopUp("hash");
        }catch(err){
            showPopUp("not",TransactionPending,"",(err.reason).toString());
            console.log("error",err)
        }
      }
    }

    const approveDai = async() =>{
        if(isConnected){
        try{
            const ethersProvider =  new ethers.providers.Web3Provider(walletProvider)
            const signer =  ethersProvider.getSigner()
            const DaiContract = new ethers.Contract(DAI_TOKEN_Address, DAI_TOKEN_ABI, signer);
            
            await showPopUp("yes",TransactionPending,"","");
            let givenAmount = ethers.utils.parseUnits((548949849846516854849848651651468848451n).toString(), 0)
            const approveTx = await DaiContract.approve(DIME_Bond_Address,givenAmount);
            await approveTx.wait();
          
            console.log("minttx",approveTx.hash);
            await getAssetDetails();
            await TxnId_PopUp(approveTx.hash);
            // await TxnId_PopUp("hash");
        }catch(err){
            showPopUp("not",TransactionPending,"",(err.reason).toString());
            console.log("error",err)
        }
      }
    }

    const purchaseBond = async() =>{
        if(isConnected){
        try{
            const ethersProvider =  new ethers.providers.Web3Provider(walletProvider)
            const signer =  ethersProvider.getSigner()
            const DimeBondContract = new ethers.Contract(DIME_Bond_Address, DIME_Bond_ABI, signer);
            
            await showPopUp("yes",TransactionPending,"","");
            // let givenAmount = ethers.BigNumber.from(bondAmount / 1e18 ).mul(ethers.BigNumber.from(10).pow(18));
            let givenAmount = ethers.utils.parseEther((bondAmount).toString());
            const bondTx = await DimeBondContract.deposit(givenAmount,1000, address);
            await bondTx.wait();
          
            console.log("minttx",bondTx.hash);
            await getAssetDetails();
            resetState();
            await TxnId_PopUp(bondTx.hash);
            await balanceOfTokens();
            await createTxn("Dime",bondTx.hash,"Bond Purchase",address,DIME_Bond_Address);
            // await TxnId_PopUp("hash");
        }catch(err){
            showPopUp("not",TransactionPending,"",(err.reason).toString());
            console.log("error",err)
        }
      }
    }

    const claimReward = async() =>{
        if(isConnected){
        try{
            const ethersProvider =  new ethers.providers.Web3Provider(walletProvider)
            const signer =  ethersProvider.getSigner()
            const DimeBondContract = new ethers.Contract(DIME_Bond_Address, DIME_Bond_ABI, signer);
            
            await showPopUp("yes",TransactionPending,"","");
            const claimTx = await DimeBondContract.redeem(address,bondCount);
            await claimTx.wait();
          
            console.log("minttx",claimTx.hash);
            await handleSelectChange(selectedBond, bondCount + 1);
            await TxnId_PopUp(claimTx.hash);
            // await TxnId_PopUp("hash");
            await createTxn("Dime",claimTx.hash,"Bond Claim",address,DIME_Bond_Address);
        }catch(err){
            showPopUp("not",TransactionPending,"",(err.reason).toString());
            console.log("error",err)
        }
      }
    }

    const TxnId_PopUp =async(hash) =>{
        let txnid = TXN_ID+hash;
        showPopUp("no",TransactionPending,txnid,"");
        await getAssetDetails();
    }

    const showPopUp = async(pendingvalue,imgSrc,txid,errormsg)=>{
        setPending(pendingvalue);
        setTxId(txid);
        setTxnImage(imgSrc);
        seterrormsg(errormsg);    
        handleShowTransation();        
    }

    const changeInputValue = async(value) => {
        //     // Convert the input value to a float
        // let value = parseFloat(value1);
        
        // Check if the input value is a valid number
        if (value === "" || value === null || value === NaN || value === "." || isNaN(value) || hasMoreThan18Decimals(value)) {
            // If not a valid number, set the bondAmount state to 0
            resetState();
            return; // Exit the function
        }

            setBondAmount(value);
            const ethersProvider =  new ethers.providers.Web3Provider(walletProvider)
            const signer =  ethersProvider.getSigner()
            const DimeBondContract = new ethers.Contract(DIME_Bond_Address, DIME_Bond_ABI, signer);
            
            // let result = await DimeBondContract.depositchecker(ethers.BigNumber.from(value).mul(ethers.BigNumber.from(10).pow(18)));
            let result = await DimeBondContract.depositchecker(ethers.utils.parseEther(value.toString()));
            const bigNumberObject = ethers.BigNumber.from((result[3])._hex);
            const bigNumberObject2 = ethers.BigNumber.from((result[1])._hex);
            console.log(result);
            console.log(bigNumberObject.toNumber());
            setJokerInput(bigNumberObject.toNumber());
            setPossiblePayout(bigNumberObject2.toNumber());
            await getAssetDetails();
    }

        // Function to check if the input has more than 18 decimal places
    const hasMoreThan18Decimals = (value) => {
        const parts = value.toString().split('.');
        if (parts.length === 2 && parts[1].length > 18) {
            return true;
        }
        return false;
    };

    const handleSelectChange = async(event1 ,event2) => {
        if(event1 == ""){
            setSelectedBond("");
            console.log("selected bond: empty");
        }else{
            const ethersProvider =  new ethers.providers.Web3Provider(walletProvider)
            const signer =  ethersProvider.getSigner()
            const DimeBondContract = new ethers.Contract(DIME_Bond_Address, DIME_Bond_ABI, signer);

            let claimableReward1 = await DimeBondContract.claimableRewards(address, event2);
            setClaimableRewards(((ethers.BigNumber.from(claimableReward1._hex).toNumber())));
            
            let percentVested = await DimeBondContract.percentVestedFor(address, event2);
            const selectedBondObject = event1;
            const creationTimeBond = selectedBondObject.split(',')[3];
            const initialPayout = selectedBondObject.split(',')[4];
            const vestingTerm = selectedBondObject.split(',')[6];
            setBondCount((event2));
            setSelectedBond(selectedBondObject);
            setPayout(initialPayout);
            setVesting(vestingTerm);
            setPercentVested(percentVested);
            setCreationTimeBond(creationTimeBond);
            console.log("selected bond: ",  initialPayout, event1, ethers.BigNumber.from(claimableReward1._hex).toNumber(), claimableReward1);
        }
    };

    const resetState = () =>{
        setJokerInput("");
        setBondAmount("");
        setPossiblePayout("");
    }

    useEffect(()=>{getAssetDetails()},[address, isConnected]);

    return (  
        <LayoutAfterLogin menuActive={"joker-protocol"} balances={balances}>
            <div className="py-3 px-3 price-list border-bottom border-3">
                <Row className="g-3 justify-content-around align-items-center">
                    <Col lg="2" md={3} xs={6}>
                        <h4 className="mb-1">Treasury Balance </h4>
                        <h4 className="text-gray mb-0   ">${TreasuryDollarvalue ? parseFloat(TreasuryDollarvalue/1e8).toFixed(4) : "0.0000"}</h4>
                    </Col>
                    <Col lg="2" md={3} xs={6}>
                        <h4 className="mb-1">DIME Balance </h4>
                        <h4 className="text-gray mb-0">{TreasuryDimeBal ? parseFloat(TreasuryDimeBal/1e9).toFixed(4) : "0.0000"}</h4>
                    </Col>
                    <Col lg="2" md={3} xs={6}>
                        <h4 className="mb-1">Joker Balance </h4>
                        <h4 className="text-gray mb-0">{TreasuryJokerBal ? parseFloat(TreasuryJokerBal/1e9).toFixed(4) : "0.0000"}</h4>
                    </Col>
                    <Col lg="2" md={3} xs={6}>
                        <h4 className="mb-1">DAI Balance </h4>
                        <h4 className="text-gray mb-0">{TreasuryDaiBal ? parseFloat(TreasuryDaiBal/1e18).toFixed(4) : "0.0000"}</h4>
                    </Col>
                </Row>
            </div>
            <div className="p-24 table-box app-contain">
                <Table responsive striped hover>
                    <thead>
                        <tr>
                            <th>Asset</th>
                            <th>Dime</th>
                            <th>Bond</th>
                            <th>Min Claim limit</th>
                            <th>Min Claim time </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div className="d-flex align-items-center">
                                    <img src={Icon2} width={28} alt="Transfer" />
                                    Dime
                                </div>
                            </td>
                            <td>${(dimePrice / 1e8).toFixed(4)}</td>
                            <td>${(bondPrice / 1e18).toFixed(8)}</td>
                            <td> 20%</td>
                            <td> 2.8 days</td>
                        </tr>
                    </tbody>
                </Table>
                <div className="box p-3 px-md-4">
                    <div className="flex mb-3 flex-wrap g-2">
                        <button className="btn btn-grad outline btn-sm"><span>Dime Bond</span></button>

                        <div className="ms-auto flex flex-wrap g-md-5 g-2 justify-content-end">
                            <h5 className="mb-0"><a className="text-gray flex g-md-2 g-1" href={`https://sepolia.etherscan.io/address/${DIME_Bond_Address}`} target="_blank"><img src={Upload} alt="upload" /> View on explorer</a></h5>
                            {/* <h5 className="mb-0"><a className="text-gray" href={`https://sepolia.etherscan.io/address/${DIME_Bond_Address}`} target="_blank">How it works</a></h5> */}
                            <h5 className="mb-0"><Link className="text-gray" to='/faucet'>DAI Faucet</Link></h5>
                        </div>
                    </div>

                    <Row className="g-3">
                        <Col md={6}>
                            <div className="burn-card px-3 py-3">
                                <div className="swap-card d-flex align-items-center">
                                    <div>
                                        <img src={DaiIcon} alt="Icon1" height="42px"/>
                                    </div>
                                    <div>
                                        <h5>DAI</h5>
                                        <h6>Bal: {parseFloat(daiBalance/1e18).toFixed(2)}</h6>
                                    </div>
                                    <div className="flex-grow-1 py-2">
                                        <input value={bondAmount ? bondAmount : ""} placeholder="0.00" className="input-reset" onChange={(e) => changeInputValue(e.target.value)}/>
                                    </div>
                                </div>
                                <div className="py-2 text-center">
                                    <button type="button" className="btn-reset"><img src={SwapBtn} alt="SwapBtn" /></button>
                                </div>
                                <div className="swap-card mb-4 d-flex align-items-center">
                                    <div>
                                        <img src={Icon1} alt="Icon1" height="42px"/>
                                    </div>
                                    <div>
                                        <h5>Joker</h5>
                                        <h6>Bal: {parseFloat(JokerBalance/1e9).toFixed(2)}</h6>
                                    </div>
                                    <div className="flex-grow-1 py-2">
                                        <input readonly disabled placeholder="0.00" className="input-reset" value={JokerInput?parseFloat(JokerInput/1e9).toFixed(4) : ''} />
                                    </div>
                                </div>
                            {(allowan2 >= JokerInput && allowan >= (bondAmount * 1e18)) ? (<>
                                <button type="submit" className="btn mb-3 btn-grad w-100" onClick={purchaseBond}>
                                <span>Purchase Bond</span></button>
                        </>): (allowan >= (bondAmount * 1e18) ? (<>
                                <button  type="submit"  className=" btn mb-3 btn-grad w-100 " onClick={approveJoker}>
                                <span>Approve Joker</span></button>
                        </>) : (<>
                                <button  type="submit"  className=" btn mb-3 btn-grad w-100 " onClick={approveDai}>
                                <span>Approve DAI</span></button>
                        </>))}

                                <div className="flex flex-wrap g-4">
                                    <div>
                                        <h6 className="text-gray text-13 mb-1">You Will Get</h6>
                                        <h5 className="mb-0">{possiblePayout > 0 ? (possiblePayout / 1e9).toFixed(4) : '0.00'} DIME</h5>
                                    </div>
                                    <div>
                                        <h6 className="text-gray text-13 mb-1">Max You Can Buy</h6>
                                        <h5 className="mb-0">{maxPayout ? (maxPayout / 1e9).toFixed(2) : "0.00"} DIME</h5>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            
                            {selectedBond === "" && (
                            <> <br/><br/><br/><h5 className="text-gray">Select the Bond you want to access</h5>
                            </>)}
                            <div className="mb-3">
                                <select className="form-select" onChange={(event) => handleSelectChange(event.target.value, event.target.selectedIndex)}>
                                {activeBonds.length > 0 ? (
                                        <>
                                        <option value="" key ="">-- Select bond --</option>
                                        {bondInfo.map((bond, index) => (
                                            <option key={index} value={bond}>{`Bond ${index + 1}`}</option>
                                        ))}</>
                                        
                                    ) : (
                                        <option value="" key ="">No Bond</option>
                                    )
                                    }
                                </select>
                                </div>
                                {selectedBond === "" && 
                                (<>
                                <br/>
                                 <div className="flex flex-wrap g-4">
                                    <div className="flex-grow-1">
                                        <h6 className="text-gray text-13 mb-1">Joker</h6>
                                        <h5 className="mb-0">${(jokerPrice / 1e8).toFixed(4)}</h5>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h6 className="text-gray text-13 mb-1">Vesting Term</h6>
                                        <h5 className="mb-0">2 Weeks</h5>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h6 className="text-gray text-13 mb-1">Collateral Ratio</h6>
                                        <h5 className="mb-0">{contractRatio} % DAI + {100 - contractRatio} % JOKER</h5>
                                    </div>
                                </div>
                                </>)}
                            
                            
                            {selectedBond !== "" && ( <>
                                <div className="burn-card mb-3 d-flex align-items-center px-3 py-4">
                                <div>
                                    <h4 className="mb-3">Rewards to be vested in</h4>
                                    <h5 className="text-gray">Next Seigniorage</h5>
                                    <Timer count={new Date(creationTimeBond*1000 + 1210000*1000)} />
                                </div>
                                <div className="ms-auto">
                                    <ProgressBar
                                        percentage={percentVested/100}
                                        startColor="#20E2D0"
                                        endColor="#00C2FF"
                                        gradientId="progress4"
                                    />
                                </div>
                            </div>
                            <div className="burn-card mb-3 px-3 py-4">
                                {claimableRewards > 0 ? 
                                (<>
                                <button type="submit" className="btn mb-3 btn-grad w-100" onClick={claimReward}>Claim</button>
                                </>) : (
                                <>
                                <button disabled type="submit" className="btn mb-3 btn-grad w-100">Claim</button>
                                </>)}
                                
                                <div className="flex flex-wrap g-4">
                                    <div className="flex-grow-1">
                                        <h6 className="text-gray text-13 mb-1">Pending Rewards</h6>
                                        <h5 className="mb-0">{(claimableRewards / 1e9).toFixed(4)} DIME</h5>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h6 className="text-gray text-13 mb-1">Time until fully vested</h6>
                                        <h5 className="mb-0">2 Weeks</h5>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h6 className="text-gray text-13 mb-1">Payout</h6>
                                        <h5 className="mb-0">{(payout / 1e9).toFixed(4)} DIME</h5>
                                    </div>
                                </div>
                            </div>
                            </>) }
                            
                        </Col>
                    </Row>
                    <PendingModal pending={pending} showTr={showTr}  handleCloseTransation={handleCloseTransation} imgSrc={TxnImage} txId={txId} errormsg={errormsg}/>
                </div>
            </div>
        </LayoutAfterLogin>
    );
}

export default Bond;