import { Col, Modal, Row , Spinner} from "react-bootstrap";
import Icon1 from '../../asserts/IMAGES/joker-icon.svg';
import Icon2 from '../../asserts/IMAGES/dimeCyanLogo.svg';
import SwapBtn from '../../asserts/IMAGES/swap-btn.svg';
import EthereumIcon from '../../asserts/IMAGES/Ethereum-icon.svg';
import TransactionPending from '../../asserts/IMAGES/transsationPending.svg';
import ArrowLink from '../../asserts/IMAGES/arrowLink.svg';
import ProgressBar from "../Dashboard/Snippets/CircleTimer";
import Timer from "../Dashboard/Snippets/Timer";
import { useEffect,useState } from "react";
import tauIcon from '../../asserts/IMAGES/tau-original.png'

import { CheckAllowance, PendingModal,   convertEpochToDateTime, getGasBalance, getTokenBalance } from "../../abi/CommonSolFunctions";
import { CHAIN_URL, TAU_Token_ABI, TAU_Token_Address, TXN_ID, WETH_TOKEN_Address, TAU_Bond_ABI, TAU_Bond_Address, DIME_Token_Address, DIME_Token_ABI, DIME_Chainlink_Oracle_Address, Chainlink_Oracle_ABI, TAU_Chainlink_Oracle_Address, API_KEY, Network_Name } from "../../abi/ABI&ContractAddress";
import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers5/react";
import { ethers } from "ethers";
import { createTxn, recordUserVisits } from "../../abi/firebasecode";
/* global BigInt */

function Rollover({selectedTab, balanceOfTokens}) {
    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
    const provider = ethers.getDefaultProvider(Network_Name, {
        etherscan: API_KEY});

    //usestate values
    const [DimeBalance, setDimeBalance] = useState("");
    const [TauBalance, setTauBalance] = useState("");
    const [dimeAmount,setdimeAmount] = useState("");
    const [OutputValue,setOutputValue] = useState("");
    const [dimeAllowance,setdimeAllowance] = useState("");
    const [isRollover,setisRollover] = useState(false);
    const [exchangeRate,setExchangeRate] = useState("");


    const [showTr, setShowTr] = useState(false);
    const [pending, setPending] = useState("");
    const [txId, setTxId] = useState("");
    const [TxnImage, setTxnImage] = useState("");
    const [errormsg, seterrormsg] = useState("");

    const handleCloseTransation = () => setShowTr(false);
    const handleShowTransation = () => setShowTr(true);
   
    const JOKERContract = new ethers.Contract(TAU_Token_Address, TAU_Token_ABI, provider);

    const getAllBalanceFunction = async() =>{
        if(selectedTab === "Rollover")
            await recordUserVisits(address, "RollOver");
        if(isConnected){
            let tauBalance = await getTokenBalance(address,TAU_Token_Address,TAU_Token_ABI);
            console.log("DimeBalance",tauBalance)
            let dimeBalance = await getTokenBalance(address,DIME_Token_Address,DIME_Token_ABI);
            setDimeBalance(dimeBalance);
            setTauBalance(tauBalance);

            let dimeallowance = await CheckAllowance(DIME_Token_Address,DIME_Token_ABI,address,TAU_Bond_Address);
            setdimeAllowance(dimeallowance);

          
            const dimeOracle = new ethers.Contract(DIME_Chainlink_Oracle_Address, Chainlink_Oracle_ABI, provider);
            const TauOracle = new ethers.Contract(TAU_Chainlink_Oracle_Address, Chainlink_Oracle_ABI, provider);
            let dimePrice = ethers.utils.formatUnits(await dimeOracle.getChainlinkDataFeedLatestAnswer(),0);
            let tauprice = ethers.utils.formatUnits(await TauOracle.getChainlinkDataFeedLatestAnswer(),0);
            console.log("price:", dimePrice);
            // let dimePrice = 1e8;
            // let tauprice = 10e8;
            if(dimePrice < 95000000){
                setisRollover(true);
            }
            let exchangeRate = (1 * 1e9 * tauprice)/dimePrice;
            setExchangeRate(exchangeRate /1e9);
            // let secondsleft = ethers.utils.formatUnits(await JOKERContract._secondsLeft(address),0);
            // setSecondsLeft(await convertEpochToDateTime(secondsleft))
            // setLockedTrue(secondsleft)
            // console.log("Seconds left",secondsleft)

            // let value = await JOKERContract.checkSwapValue(1*1e9,[TAU_Token_Address,WETH_TOKEN_Address]);
            // let ethAmount = ethers.utils.formatUnits(value,0);
            // setExchangeRate(ethAmount);
            

        }
        
    }

    useEffect(() =>{getAllBalanceFunction()},[address,isConnected,selectedTab]);

    function formatValues (values,digitsToPrint) {
        return parseFloat(values).toLocaleString(undefined, { minimumFractionDigits: digitsToPrint, useGrouping: false });
    }

    const changeinput = async(e) => {
        setdimeAmount(e);
        
        try{
           
            const dimeOracle = new ethers.Contract(DIME_Chainlink_Oracle_Address, Chainlink_Oracle_ABI, provider);
            const TauOracle = new ethers.Contract(TAU_Chainlink_Oracle_Address, Chainlink_Oracle_ABI, provider);
            let dimePrice = ethers.utils.formatUnits(await dimeOracle.getChainlinkDataFeedLatestAnswer(),0);
            let tauprice = ethers.utils.formatUnits(await TauOracle.getChainlinkDataFeedLatestAnswer(),0);
            // let dimePrice = 1e8;
            // let tauprice = 10e8;

            let dimeTokenMint = (e * 1e9 * tauprice)/dimePrice;
            setOutputValue(dimeTokenMint)
        }
        catch(err){
            console.log(err)
            setOutputValue(0);
        }
         
    }

    const maxCall = async() =>{
        if(DimeBalance > 0){
            changeinput(DimeBalance/1e9)
        }
    }

    const approve = async() =>{
        if(isConnected){
            try{
                const ethersProvider =  new ethers.providers.Web3Provider(walletProvider)
                const signer =  ethersProvider.getSigner() 
                const DimeContract = new ethers.Contract(DIME_Token_Address, DIME_Token_ABI, signer);
                await showPopUp("yes",TransactionPending,"","");
                const approveTxn = await DimeContract.approve(TAU_Bond_Address,BigInt(1000000000 * 1e9));
                
                await approveTxn.wait();
                await TxnId_PopUp(approveTxn.hash);
            }catch(err){
                showPopUp("not",TransactionPending,"",(err.reason).toString());
                console.log("error",err);
                
            }
        }
    }
    const rollover = async() =>{
        if(isConnected){
            try{
                const ethersProvider =  new ethers.providers.Web3Provider(walletProvider)
                const signer =  ethersProvider.getSigner() 
                const TAUBondContract = new ethers.Contract(TAU_Bond_Address, TAU_Bond_ABI, signer);
                await showPopUp("yes",TransactionPending,"","");
                const rollovertxn = await TAUBondContract.deposit(BigInt(dimeAmount * 1e9));
                
                await rollovertxn.wait();
                await TxnId_PopUp(rollovertxn.hash);
                await balanceOfTokens();
                await createTxn("TAU",rollovertxn.hash,"Rollover",address,TAU_Bond_Address);
                await resetState();
            }catch(err){
                showPopUp("not",TransactionPending,"",(err.reason).toString());
                console.log("error",err);
                
            }
        }
    }

    const TxnId_PopUp =async(hash) =>{
        let txnid = TXN_ID+hash;
        showPopUp("no",TransactionPending,txnid,"");
        await getAllBalanceFunction();
    }

    const showPopUp = async(pendingvalue,imgSrc,txid,errormsg)=>{
        // console.log("showPopUp",pendingvalue,imgSrc,txid,errormsg)
        setPending(pendingvalue);
        setTxId(txid);
        setTxnImage(imgSrc);
        seterrormsg(errormsg);    
        handleShowTransation();        
    }

    const resetState = async() =>{
        setdimeAmount("");
        setOutputValue("");
    }

    const allowanceTrue = parseInt(dimeAllowance) >= parseInt(dimeAmount*1e9);

    return ( 
        <div className="p-24">
             <Row className="justify-content-center">
                <Col md={12} style={{maxWidth: '800px'}}>
                    <div className="box p-3 p-md-4">
                        <div className="swap-card d-flex align-items-center">
                            <div>
                                <img src={Icon2} alt="Icon2" height={'42px'}/>
                            </div>
                            <div>
                                <h5>Dime</h5>
                                <h6>Bal: 
                                {parseFloat(DimeBalance) === 'NaN' ? '0.00' :  formatValues((DimeBalance/1e9),2)}
                                    </h6>
                            </div>
                            <div className="flex-grow-1 py-2">
                                <input value={dimeAmount} placeholder="0.00" className="input-reset" onChange={(e) => changeinput(e.target.value)}/>
                            </div>

                            <button className="btn btn-grad py-2" onClick={()=>maxCall()}>Max</button>
                        </div>
                        <div className="py-2 text-center">
                            <button type="button" className="btn-reset"><img src={SwapBtn} alt="SwapBtn" /></button>
                        </div>
                        <div className="swap-card mb-4 d-flex align-items-center">
                            <div>
                                <img src={tauIcon} height="42px" alt="Icon1" />
                            </div>
                            <div>
                                <h5>TAU</h5>
                                <h6>Bal:
                                {parseFloat(TauBalance) === 'NaN' ? '0.00' :  formatValues((TauBalance/1e9),2)}
                                     </h6>
                            </div>
                            <div className="flex-grow-1 py-2">
                                <input readonly disabled  placeholder="0.00" className="input-reset" value={parseFloat(OutputValue) ? (parseFloat(OutputValue)/1e9).toFixed(4) : '0.00'}/>
                            </div>
                        </div>
                      
                   <div className="box swap-value border-top">
                        <div className="p-4 border-bottom">
                            <h6>Exchange Rate</h6>
                            <h4>1 &nbsp; DIME   &nbsp;= &nbsp; {exchangeRate?parseFloat(exchangeRate).toFixed(8):'0'} &nbsp; TAU </h4>
                        </div>
                        {/* <div className="p-4 border-bottom">
                            <h6>Exchange Fees</h6>
                            <h4> $1 DIME = 10 TAU </h4>
                        </div> */}
                        <div className="p-4 border-bottom">
                            <h6>You Will Receive</h6>
                            <h4>{OutputValue?parseFloat(OutputValue / 1e9).toFixed(9):'0.00'}</h4>
                        </div>
                        <div className="px-4 pb-4 p-4">
                        {/* {allowanceTrue?(<>
                            <button type="submit" className="btn btn-grad w-100 outline py-2 "><span>Approve DIME</span></button>
                        </>):(<>
                            <button className="btn btn-grad w-100 py-2" onClick={()=>approve()}>Approve DIME</button>
                        </>)} */}
                        {/* {allowanceTrue && isRollover?(<>
                        <button  type="submit"  className=" btn mb-20 btn-grad  w-100 " onClick={()=>rollover()}>
                        <span>Rollover</span></button>
                        </>):(<>
                            <button className="btn btn-grad w-100 py-2" onClick={()=>approve()}>Approve DIME</button>
                        </>)} */}
                        <button type="submit" className="btn btn-grad w-100 outline py-2 "><span>Rollover is not available</span></button>
                       
                                                                 
                        {!isRollover &&
                        <div className="mt-4 text-center">
                            <p className="text-gray mb-0">Disclaimer: Rollover will not be available until the Dime price is below $1.
                            {/* <Tooltip text="Lorem Ipsum Content." /> */}
                            </p>
                        </div>
                        }
                        </div>
                    </div>
                    </div>
                    
                </Col>
                {/* <Col md={6}>
                   
                </Col> */}
            </Row>
            <PendingModal pending={pending} showTr={showTr}  handleCloseTransation={handleCloseTransation} imgSrc={TxnImage} txId={txId} errormsg={errormsg}/>

        </div>
     );
}

export default Rollover;