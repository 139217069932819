import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import Logo from '../asserts/IMAGES/logo-icon.svg';
import Icon1 from '../asserts/IMAGES/header-icon-1.svg';
import Icon2 from '../asserts/IMAGES/header-icon-2.svg';
import Icon3 from '../asserts/IMAGES/header-icon-3.svg';
import Icon4 from '../asserts/IMAGES/header-icon-4.svg';
import jokerLogo from '../asserts/IMAGES/jokerRedLogo.svg';
import dimeLogo from '../asserts/IMAGES/dimeCyanLogo.svg';
import creditsLogo from '../asserts/IMAGES/creditsBlackLogo.svg';
import DangerIcon from '../asserts/IMAGES/DangerIcon.svg';
import Icon5 from '../asserts/IMAGES/header-icon-5.svg';
import JokerIcon from '../asserts/IMAGES/joker-icon-logo.svg'
import MetamaskIcon from '../asserts/IMAGES/metamask-icon.svg'
import ExplorerIcon from '../asserts/IMAGES/explorerIcon.svg'
import Dots from '../asserts/IMAGES/dots.svg'
import Menu from '../asserts/IMAGES/menu-icon.svg'
import CopyIcon from '../asserts/IMAGES/copy-icon.svg'
import TranssationPending from '../asserts/IMAGES/transsationPending.svg'
import ArrowLink from '../asserts/IMAGES/arrowLink.svg'
import Sidebar from './Sidebar';
import headerLogo from '../asserts/IMAGES/joker-app-logo.svg';

import { useState } from 'react';
import { Col, Modal, Row, Spinner } from 'react-bootstrap';
import { ChangeNetwork, ConnectWallet, config } from '../abi/CommonSolFunctions';
import { useWeb3ModalAccount, useWeb3ModalProvider, useDisconnect  } from '@web3modal/ethers5/react';

function Header({menuActive, balances}) {
    const { walletProvider } = useWeb3ModalProvider();
    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { disconnect } = useDisconnect();

    const [showSidebar, SetShowSidebar] = useState(false);
    const handleToggle = () => {
      SetShowSidebar(!showSidebar);
    }

    const [show, setShow] = useState(false);
    const [copy, setCopy] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleCopied = async () => {
      setCopy(true);
      await sleep(2000);
      setCopy(false);
    }

    const connectWallet = async () => {
      await ConnectWallet();
    }

    const changeNetwork = async () => {
      await ChangeNetwork({walletProvider});
    }

    const disconnectWallet = async () => {
      try {
        disconnect();
        handleClose();
        // console.log('Wallet disconnected successfully.');
      } catch (error) {
        // console.error('Error disconnecting wallet:', error);
      }
    };

    function sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    const [showTr, setShowTr] = useState(false);

    const handleCloseTransation = () => setShowTr(false);
    const handleShowTransation = () => setShowTr(true);

    return ( 
      <>
        <header className="header-bar sticky-top">
        <Link className="header-logo" to="/"><img src={headerLogo} alt="logo" height="40px"/></Link>

          <button onClick={() => handleToggle()} className='btn p-0 ms-auto ms-md-3 order-md-1 btn-menu'>
            <img src={Menu} alt='Menu' />
          </button>

          <div className='header-controls ms-sm-auto'>
          {isConnected ? <>
            <div className='headerlogo-item'>
              <img src={jokerLogo} alt="icon" height={'19px'} />
              {parseFloat(balances.joker).toFixed(2)} 
            </div>
            <div className='headerlogo-item'>
              <img src={dimeLogo} alt="icon" height={'19px'} />
              {parseFloat(balances.dime).toFixed(2)} 
            </div>
            <div className='headerlogo-item' >
              <img src={creditsLogo} alt="icon" height={'19px'}/>
              {parseFloat(balances.credits).toFixed(2)} 
            </div>
          </> : <></>}

            <button type='button' className='header-item' onClick={(!address ? connectWallet : handleShow)}>
              <img src={Icon4} alt="icon" />
                {!address ? 'Connect Wallet' : `${address.substring(0, 4)}...${address.substring(address.length - 4)}`}
            </button>

            {chainId !== config.chainId && isConnected && (
              <button type='button' className='header-item  btn btn-red' style={{ background: '#C70039' }} onClick={changeNetwork}>
                <img src={DangerIcon} alt="icon" />
                Change Network
              </button>
            )}

            {/* <Dropdown className='d-none d-sm-block'>
              <Dropdown.Toggle variant="toggle" id="dropdown-basic">
                <img src={Dots} alt="Dots" />
              </Dropdown.Toggle> */}

              {/* <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
              </Dropdown.Menu> */}
            {/* </Dropdown> */}
          </div>
        </header>     

        <Sidebar menuActive={menuActive} view={showSidebar} />   

        <Modal show={show} centered onHide={handleClose}>
            <Modal.Header className='pb-0' closeButton>
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="text-center">
                    <div className="mb-4">
                        <img src={JokerIcon} height={'40px'} className="mb-20" alt="JokerIcon" />
                        <h3 style={{ fontSize: '1rem' }}>{!address ? 'Connect Wallet' : `${address.substring(0, 4)}...${address.substring(address.length - 4)}`}</h3>
                    </div>

                    <div className='d-flex justify-content-center align-items-center mb-4'>
                      <div className='header-item'>
                        <img src={Icon5} alt="icon" />
                        {parseFloat(balances.eth).toFixed(6)} ETH
                      </div>
                    </div>

                    <Row className='g-3'>
                      <Col xs={6}>
                        <button type='button' className='flex btn-black swap-card g-3 w-100 p-3 justify-content-center' 
                        onClick={() =>
                          {
                              navigator.clipboard.writeText(address);
                              handleCopied();
                          }}>
                          <img src={CopyIcon} className='m-0' alt="Icon1" />
                          {!copy ? 'Copy Address' : 'Address Copied!'}
                        </button>
                      </Col>
                      <Col xs={6}>
                        <a href={`${config.explorerUrl}/address/${address}`} target="_blank" rel="noopener noreferrer" type='button' className='flex btn-black swap-card g-3 w-100 p-3 justify-content-center'>
                          <img src={ExplorerIcon} className='m-0' alt="Icon1" />
                          View on explorer
                        </a>
                      </Col>
                    </Row>
                </div>
                <hr />
                <h4 className='mb-3'>Connected with MetaMask <img src={MetamaskIcon} className='ms-2' alt="MetamaskIcon" /></h4>
                <Row className='g-3 align-items-center'>
                <Col xs={6}>
                    <select className='form-select'>
                      <option>Testnet</option>
                      <option>Mainnet</option>
                    </select>
                  </Col>
                  <Col xs={6}>
                    <button type='button' className='flex btn btn-red w-100 p-3 justify-content-center' onClick={disconnectWallet}>
                      Disconnect
                    </button>
                  </Col>
                </Row>

                {/* <button className="btn btn-grad w-100">Button</button>     */}
            </Modal.Body>
        </Modal>

        <Modal show={showTr} centered onHide={handleCloseTransation}>
            <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="text-center">
                <img src={TranssationPending} width={80} className="mb-3" alt="JokerIcon" />
                <h3 className='mb-1'>Transaction Pending</h3>
                <p className='mb-4'><small>Follow the Metamask prompts to authorize the transaction.</small></p>
                <button className='btn btn-black border-0 flex justify-content-center  w-100 g-2 mb-3'><Spinner size='sm' animation="border" variant="blue " /> <span className='text-grad'>Loading</span></button>
                <button className="btn-link" onClick={handleCloseTransation}><span className='text-grad'>View Etherscan</span> <img src={ArrowLink} className='ms-2' alt="arrowLink" /></button>    
              </div>

            </Modal.Body>
        </Modal>
      </>
     );
}

export default Header;
