import { Col, Row } from "react-bootstrap";
import LayoutAfterLogin from "../../component/LayoutAL";
import Tooltip from "../../Snippets/Tooltip";
import JokerIcon from '../../asserts/IMAGES/jokerRedLogo.svg'
import DimeIcon from '../../asserts/IMAGES/daidimelp.svg'
import CreditsIcon from '../../asserts/IMAGES/daicreditslp.svg'
import RocketIcon from '../../asserts/IMAGES/rocket-icon.svg'
import DollerYellow from '../../asserts/IMAGES/dai.svg'
import LinkIcon from '../../asserts/IMAGES/link-icon.svg'
import Icon1 from '../../asserts/IMAGES/jokerRedLogo.svg';
import Icon2 from '../../asserts/IMAGES/daidimelp.svg';
import Icon3 from '../../asserts/IMAGES/daicreditslp.svg';
import dimelogo from '../../asserts/IMAGES/dimeCyanLogo.svg';
import { Link, useNavigate } from "react-router-dom";
import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers5/react";
import { useEffect, useState } from "react";
import { JokerJSON, getCreditsStakingContractDetails, getDIMEStakingContractDetails, getJokerStakingContractDetails, getTokenBalance } from "../../abi/CommonSolFunctions";
import { CREDITS_LP_Staking_Address, CREDITS_LP_Token_Address, CREDITS_Token_Address, Communitywallet_Address, DAI_TOKEN_Address, DIME_LP_Staking_Address, DIME_LP_Token_Address, DIME_Token_ABI, DIME_Token_Address, JOKER_Staking_Address, JOKER_Token_ABI, JOKER_Token_Address } from "../../abi/ABI&ContractAddress";
import { recordUserVisits } from "../../abi/firebasecode";

function Staking({balances}) {
    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
    const navigate = useNavigate();


    const [jokerBalance, setJokerBalance] = useState("");
    const [DimeLpBalance, setDimeLpBalance] = useState("");
    const [CreditLpBalance, setCreditLpBalance] = useState("");

    const [JokerBlocksPerBlocks, setJokerBlocksPerBlocks] = useState("");
    const [DimeBlocksPerBlocks, setDimeBlocksPerBlocks] = useState("");
    const [CreditBlocksPerBlocks, setCreditBlocksPerBlocks] = useState("");
    
    const [Jokerbonus, setJokerbonus] = useState("");
    const [Dimebonus, setDimebonus] = useState("");
    const [Creditbonus, setCreditbonus] = useState("");
    const [JokerBalanceCommunity, setJokerBalanceCommunity] = useState("");
    const [DimeBalanceCommunity, setDimeBalanceCommunity] = useState("");



    const getAllBalanceFunction = async() =>{
        await recordUserVisits(address, "Staking - Home Page");
        if(isConnected){
            let jokerBalance = await getTokenBalance(JOKER_Staking_Address,JOKER_Token_Address,JOKER_Token_ABI);
            console.log("jokerBalance",jokerBalance)
            setJokerBalance(jokerBalance)
            let dimelpBalance = await getTokenBalance(DIME_LP_Staking_Address,DIME_LP_Token_Address,DIME_Token_ABI);
            setDimeLpBalance(dimelpBalance)
            let creditlpBalance = await getTokenBalance(CREDITS_LP_Staking_Address,CREDITS_LP_Token_Address,DIME_Token_ABI);
            setCreditLpBalance(creditlpBalance)

            let blocksperblocksjoker = await getJokerStakingContractDetails('blackPerBlock');
            setJokerBlocksPerBlocks(blocksperblocksjoker);
            let blocksperblocksdime = await getDIMEStakingContractDetails('blackPerBlock');
            setDimeBlocksPerBlocks(blocksperblocksdime);
            let blocksperblockscredit = await getCreditsStakingContractDetails('blackPerBlock');
            setCreditBlocksPerBlocks(blocksperblockscredit);

            let bonusjoker = await getJokerStakingContractDetails('BONUS_MULTIPLIER');
            setJokerbonus(bonusjoker);
            let bonusdime = await getDIMEStakingContractDetails('BONUS_MULTIPLIER');
            setDimebonus(bonusdime);
            let bonuscredit = await getCreditsStakingContractDetails('BONUS_MULTIPLIER');
            setCreditbonus(bonuscredit);

            let jokerBalanceCommunity = await getTokenBalance(Communitywallet_Address,JOKER_Token_Address,JOKER_Token_ABI);
            setJokerBalanceCommunity(jokerBalanceCommunity);

            let dimeBalanceCommunity = await getTokenBalance(Communitywallet_Address,DIME_Token_Address,DIME_Token_ABI);
            setDimeBalanceCommunity(dimeBalanceCommunity);

            
        }
    }

    useEffect(() =>{getAllBalanceFunction()},[address,isConnected]);

   
    let JokerJSON ={
        "depositTokenAddress":JOKER_Token_Address,
        "RewardTokenAddress":JOKER_Token_Address,
        "StakingContract":JOKER_Staking_Address,
        "decimalAmount":9,
        "rewarddecimal":9,
        "TokenName":"JOKER",
        "tokenLogo":Icon1,
        "blocksperyear":JokerBlocksPerBlocks?JokerBlocksPerBlocks:'0'
    }
    
    let DimeJSON ={
        "depositTokenAddress":DIME_LP_Token_Address,
        "RewardTokenAddress":DIME_Token_Address,
        "StakingContract":DIME_LP_Staking_Address,
        "decimalAmount":18,
        "rewarddecimal":18,
        "TokenName":"DIME_DAI LP",
        "tokenLogo":Icon2,
        "blocksperyear":DimeBlocksPerBlocks?DimeBlocksPerBlocks:'0'

    }

    let CreditJSON ={
        "depositTokenAddress":CREDITS_LP_Token_Address,
        "RewardTokenAddress":JOKER_Token_Address,
        "StakingContract":CREDITS_LP_Staking_Address,
        "decimalAmount":18,
        "rewarddecimal":9,
        "TokenName":"CREDIT_DAI LP",
        "tokenLogo":Icon3,
        "blocksperyear":CreditBlocksPerBlocks?CreditBlocksPerBlocks:'0'

    }
     

   
    return (  
        <LayoutAfterLogin menuActive={"joker-protocol"} balances={balances}>
            <div className="py-3 px-3 price-list border-bottom border-3">
                <Row className="g-3 justify-content-around align-items-center">
                    <Col lg="2" md={3} xs={6}>
                        <h4 className="mb-1">Staked Liquidity </h4>
                        <h4 className="text-grey mb-0">{jokerBalance?parseFloat((parseFloat(jokerBalance/1e9)+parseFloat(CreditLpBalance/1e18)+parseFloat(DimeLpBalance/1e18))).toFixed(3):'0.0'}</h4>
                    </Col>
                    <Col lg="2" md={3} xs={6}>
                        <h4 className="mb-1">Total APR </h4>
                        <h4 className="text-grey mb-0">$3.51</h4>
                    </Col>
                    <Col lg="2" md={3} xs={6}>
                        <h4 className="mb-1">Available Rewards </h4>
                        <h4 className="text-grey mb-0"><img src={JokerIcon} alt="JokerIcon" height={'35px'}/> {JokerBalanceCommunity?parseFloat(JokerBalanceCommunity/1e9).toFixed(4):'0'}</h4>
                    </Col>
                    <Col lg="2" md={3} xs={6}>
                        <h4 className="mb-1">Available Rewards </h4>
                        <h4 className="text-grey mb-0"><img src={dimelogo} alt="JokerIcon" height={'35px'}/> {DimeBalanceCommunity?parseFloat(DimeBalanceCommunity/1e9).toFixed(4):'0'}</h4>
                    </Col>
                </Row>
            </div>
            <div className="p-24 app-contain">
                <Row className="g-lg-4 g-3 justify-content-center">
                    <Col md={4} sm={6}>
                        <div className="box p-3 staking-card">
                            <div className="staking-card-header border-bottom pb-2 flex g-2 justify-content-between">
                                <div className="staking-card-header-icon">
                                    <img src={JokerIcon} alt="JokerIcon" height={'42px'}/>
                                    {/* <img src={DollerYellow} alt="DollerYellow" /> */}
                                </div>
                                <div className="staking-card-header-info text-end">
                                    <h5 className="mb-1">JOKER</h5>
                                    {/* <span>0.25%</span> */}
                                    <span>{Jokerbonus?Jokerbonus:'0'}X</span>
                                </div>
                            </div>
                            <div className="staking-card-body border-bottom py-3">
                                <div className="mb-2 flex justify-content-between text-13 text-600">
                                    <span className="text-gray">APR:</span>
                                    <span className="flex g-1 text-green"><img src={RocketIcon} alt="RocketIcon" /> Up to {JokerBlocksPerBlocks?parseFloat(((JokerBlocksPerBlocks*365)/(jokerBalance/1e9))*100).toFixed(3):'0.0'}%</span>
                                </div>
                                <div className="mb-2 flex justify-content-between text-13 text-600">
                                    <span className="text-gray">Earn:</span>
                                    <span>JOKER </span>
                                </div>
                               
                               
                            </div>
                            <div className="staking-card-footer py-3">
                                <div className="mb-2 flex justify-content-between text-13 text-600">
                                    <span className="text-gray">Staked Liquidity:</span>
                                    <span>{jokerBalance?parseFloat(jokerBalance/1e9).toFixed(4):'0'}</span>
                                </div>
                                <div className="mb-3 flex justify-content-between text-13 text-600">
                                    <span className="text-gray">Multiplier:</span>
                                    <span>{Jokerbonus?Jokerbonus:'0'}X </span>
                                </div>
                                <div className="flex g-2 flex-column justify-content-end align-items-end text-13 text-600">
                                    <a href={`https://pancakeswap.finance/swap?chain=sepolia&outputCurrency=${JOKER_Token_Address}`}  target="_blank" rel="noreferrer" className="text-blue">Buy JOKER</a>
                                    <a href={`https://sepolia.etherscan.io/address/${JOKER_Token_Address}`} target="_blank" className="text-blue flex g-2">See Token Info <img src={LinkIcon} alt="LinkIcon" /></a>
                                    <a href={`https://sepolia.etherscan.io/address/${JOKER_Staking_Address}`} target="_blank" className="text-blue">View Contract</a>
                                </div>
                            </div>
                            <Link to='/welcome' state={JokerJSON}>
                                <button className="btn btn-grad py-2 mt-md-5 mt-4 w-100" >View Pool</button>
                                </Link>
                        </div>
                    </Col>
                    <Col md={4} sm={6}>
                        <div className="box p-3 staking-card">
                            <div className="staking-card-header border-bottom pb-2 flex g-2 justify-content-between">
                                <div className="staking-card-header-icon">
                                    <img src={DimeIcon} alt="DIME-DAI" height={'42px'}/>
                                    {/* <img src={DollerYellow} alt="DollerYellow" height={'20px'}/> */}
                                </div>
                                <div className="staking-card-header-info text-end">
                                    <h5 className="mb-1">DIME-DAI</h5>
                                    {/* <span>0.25%</span> */}
                                    <span>{Dimebonus?Dimebonus:'0'}X</span>
                                </div>
                            </div>
                            <div className="staking-card-body border-bottom py-3">
                                <div className="mb-2 flex justify-content-between text-13 text-600">
                                    <span className="text-gray">APR:</span>
                                    <span className="flex g-1 text-green"><img src={RocketIcon} alt="RocketIcon" /> Up to {DimeBlocksPerBlocks?parseFloat(((DimeBlocksPerBlocks*365)/(DimeLpBalance/1e9))*100).toFixed(3):'0.0'}%</span>
                                </div>
                                <div className="mb-2 flex justify-content-between text-13 text-600">
                                    <span className="text-gray">Earn:</span>
                                    <span>DIME </span>
                                </div>

                                
                            </div>
                            <div className="staking-card-footer py-3">
                                <div className="mb-2 flex justify-content-between text-13 text-600">
                                    <span className="text-gray">Staked Liquidity:</span>
                                    <span>{DimeLpBalance?parseFloat(DimeLpBalance/1e18).toFixed(4):'0'}</span>
                                </div>
                                <div className="mb-3 flex justify-content-between text-13 text-600">
                                    <span className="text-gray">Multiplier:</span>
                                    <span>{Dimebonus?Dimebonus:'0'}X </span>
                                </div>
                                <div className="flex g-2 flex-column justify-content-end align-items-end text-13 text-600">
                                    <a href={`https://pancakeswap.finance/v2/add/${DIME_Token_Address}/${DAI_TOKEN_Address}?chain=sepolia`} target="_blank" className="text-blue">Add DIME-DAI LP</a>
                                    <a href={`https://sepolia.etherscan.io/address/${DIME_LP_Token_Address}`} target="_blank" className="text-blue flex g-2">See Pair Info <img src={LinkIcon} alt="LinkIcon" /></a>
                                    <a href={`https://sepolia.etherscan.io/address/${DIME_LP_Staking_Address}`} target="_blank" className="text-blue">View Contract</a>
                                </div>
                            </div>
                            <Link to='/welcome' state={DimeJSON}>
                                <button className="btn btn-grad py-2 mt-md-5 mt-4 w-100" >View Pool</button>
                                </Link>
                        </div>
                    </Col>
                    <Col md={4} sm={6}>
                        <div className="box p-3 staking-card">
                            <div className="staking-card-header border-bottom pb-2 flex g-2 justify-content-between">
                                <div className="staking-card-header-icon">
                                    <img src={CreditsIcon} alt="CREDITS-DAI" height={'42px'}/>
                                    {/* <img src={DollerYellow} alt="DollerYellow" /> */}
                                </div>
                                <div className="staking-card-header-info text-end">
                                    <h5 className="mb-1">CREDITS-DAI</h5>
                                    {/* <span>0.25%</span> */}
                                    <span>{Creditbonus?Creditbonus:'0'}X</span>
                                </div>
                            </div>
                            <div className="staking-card-body border-bottom py-3">
                                <div className="mb-2 flex justify-content-between text-13 text-600">
                                    <span className="text-gray">APR:</span>
                                    <span className="flex g-1 text-green"><img src={RocketIcon} alt="RocketIcon" /> Up to {CreditBlocksPerBlocks?parseFloat(((CreditBlocksPerBlocks*365)/(CreditLpBalance/1e18))*100).toFixed(3):'0.0'}%</span>
                                </div>
                                <div className="mb-2 flex justify-content-between text-13 text-600">
                                    <span className="text-gray">Earn:</span>
                                    <span>JOKER</span>
                                </div>

                               
                            </div>
                            <div className="staking-card-footer py-3">
                                <div className="mb-2 flex justify-content-between text-13 text-600">
                                    <span className="text-gray">Staked Liquidity:</span>
                                    <span>{CreditLpBalance?parseFloat(CreditLpBalance/1e18).toFixed(4):'0'}</span>
                                </div>
                                <div className="mb-3 flex justify-content-between text-13 text-600">
                                    <span className="text-gray">Multiplier:</span>
                                    <span>{Creditbonus?Creditbonus:'0'}X </span>
                                </div>
                                <div className="flex g-2 flex-column justify-content-end align-items-end text-13 text-600">
                                    <a href={`https://pancakeswap.finance/v2/add/${CREDITS_Token_Address}/${DAI_TOKEN_Address}?chain=sepolia`}  target="_blank" className="text-blue">Add CREDITS-DAI LP</a>
                                    <a href={`https://sepolia.etherscan.io/address/${CREDITS_LP_Token_Address}`} target="_blank" className="text-blue flex g-2">See Pair Info <img src={LinkIcon} alt="LinkIcon" /></a>
                                    <a href={`https://sepolia.etherscan.io/address/${CREDITS_LP_Staking_Address}`} target="_blank" className="text-blue">View Contract</a>
                                </div>
                            </div>
                            <Link to='/welcome' state={CreditJSON}>
                                <button className="btn btn-grad py-2 mt-md-5 mt-4 w-100" >View Pool</button>
                                </Link>
                        </div>
                    </Col>
                </Row>
            </div>
        </LayoutAfterLogin>
    );
}

export default Staking;