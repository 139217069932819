import { Button, Col, Container, FloatingLabel, Form, InputGroup, Row } from "react-bootstrap";
import LayoutBeforeLogin from "../component/LayoutBL";
import Preview from "../asserts/IMAGES/loginImage.jpg";
import { useState } from "react";
import { Link } from "react-router-dom";
import LoginIcon from '../asserts/IMAGES/logo-icon.svg'

function CreateAccount() {
    const [show, setShow] = useState(false);
    
    return ( 
        <LayoutBeforeLogin>
            <Container fluid>
                <Row className="app-blogin-row align-items-center">
                    <Col md={6}>
                        <div className="app-blogin-inner">
                            <div className="d-md-none text-center mb-4">
                                <img src={LoginIcon} alt="logo icon" />
                            </div>
                            <div className="mb-4 row text-center text-md-start">
                                <Col md={8}>
                                    <h1 className="text-28 text-600 mb-1">Welcome onboard!</h1>
                                    <p className="text-500">Create your account using your email address or try via other available options</p>
                                </Col>
                            </div>

                            <Form>
                                <FloatingLabel
                                    controlId="floatingInput.Email"
                                    label="Email"
                                    className="mb-3" 
                                >
                                    <Form.Control type="email" placeholder=" " />
                                </FloatingLabel>

                                <Button type="submit" variant="black" className="d-block w-100 mb-2">Continue</Button>
                                <p className="px-md-5 px-4 text-center mb-3">By clicking on log in you agreed with the divinedim’s terms of service & privacy guidelines</p>
                                <div className="divider d-flex align-items-center text-nowrap"><span className="px-3">Or sign up using</span></div>

                                <Button variant="white" className="btn-icon icon-wallet w-100 mb-3">Login via your Wallet</Button>
                                <Button variant="white" className="btn-icon icon-metamask w-100 mb-3">Login via metamask</Button>

                                <div className="text-center">
                                    <Link className="btn-link" to="/">Already have an account? Log In</Link>
                                </div>
                            </Form>

                        </div>
                    </Col>
                    <Col md={6} className="d-none d-md-block text-center text-md-end">
                        <img src={Preview} alt="Preview" className="img-fluid" />
                    </Col>
                </Row>
            </Container>
        </LayoutBeforeLogin>
    )
}

export default CreateAccount;