import { Button, Col, Container, FloatingLabel, Form, InputGroup, Row } from "react-bootstrap";
import LayoutBeforeLogin from "../component/LayoutBL";
import { useState } from "react";
import { Link } from "react-router-dom";
import LoginIcon from '../asserts/IMAGES/logo-icon.svg'

function Signup() {
    const [show, setShow] = useState(false);
    const [showCon, setShowCon] = useState(false);
    
    return ( 
        <LayoutBeforeLogin>
            <Container fluid>
                <Row className="app-blogin-row justify-content-center align-items-center">
                    <Col md={6}>
                        <div className="app-blogin-inner">
                            <div className="d-md-none text-center mb-4">
                                <img src={LoginIcon} alt="logo icon" />
                            </div>
                            <div className="mb-4 text-center">
                                <h1 className="text-28 text-600 mb-1">Let set up your account</h1>
                                <p className="text-500">Add up details to help you create an account.</p>
                            </div>

                            <Form>
                                <Row>
                                    <Col xs={6} className="mb-3">
                                        <FloatingLabel
                                            controlId="floatingInput.First"
                                            label="First name"
                                        >
                                            <Form.Control type="text" placeholder=" " />
                                        </FloatingLabel>
                                    </Col>
                                    <Col xs={6} className="mb-3">
                                        <FloatingLabel
                                            controlId="floatingInput.Last"
                                            label="Last name"
                                        >
                                            <Form.Control type="text" placeholder=" " />
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                                <FloatingLabel
                                    controlId="floatingInput.Email"
                                    label="Email"
                                    className="mb-3"
                                >
                                    <Form.Control type="email" placeholder=" " />
                                </FloatingLabel>
                                <FloatingLabel
                                    controlId="floatingInput.Phone"
                                    label="Phone number"
                                    className="mb-3"
                                >
                                    <Form.Control type="tel" placeholder=" " />
                                </FloatingLabel>

                                <InputGroup className="mb-3">
                                    <FloatingLabel
                                        controlId="floatingInput.Password"
                                        label="Password"
                                    >
                                        <Form.Control
                                            type={show ? 'text' : "password"}
                                            placeholder=" "
                                        />
                                    </FloatingLabel>
                                    <Button variant="reset" onClick={() => setShow(!show)} className="border-0" id="button-addon2">
                                        {show ? 'Hide' : 'Show'}
                                    </Button>
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <FloatingLabel
                                        controlId="floatingInput.Confirm"
                                        label="Confirm password"
                                    >
                                        <Form.Control
                                            type={showCon ? 'text' : "password"}
                                            placeholder=" "
                                        />
                                    </FloatingLabel>
                                    <Button variant="reset" onClick={() => setShowCon(!showCon)} className="border-0" id="button-addon2">
                                        {showCon ? 'Hide' : 'Show'}
                                    </Button>
                                </InputGroup>

                                <Button type="submit" variant="black" className="d-block w-100 mb-3">Continue</Button>
                                <p className="px-md-4 text-16 text-500 text-center mb-3">By clicking on Continue you agreed with the divinedim’s <br /><Link to="/">terms of service</Link> & <Link to="/">privacy guidelines</Link></p>
                            </Form>

                        </div>
                    </Col>
                </Row>
            </Container>
        </LayoutBeforeLogin>
    )
}

export default Signup;