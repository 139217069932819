import { Col, Row } from "react-bootstrap";
import Tooltip from "../../../Snippets/Tooltip";
import TransactionPending from '../../../asserts/IMAGES/transsationPending.svg';
import { useState, useEffect } from "react";
import DollerIcon from '../../../asserts/IMAGES/doller-icon.svg';
import DaiIcon from '../../../asserts/IMAGES/dai.svg';

import { CheckAllowance, PendingModal, SuccessModal, getGasBalance, getTokenBalance, convertEpochToDateTime } from "../../../abi/CommonSolFunctions";
import { CHAIN_URL, JOKER_Token_ABI, JOKER_Token_Address, TXN_ID,  Chainlink_Oracle_ABI, DAI_TOKEN_ABI, DAI_TOKEN_Address, Carbonix_Finance_Address, Carbonix_Finance_ABI, DIME_Chainlink_Oracle_Address, DAI_Chainlink_Oracle_Address, DIME_Token_ABI, DIME_Token_Address, JOKER_Chainlink_Oracle_Address, CREDITS_Token_Address, CREDITS_Token_ABI, API_KEY, Network_Name } from "../../../abi/ABI&ContractAddress";
import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers5/react";
import { ethers } from "ethers";
import { createTxn, recordUserVisits } from "../../../abi/firebasecode";

function Deposit({ selectedTab, balanceOfTokens }) {

    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
    const provider = ethers.getDefaultProvider(Network_Name, {
        etherscan: API_KEY});
        
    const [showTr, setShowTr] = useState(false);
    const [pending, setPending] = useState("");
    const [txId, setTxId] = useState("");
    const [TxnImage, setTxnImage] = useState("");
    const [errormsg, seterrormsg] = useState("");

    const handleCloseTransation = () => setShowTr(false);
    const handleShowTransation = () => setShowTr(true);

    const[allowan,setAllowance] = useState("")
    // const[allowan2,setAllowance2] = useState("")
    const[daiBalance,setdaiBalance] = useState("")
    const[creditsBalance,setCreditsBalance] = useState("");
    const[dimePrice,setdimePrice] = useState("")
    const[daiPrice,setdaiPrice] = useState("")
    const[bondPrice,setbondPrice] = useState("")

    const[depositAmt, setDepositAmt] = useState(0.00);
    const[userDeposit,setUserDeposit] = useState(0);
    const[userDebt,setUserDebt] = useState(0);

    const getAssetDetails = async() =>{
        if(selectedTab === 'Deposit')
            await recordUserVisits(address, "Vault - Deposit");
        if(isConnected){
          console.log("useeffect deposit");

        const DimePriceContract = new ethers.Contract(DIME_Chainlink_Oracle_Address, Chainlink_Oracle_ABI, provider);
        const DaiPriceContract = new ethers.Contract(DAI_Chainlink_Oracle_Address, Chainlink_Oracle_ABI, provider);
        const daiContract = new ethers.Contract(DAI_TOKEN_Address, DAI_TOKEN_ABI, provider);
        const creditsContract = new ethers.Contract(CREDITS_Token_Address, CREDITS_Token_ABI, provider);
        const CarbonixContract = new ethers.Contract(Carbonix_Finance_Address, Carbonix_Finance_ABI, provider);

        let dimeprice = ethers.utils.formatUnits(await DimePriceContract.getChainlinkDataFeedLatestAnswer(),0);
        setdimePrice(dimeprice);
        let daiprice = ethers.utils.formatUnits(await DaiPriceContract.getChainlinkDataFeedLatestAnswer(),0);
        setdaiPrice(daiprice);

        let allowance =  ethers.utils.formatUnits(await daiContract.allowance(address,Carbonix_Finance_Address),0);
        console.log("allowance", allowance)
        setAllowance(allowance);

        let daibalance1 = ethers.utils.formatUnits(await daiContract.balanceOf(address),18);
          setdaiBalance(daibalance1);
          console.log("bal",daibalance1);
          let creditsbalance1 = ethers.utils.formatUnits(await creditsContract.balanceOf(address),0);
          setCreditsBalance(creditsbalance1)  
        const userDeposit = ethers.utils.formatUnits(await CarbonixContract.getCdpTotalDeposited(address),0);
        setUserDeposit(userDeposit);
        const userDebt = ethers.utils.formatUnits(await CarbonixContract.getCdpTotalDebt(address),0);
        setUserDebt(userDebt);
      }
    }

    const approveDai = async() =>{
        if(isConnected){
        try{
            const ethersProvider =  new ethers.providers.Web3Provider(walletProvider)
            const signer =  ethersProvider.getSigner()
            const DaiContract = new ethers.Contract(DAI_TOKEN_Address, DAI_TOKEN_ABI, signer);
            
            await showPopUp("yes",TransactionPending,"","");
            let givenAmount = ethers.utils.parseEther(depositAmt.toString());
            const approveTx = await DaiContract.approve(Carbonix_Finance_Address,givenAmount);
            await approveTx.wait();
          
            console.log("minttx",approveTx.hash);
            await getAssetDetails();
            await TxnId_PopUp(approveTx.hash);
        }catch(err){
            showPopUp("not",TransactionPending,"",(err.reason).toString());
            console.log("error",err)
        }
      }
    }

    const deposit = async() =>{
        if(isConnected){
        try{
            const ethersProvider =  new ethers.providers.Web3Provider(walletProvider)
            const signer =  ethersProvider.getSigner()
            const CarbonixContract = new ethers.Contract(Carbonix_Finance_Address, Carbonix_Finance_ABI, signer);
            
            await showPopUp("yes",TransactionPending,"","");
            let givenAmount = ethers.utils.parseEther(depositAmt.toString());
            const depositTx = await CarbonixContract.deposit(givenAmount);
            await depositTx.wait();
          
            console.log("minttx",depositTx.hash);
            await getAssetDetails();
            resetState();
            await TxnId_PopUp(depositTx.hash);
            await balanceOfTokens();
            await createTxn("DAI",depositTx.hash,"Deposit DAI",address,Carbonix_Finance_Address);
        }catch(err){
            showPopUp("not",TransactionPending,"",(err.reason).toString());
            console.log("error",err)
        }
      }
    }

    const TxnId_PopUp =async(hash) =>{
        let txnid = TXN_ID+hash;
        showPopUp("no",TransactionPending,txnid,"");
    }

    const showPopUp = async(pendingvalue,imgSrc,txid,errormsg)=>{
        setPending(pendingvalue);
        setTxId(txid);
        setTxnImage(imgSrc);
        seterrormsg(errormsg);    
        handleShowTransation();        
    }

    const handlechange = (event) =>{ 
        setDepositAmt(event.target.value);
    }

    const handleInput = (value) => {
        setDepositAmt(((daiBalance*value)/100));
        console.log(value);
    }

    const resetState = async() =>{
        setDepositAmt(0.00);
    }

    useEffect(()=>{getAssetDetails()},[address, isConnected, selectedTab]);
    let buttonDisableCondition = !parseFloat(daiBalance);
    return ( 
        <div className="p-24">
            <Row className="justify-content-center">
                <Col style={{maxWidth: '700px'}}>
                    <div className="box mb-20 p-3 p-md-4">
                        <div className="swap-card mb-20 d-flex align-items-center">
                            <div>
                                <img src={DaiIcon} alt="Icon1" style={{ width: "37px", height: "37px" }}/>
                            </div>
                            <div className="flex-grow-1 ms-2 ps-3 py-2">
                                <input disabled={buttonDisableCondition} value={depositAmt ? depositAmt : ""} placeholder="0.00" className="input-reset" onChange={(e) => handlechange(e)}/>
                            </div>
                            {
                            (allowan >= depositAmt && allowan > 0) ? 
                            <button disabled={buttonDisableCondition} className="btn btn-grad py-2" onClick={deposit}>Deposit</button> 
                            : 
                            <button disabled={buttonDisableCondition} className="btn btn-grad py-2" onClick={approveDai}>Approve</button>
                            }
                            
                        </div>

                        <Row className="mb-20 g-2">
                            <Col>
                                <button disabled={buttonDisableCondition} className="btn btn-toggle w-100" onClick={() => handleInput(25)}><span>25%</span></button>
                            </Col>
                            <Col>
                                <button disabled={buttonDisableCondition} className="btn btn-toggle w-100" onClick={() => handleInput(50)}><span>50%</span></button>
                            </Col>
                            <Col>
                                <button disabled={buttonDisableCondition} className="btn btn-toggle w-100" onClick={() => handleInput(75)}><span>75%</span></button>
                            </Col>
                            <Col>
                                <button disabled={buttonDisableCondition} className="btn btn-toggle w-100" onClick={() => handleInput(100)}><span>100%</span></button>
                            </Col>
                        </Row>

                        <div className="mt-3 text-center pb-2">
                            {buttonDisableCondition ? <h5 className="text-red mb-0">{isConnected?"Insufficient DAI balance":"Connect Your Wallet"}</h5> : <></>}
                        </div>
                        <center> <h4 className="text-muted pt-2 border-top">Deposits</h4></center> 
                    <div className="box mb-20 p-3 p-md-4 border-top">
                        <div className="flex mb-2 justify-content-between">
                            <h5 className="mb-0 text-gray">Your Wallet balance: </h5>
                            <h5 className="mb-0">{daiBalance ? (parseFloat(daiBalance)).toFixed(4) : "0.0000"} DAI</h5>
                        </div>
                        <div className="flex mb-2 justify-content-between">
                            <h5 className="mb-0 text-gray">Your collateral balance:</h5>
                            <h5 className="mb-0">{userDeposit ? (userDeposit / 1e18).toFixed(4) : "0.0000"} DAI</h5>
                        </div>
                        <div className="flex mb-2 justify-content-between ">
                            <h5 className="mb-0 text-gray">Available to withdraw:</h5>
                            <h5 className="mb-0">{(userDeposit && userDebt) ? ((userDeposit - userDebt) / 1e18).toFixed(4) : "0.0000"} DAI</h5>
                        </div>
                    </div>

                  <center> <h4 className="text-muted pt-2 border-top">Borrows</h4></center> 
                    <div className="box mb-20 p-3 p-md-4 border-top">
                        <div className="flex mb-2 justify-content-between">
                            <h5 className="mb-0 text-gray ">CREDITS borrowed:</h5>
                            <h5 className="mb-0">{userDebt ? (userDebt / 1e18).toFixed(4) : "0.0000"} Credits</h5>
                        </div>
                        <div className="flex mb-2 justify-content-between">
                            <h5 className="mb-0 text-gray">Available to borrow:</h5>
                            <h5 className="mb-0">{(userDeposit && userDebt) ? (((userDeposit / 2) - userDebt) / 1e18).toFixed(4) : "0.0000"} Credits</h5>
                        </div>
                        <div className="flex mb-2 justify-content-between">
                            <h5 className="mb-0 text-gray">Your Wallet Balance:</h5>
                            <h5 className="mb-0">{creditsBalance ? (creditsBalance / 1e18).toFixed(4) : "0.0000"} Credits</h5>
                        </div>
                    </div>
                    </div>

                   
                </Col>
            </Row>
            <PendingModal pending={pending} showTr={showTr}  handleCloseTransation={handleCloseTransation} imgSrc={TxnImage} txId={txId} errormsg={errormsg}/>
        </div>
     );
}

export default Deposit;