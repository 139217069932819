import { Badge, Col, ProgressBar, Row } from "react-bootstrap";
import LayoutAfterLogin from "../../component/LayoutAL";
import StackIcon from '../../asserts/IMAGES/stack-icon.svg'
import UnstackIcon from '../../asserts/IMAGES/unstack-icon.svg'
import RewardExplainedIcon from '../../asserts/IMAGES/reward-explained-icon.svg'
import UnstackIconRed from '../../asserts/IMAGES/unstack-icon-red.svg'
import AdjustWeeklyRewards from '../../asserts/IMAGES/adjust-weekly-rewards.svg'
import BackIcon from '../../asserts/IMAGES/back-white-icon.svg'

import SnxIcon from '../../asserts/IMAGES/snx-icon.svg'
import HelpIcon from '../../asserts/IMAGES/help-icon.svg'
import Tooltip from "../../Snippets/Tooltip";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers5/react";
import { API_KEY, CHAIN_URL, DAI_TOKEN_ABI, JOKER_Staking_Address, JOKER_Token_ABI, JOKER_Token_Address, Network_Name, STAKING_ABI } from "../../abi/ABI&ContractAddress";
import { ethers } from "ethers";
import { getTokenBalance } from "../../abi/CommonSolFunctions";
import { recordUserVisits } from "../../abi/firebasecode";

const Welcome = ({balances}) =>{
    const location =  useLocation();
    console.log("location", location);
    // const { state } = useLocation();
// console.log("state",state); 
    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
    
    

    const[staked,setstaked] = useState(true);
    const[unstakeLock,setunstakeLock] = useState(false);

    const[locationValues,setlocationValues] = useState(true);

    const[stakedAmount,setStakedAmount] = useState("");
    const[MaxStakeLimit,setMaxStakeLimit] = useState("");
    const[UnstakeTime,setUnstakeTime ] = useState("");
    const[decimal,Setdecimal] = useState("");
    const[RewardAmount,setRewardAmount] = useState("");
    const[TotalStakedValue,setTotalStakedValue] = useState("");

    const getAllBalanceFunction = async() =>{
        try{
        if (location.state)
            await recordUserVisits(address, `${location.state.TokenName} Staking - Welcome Page`);
        if(isConnected){
            
            if (location.state){
                const provider = ethers.getDefaultProvider(Network_Name, {
                    etherscan: API_KEY});
                const StakingContract = new ethers.Contract(location.state.StakingContract, STAKING_ABI, provider);

                let userDeposited = await StakingContract.userInfo(address);
                console.log("userDeposited", ethers.utils.formatUnits((userDeposited.amount),0));
                setStakedAmount(ethers.utils.formatUnits((userDeposited.amount),0));
                let maxstakeamount= await StakingContract.maxStakeAmount();
                console.log("maxstakeamount", ethers.utils.formatUnits((maxstakeamount),0));
                setMaxStakeLimit(ethers.utils.formatUnits((maxstakeamount),0));
                const now = new Date(); // Current date and time
                const targetEpoch = now.getTime() /1000;
                let holderunstaketime= await StakingContract.holderUnstakeRemainingTime(address);
                console.log("maxstakeamount",targetEpoch, ethers.utils.formatUnits((holderunstaketime),0));
                setUnstakeTime(ethers.utils.formatUnits((holderunstaketime),0));
                if( parseFloat(ethers.utils.formatUnits((holderunstaketime),0)) - parseFloat(targetEpoch) <= 0){
                    setunstakeLock(false);
                    console.log("maxstakeamount","false");
                }else{
                    setunstakeLock(true);
                    console.log("maxstakeamount","true");
                }
                console.log("maxstakeamount");
                Setdecimal(location.state.decimalAmount)

                let rewardvalue =ethers.utils.formatUnits( await StakingContract.pendingBlack(address),0);
                setRewardAmount(rewardvalue)

                let totalStakedvalue = await getTokenBalance(location.state.StakingContract,location.state.depositTokenAddress,DAI_TOKEN_ABI)
                setTotalStakedValue(totalStakedvalue)

                setlocationValues(true);
            }else{
                console.log("welcome")
            }
            
        }
        }catch(error){
            console.error(error);
        }
    }
    useEffect(() =>{getAllBalanceFunction()},[isConnected,address]);

    // console.log("welcomestakedAmount",stakedAmount,unstakeLock)

    
    return (  
        <LayoutAfterLogin menuActive={"carbonix"} balances={balances}>

            <div className="p-24 app-contain">
            <Link className="flex g-2 mb-3 text-white" to='/staking'><img src={BackIcon} alt="icon" /> Back</Link>

                <Row className="g-4 g-md-3">
                    <Col md={9}>
                        <Row className="g-3 align-items-end mb-4">
                            <Col>
                                <h2 className="mb-2">Welcome to Staking</h2>
                                <h6 className="text-gray mb-0">Stake your {location.state? location.state.TokenName :"Token"} to get a reward .If you've never staked before please read through this quick introduction first. Follow the steps below to get started.</h6>
                            </Col>
                            <Col md={'auto'}>
                                <button className="btn btn-grad outline btn-sm"><span>Read Instruction</span></button>
                            </Col>
                        </Row>
{stakedAmount  && unstakeLock ?(<>
    <div className="box mb-20 d-flex flex-column p-3">
                            <Row className="g-3 align-items-end mb-4">
                                <Col>
                                    <h2 className="mb-2">Staking Ratio Health</h2>
                                    <h6 className="text-gray mb-0">In order to avoid the locking,you deposit must be less than the target Value.</h6>
                                </Col>
                                <Col md={'auto'}>
                                    <h3 className="text-red">{parseFloat((stakedAmount/MaxStakeLimit)*100) <= 1 && parseFloat((stakedAmount/MaxStakeLimit)*100) > 0 ? 1: (stakedAmount/MaxStakeLimit)*100}%</h3>
                                </Col>
                            </Row>

                            <div className="d-flex align-items-center text-gray justify-content-between">
                                <span>Your deposits {decimal? (stakedAmount/(10**(decimal))).toFixed(4):'0'}
                                    {/* &lt; */}
                                     </span>
                                <span>Max Deposits &lt; {decimal? (MaxStakeLimit/(10**(decimal))).toFixed(4):'0'}
                                {/* <Tooltip pos="right" text="Texting" /> */}
                                </span>
                            </div>
                            <ProgressBar className="progress-shadow my-4" variant="danger" now={parseFloat((stakedAmount/MaxStakeLimit)*100) <= 1 && parseFloat((stakedAmount/MaxStakeLimit)*100) > 0 ? 1: (stakedAmount/MaxStakeLimit)*100} />
                        </div>
</>): parseFloat(stakedAmount) ? (<>
    <div className="box mb-20 d-flex flex-column p-3">
                            <Row className="g-3 align-items-end mb-4">
                            <Col>
                                    <h2 className="mb-2">Staking Ratio Health</h2>
                                    <h6 className="text-gray mb-0">In order to avoid the locking,you deposit must be less than the target Value.</h6>
                                </Col>
                                <Col md={'auto'}>
                                    <h3 className="text-green-light">{parseFloat((stakedAmount/MaxStakeLimit)*100) <= 1 && parseFloat((stakedAmount/MaxStakeLimit)*100) > 0 ? 1: (stakedAmount/MaxStakeLimit)*100}%</h3>
                                </Col>
                            </Row>

                            <div className="d-flex align-items-center text-gray justify-content-between">
                                <span>Your deposits {decimal? (stakedAmount/(10**(decimal))).toFixed(4):'0'}
                                    {/* &lt; */}
                                     </span>
                                <span>Max Deposits &lt; {decimal? (MaxStakeLimit/(10**(decimal))).toFixed(4):'0'}
                                {/* <Tooltip pos="right" text="Texting" /> */}
                                </span>
                            </div>
                            <ProgressBar className="progress-shadow my-4" variant="green-light" now={parseFloat((stakedAmount/MaxStakeLimit)*100) <= 1 && parseFloat((stakedAmount/MaxStakeLimit)*100) > 0 ? 1: (stakedAmount/MaxStakeLimit)*100}  />
                        </div>

</>) : (<></>)}
                       

                        <Row className="g-3 justify-content-center">
                            <Col md={4} sm={6}>
                                <div className="box h-100 d-flex flex-column p-3">
                                    <div className="flex mb-20 justify-content-between">
                                        <h1 className="text-grad mb-0">1</h1>
                                        <img src={StackIcon} alt="StackIcon" />
                                    </div>

                                    <div className="flex-grow-1 mb-3">
                                        <h4>Stake</h4>
                                        <h6 className="text-gray">Deposit  {location.state? location.state.TokenName :"Token"}  </h6>
                                    </div>
                                    <Link to="/staking/stake" state={location.state}>
                                    <button className="btn btn-grad w-100">Start Staking</button>
                                    </Link>
                                </div>
                            </Col>
                            <Col md={4} sm={6}>
                                {unstakeLock?(<>
                                    <div className="box h-100 d-flex flex-column p-3">
                                    <div className="flex mb-20 justify-content-between">
                                        <h1 className="text-red mb-0">2</h1>
                                        <img src={UnstackIcon} alt="StackIcon" />
                                    </div>

                                    <div className="flex-grow-1 mb-3">
                                        <h4>Unstake  </h4>
                                        <h6 className="text-gray">Withdraw your  {location.state? location.state.TokenName :"Token"} after unlock time.</h6>
                                    </div>
                                    <Badge bg="danger" className="me-auto mb-3">Adjust to collect Weekly Rewards <img src={AdjustWeeklyRewards} alt="AdjustWeeklyRewards" /></Badge>
                                    <Link to='/staking/stake-withdraw' state={location.state}>
                                    <button className="btn btn-red w-100"><span>Unstake</span></button>
                                    </Link>
                                </div>
                                </>):(<>
                                    <div className="box h-100 d-flex flex-column p-3">
                                    <div className="flex mb-20 justify-content-between">
                                        <h1 className="text-grad mb-0">2</h1>
                                        <img src={UnstackIcon} alt="StackIcon" />
                                    </div>

                                    <div className="flex-grow-1 mb-3">
                                        <h4>Unstake  </h4>
                                        <h6 className="text-gray">Withdraw your  {location.state? location.state.TokenName :"Token"} after unlock time.</h6>
                                    </div>
                                    <Badge bg="green-light" className="me-auto mb-3">Adjust to collect Weekly Rewards <img src={AdjustWeeklyRewards} alt="AdjustWeeklyRewards" /></Badge>
                                    

                                    <Link to='/staking/stake-withdraw' state={location.state} className="btn btn-dark w-100"><span>Unstake</span></Link>
                                </div>
                                </>)}
                              

                              

                            </Col>
                            <Col md={4} sm={6}>
                                <div className="box h-100 d-flex flex-column p-3">
                                    <div className="flex mb-20 justify-content-between">
                                        <h1 className="text-gray mb-0">3</h1>
                                        <img src={RewardExplainedIcon} alt="StackIcon" />
                                    </div>

                                    <div className="flex-grow-1 mb-3">
                                        <h4> Rewards</h4>
                                        <h6 className="text-gray">Maintain your target Ratio to collect your  rewards.</h6>
                                    </div>
                                    <Row className="justify-content-between mb-3">
                                        <Col xs={'auto'}>
                                            <h6>Your Rewards </h6>
                                            <h5 className="text-green-light">{RewardAmount?parseFloat(RewardAmount/1e9).toFixed(8):'0.0'}</h5>
                                        </Col>
                                        {/* <Col xs={'auto'}>
                                            <h6>Your Fees <Tooltip pos="right" text="Lorem Ipsum" /></h6>
                                            <h5 className="text-green-light">$0.10</h5>
                                        </Col> */}
                                    </Row>
                                    <Link to="/staking/stake-reward" state={location.state}>
                                    <button className="btn btn-dark w-100"><span>Collect Rewards </span></button>
                                    </Link>                               
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={3}>
                        <div className="swap-card mb-3 text-end">
                            <h6 className="text-white flex g-2 justify-content-end mb-2">Estimated APR </h6>
                            <h3 className="text-gray mb-0">{TotalStakedValue?parseFloat(((location.state.blocksperyear*365)/(TotalStakedValue/(10**location.state.decimalAmount)))*100).toFixed(3):'0.0'}%</h3>
                        </div>
                        <div className="swap-card mb-3 flex justify-content-between g-2">
                            <div className="flex-grow-1">
                                <h6 className="text-white mb-1">Total Staked</h6>
                                <p className="text-gray">Total amount of {location.state? location.state.TokenName :"Token"} staked by all users</p>
                            </div>
                            <div>
                                {/* <img src={SnxIcon} alt="SnxIcon" /> */}
                                <p className="text-gray">{TotalStakedValue?parseFloat(TotalStakedValue/(10**location.state.decimalAmount)).toFixed(2):'0.0'}</p>

                            </div>
                        </div>
                        <div className="swap-card mb-3 flex justify-content-between g-2">
                            <div className="flex-grow-1">
                                <h6 className="text-white mb-1">Staked amount</h6>
                                <p className="text-gray">{location.state? location.state.TokenName :"Token"}</p>
                            </div>
                            <div>
                                <input type="text" className="input-reset" style={{maxWidth: '50px'}} placeholder="0.00" value={stakedAmount?stakedAmount/(10**location.state.decimalAmount).toFixed(6):'0.0'} />
                            </div>
                        </div>
                        {/* <div className="swap-card mb-3 flex justify-content-between g-2">
                            <div className="flex-grow-1">
                                <h6 className="text-white mb-1">Help</h6>
                                <p className="text-gray">Learn more about Joker Protocol</p>
                            </div>
                            <div>
                                <img src={HelpIcon} alt="HelpIcon" />
                            </div>
                        </div> */}
                    </Col>
                </Row>
            </div>
        </LayoutAfterLogin>
    );
}

export default Welcome;