import { Col, Row, Table } from "react-bootstrap";
import Tooltip from "../../Snippets/Tooltip";
import Joker from "../../asserts/IMAGES/jokerRedLogo.svg";
import Upload from "../../asserts/IMAGES/UploadIcon.svg";
import TransactionPending from '../../asserts/IMAGES/transsationPending.svg';
import Timer from "../Dashboard/Snippets/Timer";
import ProgressBar from "../Dashboard/Snippets/CircleTimer";
import { CheckAllowance, PendingModal, convertEpochToDateTime, getJokerPrice, getTokenBalance } from "../../abi/CommonSolFunctions";
import { API_KEY, Burn_Vault_ABI, Burn_Vault_Address, CHAIN_URL, JOKER_Token_ABI, JOKER_Token_Address, Network_Name, TXN_ID } from "../../abi/ABI&ContractAddress";
import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers5/react";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { createTxn, recordUserVisits } from "../../abi/firebasecode";
/* global BigInt */
function BurnVault({selectedTab, balanceOfTokens}) {
    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
    const provider = ethers.getDefaultProvider(Network_Name, {
        etherscan: API_KEY});
     //usestate values
    const [jokerBalance, setJokerBalance] = useState("");
    const [jokerAmount,setJokeramount] = useState("");
    const [OutputValue,setOutputValue] = useState("");
    const [oneETHValue, setOneEthVAlue] = useState("");
    const [jokerAllowance,setJokerAllowance] = useState("");
    const [secondsLeft,setSecondsLeft] = useState("");
    const [lockedTrue,setLockedTrue] = useState("");
    const [timePercentage, setTimePercentage] = useState(0);
    const [jokerPrice,setJokerPrice] = useState("");

    const[BurnVaultTime,setBurnvaultTime] = useState("");
    const[BurnVaultLock,setBurnVaultLock] = useState("");
    const [maxLimit,setMaximumLimit] = useState("");
    const [ethAvailable,setETHAvailable] = useState("");
    const[MaxLimitToBuy,setMaxLimitToBuy] = useState("");

 
    const [showTr, setShowTr] = useState(false);
    const [pending, setPending] = useState("");
    const [txId, setTxId] = useState("");
    const [TxnImage, setTxnImage] = useState("");
    const [errormsg, seterrormsg] = useState("");

    const handleCloseTransation = () => setShowTr(false);
    const handleShowTransation = () => setShowTr(true);
       
    const JOKERContract = new ethers.Contract(JOKER_Token_Address, JOKER_Token_ABI, provider);
    const BurnVaultContract = new ethers.Contract(Burn_Vault_Address, Burn_Vault_ABI, provider);

    const getAllBalanceFunction = async() =>{
        if(selectedTab === 'Burn Vault')
            await recordUserVisits(address, "Controlled Swap - Burn Vault");
        if(isConnected){
            console.log("useeffect burnvault");
            let jokerBalance = await getTokenBalance(address,JOKER_Token_Address,JOKER_Token_ABI);
            console.log("jokerBalance",jokerBalance)
            setJokerBalance(jokerBalance);
            let totalvalue = ethers.utils.formatUnits(await BurnVaultContract.getBTCValue(1e9),0);
            setOneEthVAlue(totalvalue);
            let jokerallowance = await CheckAllowance(JOKER_Token_Address,JOKER_Token_ABI,address,Burn_Vault_Address);
            setJokerAllowance(jokerallowance);

            let secondsleft = ethers.utils.formatUnits(await JOKERContract._secondsLeft(address),0);
            setSecondsLeft(await convertEpochToDateTime(secondsleft))
            setLockedTrue(secondsleft)
            console.log("secondsleft", secondsleft);
            setTimePercentage(parseInt((secondsleft / 86400) * 100));

            let burnvaultTime = ethers.utils.formatUnits(await BurnVaultContract.secondsLeft(address),0);
            setBurnvaultTime(await convertEpochToDateTime(burnvaultTime));
            setBurnVaultLock(burnvaultTime)

            let priceOracle = await getJokerPrice();
            setJokerPrice(priceOracle)

            let maxtxlimit = ethers.utils.formatUnits(await BurnVaultContract.maxTxAmount(),0);
            setMaximumLimit(maxtxlimit)

            let ethavailable = ethers.utils.formatUnits(await BurnVaultContract.getBurnVaultBNBBalance(),0);
            setETHAvailable(ethavailable)

            let senderburnbalance = ethers.utils.formatUnits(await BurnVaultContract.senderBurnBalance(address),0);
            setMaxLimitToBuy(senderburnbalance)

        }
    }

    useEffect(() =>{
        getAllBalanceFunction()
    },[address, isConnected, timePercentage, selectedTab]);
    function formatValues (values,digitsToPrint) {
        return parseFloat(values).toLocaleString(undefined, { minimumFractionDigits: digitsToPrint, useGrouping: false });
    }

    const changeinput = async(inputvalue) => {
        setJokeramount(inputvalue);
        
        try{
            let totalvalue = oneETHValue * inputvalue;
            setOutputValue(totalvalue/1e18)
        }
        catch(err){
            console.log(err)
            setOutputValue(0);
        }
         
    }

    const maxval = async() =>{
        changeinput(jokerBalance/1e9)
    }

    const approve = async() =>{
        if(isConnected){
            try{
                const ethersProvider =  new ethers.providers.Web3Provider(walletProvider)
                const signer =  ethersProvider.getSigner() 
                const JOKERContract = new ethers.Contract(JOKER_Token_Address, JOKER_Token_ABI, signer);
                await showPopUp("yes",TransactionPending,"","");
                const approveTxn = await JOKERContract.approve(Burn_Vault_Address,BigInt(1000000000 * 1e9));
                
                await approveTxn.wait();
                await TxnId_PopUp(approveTxn.hash);
            }catch(err){
                showPopUp("not",TransactionPending,"",(err.reason).toString());
                console.log("error",err);
                
            }
        }
    }
    const Burn = async() =>{
        if(isConnected){
            try{
                const ethersProvider =  new ethers.providers.Web3Provider(walletProvider)
                const signer =  ethersProvider.getSigner() 
                const JOKERContract = new ethers.Contract(Burn_Vault_Address, Burn_Vault_ABI, signer);
                await showPopUp("yes",TransactionPending,"","");
                const swaptxn = await JOKERContract.swap(BigInt(jokerAmount * 1e9));
                
                await swaptxn.wait();
                await TxnId_PopUp(swaptxn.hash);
                await balanceOfTokens();
                await createTxn("Joker",swaptxn.hash,"Burn Vault",address,Burn_Vault_Address);
                await resetState();
            }catch(err){
                showPopUp("not",TransactionPending,"",(err.reason).toString());
                console.log("error",err.reason);
                
            }
        }
    }
    const TxnId_PopUp =async(hash) =>{
        let txnid = TXN_ID+hash;
        showPopUp("no",TransactionPending,txnid,"");
        await getAllBalanceFunction();
    }

    const showPopUp = async(pendingvalue,imgSrc,txid,errormsg)=>{
        // console.log("showPopUp",pendingvalue,imgSrc,txid,errormsg)
        setPending(pendingvalue);
        setTxId(txid);
        setTxnImage(imgSrc);
        seterrormsg(errormsg);    
        handleShowTransation();        
    }

    const resetState = async() =>{
        setJokeramount("");
        setOutputValue("");
    }

    const allowanceTrue = parseInt(jokerAllowance) >= parseInt(jokerAmount*1e9);


    return ( 
        <>
            {/* <div className="py-3 px-2 price-list border-bottom border-3">
                <Row className="g-3 justify-content-center align-items-center">
                    <Col lg="2" md={3} xs={4}>
                        <h4 className="mb-1">Vault Price <Tooltip text="Lorem Ipsum" /></h4>
                        <h2 className="text-blue mb-0   ">$34514.37</h2>
                    </Col>
                    <Col lg="2" md={3} xs={4}>
                        <h4 className="mb-1">Floor Price <Tooltip text="Lorem Ipsum" /></h4>
                        <h2 className="text-blue mb-0">$3.51</h2>
                    </Col>
                </Row>
            </div> */}
            <div className="p-24 table-box app-contain">
                <Table responsive striped hover>
                    <thead>
                        <tr>
                            <th>Asset</th>
                            <th>Joker </th>
                            <th>Floor </th>
                            <th>Max Transaction limt</th>
                            <th>ETH Available </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div className="d-flex align-items-center">
                                    <img src={Joker} width={28} alt="Transfer" />
                                    Joker
                                </div>
                            </td>
                            <td>${jokerPrice?(jokerPrice/1e8):'0.0'} </td>
                            <td>${oneETHValue?(oneETHValue/1e18).toFixed(12) :'0.0'} </td>
                            <td>{maxLimit?formatValues(maxLimit/1e9,0):'0'} JOKER</td>
                            <td>{ethAvailable?formatValues(ethAvailable/1e18,4):'0'}  </td>
                        </tr>
                    </tbody>
                </Table>
                <div className="box p-3 px-md-4">
                    <div className="flex mb-3 flex-wrap g-2">
                        <button className="btn btn-grad outline btn-sm"><span>Burn Vault</span></button>

                        <div className="ms-auto flex g-md-5 g-3">
                            <h5 className="mb-0"><a className="text-gray flex g-2" href={`https://sepolia.etherscan.io/address/${Burn_Vault_Address}`} target="_blank" rel="noreferrer"><img src={Upload} alt="upload" /> View on explorer</a></h5>
                            <h5 className="text-gray mb-0"><a className="text-gray flex g-2" href={`https://sepolia.etherscan.io/address/${Burn_Vault_Address}`} target="_blank" rel="noreferrer"><img src={Upload} alt="upload" /> How it works</a> </h5>
                        </div>
                    </div>

                    <Row className="g-3">
                        <Col md={6}>
                            <div className="burn-card px-3 py-3">
                                <h5>Your JOKER Balance: {parseFloat(jokerBalance) === 'NaN' ? '0.00' :  formatValues((jokerBalance/1e9),2)}
                                </h5>
                                <div className="swap-card py-2 mb-2 d-flex align-items-center">
                                    <div className="flex-grow-1 ps-2">
                                    <input value={jokerAmount} placeholder="0.00" className="input-reset" onChange={(e) => changeinput(e.target.value)}/>
                                    </div>

                                    <button className="btn btn-grad py-2" onClick={()=>maxval()}>Max</button>
                                </div>
                                {BurnVaultLock <= 0 ? (<>
                                    {lockedTrue <= 0 ? (<>
                                    {allowanceTrue?(<>
                                <button type="submit" className="btn mb-3 btn-grad w-100" onClick={()=>Burn()}>Burn</button>
                                </>):(<>
                                    <button type="submit" className="btn mb-3 btn-grad w-100" onClick={()=>approve()}>Approve</button>
                                </>)}
                                </>):(<>
                                    <div className="box swap-card mb-20 d-flex align-items-center p-3 p-md-4">
                                        <div>
                                            <h5 className="text-gray">JOKER to be unlocked in</h5>
                                            <Timer count={secondsLeft?secondsLeft:0} />
                                            </div>
                                            <div className="ms-auto">
                                            <ProgressBar
                                                percentage={timePercentage}
                                                startColor="#20E2D0"
                                                endColor="#00C2FF"
                                                gradientId="progress5"
                                            />
                                        </div>
                                    </div>
                                </>)}
                                </>):(<>
                                    <button  type="submit"  className=" btn mb-20 btn-grad outline w-100 ">
                            <span>Burn</span></button>
                                </>)}
                              
                                

                                <div className="flex flex-wrap g-4">
                                    <div>
                                        <h6 className="text-gray text-13 mb-1">You Will Get</h6>
                                        <h5 className="mb-0">{parseFloat(OutputValue) ? formatValues((OutputValue),13): '0.00' } ETH</h5>
                                    </div>
                                    <div>
                                        <h6 className="text-gray text-13 mb-1">Max You Can Buy</h6>
                                        <h5 className="mb-0">{MaxLimitToBuy?formatValues(((maxLimit-MaxLimitToBuy)*oneETHValue)/1e27,12):"0.00"} ETH</h5>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="burn-card flex flex-wrap g-4 mb-3 p-3">
                            <div className="flex-grow-1">
                                    <h6 className="text-gray text-13 mb-1"> You already Burned</h6>
                                    <h5 className="mb-0">{MaxLimitToBuy? formatValues(MaxLimitToBuy/1e9,3):"0.0"} JOKER</h5>
                                </div>
                                <div className="flex-grow-1">
                                    <h6 className="text-gray text-13 mb-1"></h6>
                                    <h5 className="mb-0"></h5>
                                </div>
                                <div className="flex-grow-1">
                                    <h6 className="text-gray text-13 mb-1">Max you can Burn</h6>
                                    <h5 className="mb-0">{MaxLimitToBuy?formatValues(((maxLimit-MaxLimitToBuy))/1e9,3):"0.0"} JOKER</h5>
                                </div>
                                {/* <div className="flex-grow-1">
                                    <h6 className="text-gray text-13 mb-1">Time until fully vested</h6>
                                    <h5 className="mb-0">0.00 DIME</h5>
                                </div>
                                <div className="flex-grow-1">
                                    <h6 className="text-gray text-13 mb-1">Claim Time</h6>
                                    <h5 className="mb-0">In 2 Days</h5>
                                </div> */}
                            </div>
                            {parseInt(BurnVaultLock) <= 0 ?(<>
                                <div className="burn-card d-flex align-items-center px-3 py-4">
                                <div>
                                    <h4 className="mb-3">Not Locked </h4>
                                    {/* <h5 className="text-gray">to do Burning</h5> */}
                                    <Timer count={"0"} />
                                </div>
                                <div className="ms-auto">
                                    <ProgressBar
                                        percentage={0}
                                        startColor="#20E2D0"
                                        endColor="#00C2FF"
                                        gradientId="progress3"
                                    />
                                </div>
                            </div>
                            </>):(<>
                                <div className="burn-card d-flex align-items-center px-3 py-4">
                                <div>
                                    <h4 className="mb-3">Wallet to be unlocked </h4>
                                    <h5 className="text-gray">to do Burning</h5>
                                    <Timer count={BurnVaultTime?BurnVaultTime:0} />
                                </div>
                                <div className="ms-auto">
                                    <ProgressBar
                                        percentage={30}
                                        startColor="#20E2D0"
                                        endColor="#00C2FF"
                                        gradientId="progress3"
                                    />
                                </div>
                            </div>
                            </>)}
                         
                        </Col>
                    </Row>
                </div>
                <PendingModal pending={pending} showTr={showTr}  handleCloseTransation={handleCloseTransation} imgSrc={TxnImage} txId={txId} errormsg={errormsg}/>
            </div>
        </>
     );
}

export default BurnVault;