import LayoutAfterLogin from "../../component/LayoutAL";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Rollover from "./Rollover";
import Redemption from "./Redeemption";
import { useState } from "react";

function TauBond({balances, balanceOfTokens}) {
    const [selectedTab, setSelectedTab] = useState("Rollover");

    const handleTabChange = (tabKey) => {
        setSelectedTab(tabKey);
    };
    return (  
        <LayoutAfterLogin menuActive={"joker-protocol"} balances={balances}>
            <Tabs
                defaultActiveKey="Rollover"
                id="uncontrolled-tab-example"
                className="justify-content-center"
                onSelect={(eventKey) => handleTabChange(eventKey)}
                >
                <Tab eventKey="Rollover" title="Rollover">
                    <Rollover selectedTab={selectedTab} balanceOfTokens={balanceOfTokens}/>
                </Tab>
                <Tab eventKey="Redemption" title="Redemption">
                    <Redemption selectedTab={selectedTab} balanceOfTokens={balanceOfTokens}/>
                </Tab>
            </Tabs>
        </LayoutAfterLogin>
    );
}

export default TauBond;