import LayoutAfterLogin from "../../component/LayoutAL";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Swap from "./Swap";
import AddLiquidity from "./AddLiquidity";
import BurnVault from "./BurnVault";
import { useState } from "react";

function Wallet({balances, balanceOfTokens}) {
    const [selectedTab, setSelectedTab] = useState('Swap'); // State to track the selected tab

    const handleTabChange = (tabKey) => {
        setSelectedTab(tabKey);
    };
    return (  
        <LayoutAfterLogin menuActive={"joker-protocol"} balances={balances}>
            <Tabs
                defaultActiveKey="Swap"
                id="uncontrolled-tab-example"
                className="justify-content-center"
                onSelect={(eventKey) => handleTabChange(eventKey)}
                >
                <Tab eventKey="Swap" title="Swap">
                    <Swap selectedTab={selectedTab} balanceOfTokens={balanceOfTokens}/>
                </Tab>
                <Tab eventKey="Add Liquidity" title="Add Liquidity">
                    <AddLiquidity selectedTab={selectedTab} balanceOfTokens={balanceOfTokens}/>
                </Tab>
                <Tab eventKey="Burn Vault" title="Burn Vault">
                    <BurnVault selectedTab={selectedTab} balanceOfTokens={balanceOfTokens}/>
                </Tab>
            </Tabs>
        </LayoutAfterLogin>
    );
}

export default Wallet;